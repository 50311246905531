import { SvgIconComponent } from '@mui/icons-material';
import { SvgIconProps, SvgIcon } from '@mui/material';

export const Aquarium: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 21 18">
    <path d="M17.61 2H19.5V0H1.5V2H3.39C1.61 3.8 0.5 6.27 0.5 9C0.5 12.97 2.81 16.39 6.16 18H14.84C18.19 16.39 20.5 12.97 20.5 9C20.5 6.27 19.39 3.8 17.61 2ZM11.75 14C10.59 14 9.56 13.64 8.83 13.17C8.17 14 6.83 14 5.5 14C6.6 14 7 12.88 7 11.5C7 10.12 6.6 9 5.5 9C6.83 9 8.17 9 8.87 9.91C9.56 9.36 10.59 9 11.75 9C13.82 9 15.5 10.12 15.5 11.5C15.5 12.88 13.82 14 11.75 14ZM14.5 4C12.06 5.71 8.94 5.71 6.5 4C5.56 4.66 4.5 5.09 3.45 5.25C3.81 4.58 4.27 3.96 4.82 3.41L6.2 2H14.8L16.18 3.41C16.73 3.96 17.19 4.58 17.55 5.25C16.5 5.09 15.44 4.66 14.5 4Z" />
  </SvgIcon>
);

Aquarium.muiName = 'Aquarium';
