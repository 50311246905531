import styled from 'styled-components';

import { Box } from '@mui/material';

export const DetailsContainer = styled(Box)`
  padding: ${({ theme }) => theme.spacing(4)};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  overflow-y: auto;
  max-height: 960px;
  max-width: 1280px;
  width: 100%;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.12);
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  &::-webkit-scrollbar {
    width: 0.5em;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
  }
`;
