import { AxiosError } from 'axios';

import { useQuery } from '@tanstack/react-query';

import { fetchOrderData } from '../../../../api/orders';
import { OrderDataResponse } from '../../../../api/orders/types';

export const useOrderData = (
  {
    email,
    orderNumber,
  }: {
    email: string | null;
    orderNumber: string | null;
  },
  enabled: boolean = true,
) => {
  const { data, isLoading, isError, error } = useQuery<
    OrderDataResponse,
    AxiosError<{ errorKey: string }>
  >(['order', email, orderNumber], () => fetchOrderData(email!, orderNumber!), {
    enabled: !!email && !!orderNumber && enabled,
    retry: false,
  });

  return !email || !orderNumber
    ? { data: null, isLoading: false, isError: false, error: null }
    : { data, isLoading, isError, error };
};
