import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Mixed: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 26 20">
    <path d="M3.48438 0C2.09687 0 0.984375 1.1125 0.984375 2.5V17.5C0.984375 18.8875 2.09687 20 3.48438 20H23.4844C24.8719 20 25.9844 18.8875 25.9844 17.5V2.5C25.9844 1.1125 24.8719 0 23.4844 0H3.48438ZM3.48438 2.5H23.4844V7.5H3.48438V2.5ZM3.48438 10H8.48438V12.5H3.48438V10ZM10.9844 10H23.4844V12.5H10.9844V10ZM3.48438 15H15.9844V17.5H3.48438V15ZM18.4844 15H23.4844V17.5H18.4844V15Z" />
  </SvgIcon>
);

Mixed.muiName = 'Mixed';
