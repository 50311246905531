import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Apartment: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 22 27">
    <path d="M0.984375 0V26.5625H9.68973V21.3976H12.5915V26.5625H21.2969V0H0.984375ZM3.88616 2.95139H6.78795V5.90278H3.88616V2.95139ZM9.68973 2.95139H12.5915V5.90278H9.68973V2.95139ZM15.4933 2.95139H18.3951V5.90278H15.4933V2.95139ZM3.88616 8.85417H6.78795V11.8056H3.88616V8.85417ZM9.68973 8.85417H12.5915V11.8056H9.68973V8.85417ZM15.4933 8.85417H18.3951V11.8056H15.4933V8.85417ZM3.88616 14.7569H6.78795V17.7083H3.88616V14.7569ZM9.68973 14.7569H12.5915V17.7083H9.68973V14.7569ZM15.4933 14.7569H18.3951V17.7083H15.4933V14.7569ZM3.88616 20.6597H6.78795V23.6111H3.88616V20.6597ZM15.4933 20.6597H18.3951V23.6111H15.4933V20.6597Z" />
  </SvgIcon>
);

Apartment.muiName = 'Apartment';
