import styled, { css } from 'styled-components';

import { Paper } from '@mui/material';

export const PolicyContainer = styled(Paper)<{ highlighted?: boolean; recommended?: boolean }>`
  display: flex;
  width: ${({ recommended, highlighted }) => (highlighted || recommended ? '328px' : '320px')};
  padding: 0 ${({ theme }) => theme.spacing(2)};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.spacing(2)};
  align-items: center;
  flex-direction: column;
  box-shadow: ${({ recommended }) =>
    recommended ? '0 0 30px 10px rgba(0, 0, 0, 0.1)' : '0 11px 9px 0 rgba(0, 0, 0, 0.05)'};
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  ${({ recommended, theme }) =>
    recommended &&
    css`
      border: 4px solid ${theme.palette.accent.main};
    `}

  ${({ highlighted, theme }) =>
    highlighted &&
    css`
      border: 4px solid ${theme.palette.primary.main};
    `}
  position: relative;
  height: 720px;
  overflow: hidden;
  justify-self: center;

  @media (max-width: 660px) {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.05);
    padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(1)}`};
  }

  @media (max-width: 360px) {
    width: 100%;
  }

  @media (max-width: 330px) {
    height: 800px;
  }
`;

export const CompanyLogo = styled.img`
  margin-top: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(5)};
`;

export const HighlightLabel = styled.div<{ recommended?: boolean }>`
  background: ${({ theme, recommended }) => {
    if (recommended) {
      return theme.palette.accent.main;
    }
    return theme.palette.grey[100];
  }};
  padding: ${({ theme }) => theme.spacing(1)};
  display: flex;
  justify-content: center;
  border-bottom-left-radius: ${({ theme }) => theme.spacing(2)};
  max-width: 70%;
  box-sizing: border-box;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: 660px) {
    right: auto;
    left: 0;
    border-bottom-right-radius: ${({ theme }) => theme.spacing(2)};
    border-bottom-left-radius: 0;
  }
`;

export const CheapestLabel = styled(HighlightLabel)`
  background: ${({ theme }) => theme.palette.grey[100]};
`;

export const HighlightLabelSmall = styled(CheapestLabel)`
  background: ${({ theme }) => theme.palette.primary.main};
`;
