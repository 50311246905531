import { SvgIconComponent } from '@mui/icons-material';
import { SvgIconProps, SvgIcon } from '@mui/material';

export const Glass: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 13 20">
    <path d="M7.5 18H12.5V20H0.5V18H5.5V11.97C2.69 11.7 0.5 9.34 0.5 6.46C0.5 6.15 0.53 5.85 0.58 5.55L1.5 0H8.04L6.83 2.41L6.29 3.5H8.29L6.83 6.41L6.29 7.5H8.5L7.5 10.75L10.17 7.09L10.96 6H8.71L10.17 3.09L10.71 2H8.71L9.71 0H11.5L12.43 5.55C12.5 5.85 12.5 6.15 12.5 6.46C12.5 9.34 10.31 11.7 7.5 11.97V18Z" />
  </SvgIcon>
);

Glass.muiName = 'Glass';
