import { RecoilState, useSetRecoilState } from 'recoil';

/**
 * Helper hook that enables setting a single value on a store with an object without having to destructure locally.
 */
export const useSetRecoilKeyValue = <T>(store: RecoilState<T>) => {
  const setState = useSetRecoilState(store);

  const setKeyValue = (key: keyof T, value: T[keyof T]) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return setKeyValue;
};
