import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Earthquake: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 21">
    <path d="M20.5 10L18.5 11L17.5 12L16.5 11L15.5 14L14.5 11L13.5 19L12.5 11L11.5 13L10.5 11L9.5 15L8.5 11L7.5 20L6.5 11L5.5 17L4.5 11L3.5 12L2.5 11L0.5 10L2.5 9L3.5 8L4.5 9L5.5 3L6.5 9L7.5 0L8.5 9L9.5 5L10.5 9L11.5 7L12.5 9L13.5 1L14.5 9L15.5 6L16.5 9L17.5 8L18.5 9L20.5 10Z" />
  </SvgIcon>
);

Earthquake.muiName = 'Earthquake';
