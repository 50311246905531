import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Flood: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      fill: '#FFF',
    }}
  >
    <path
      d="M16.7,17c-1.9,0-2.1,1-3.3,1c-1.2,0-1.4-1-3.3-1c-1.9,0-2.1,1-3.3,1s-1.4-1-3.3-1S1.2,18,0,18v2
	c2,0,2.1-1,3.3-1c1.2,0,1.4,1,3.3,1s2.1-1,3.3-1s1.4,1,3.3,1s2.1-1,3.3-1c1.2,0,1.4,1,3.3,1v-2C18.8,18,18.6,17,16.7,17z M6.7,15.5
	c2,0,2.1-1,3.3-1c1.2,0,1.4,1,3.3,1c1.9,0,2.1-1,3.3-1c1.2,0,1.4,1,3.3,1v-2c-0.6,0-1-0.3-1.5-0.6l-2-7.5l2.1,0.8l0.7-1.9L7.8,0
	L0,9.6l1.6,1.2L3,9.1l0.9,3.5c-0.2,0-0.3,0-0.6,0c-1.9,0-2.1,1-3.3,1v2c1.9,0,2.2-1,3.3-1C4.5,14.5,4.8,15.5,6.7,15.5z M12,8.2
	l1.4,5.3c-1.3,0.1-1.5-1-3.5-1c-0.4,0-0.7,0-0.9,0.1L8.2,9.2L12,8.2z"
    />
  </SvgIcon>
);

Flood.muiName = 'Flood';
