import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Neighbour: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 23">
    <path d="M12.9444 20.7C12.7144 20.01 12.4844 19.205 12.4844 18.4C12.4844 16.675 13.1744 15.065 14.2094 13.8H12.4844V11.5H14.7844V13.225C15.4744 12.65 16.2794 12.19 17.0844 11.845V0H0.984375V20.7H7.88437V16.675H10.1844V20.7H12.9444ZM12.4844 2.3H14.7844V4.6H12.4844V2.3ZM12.4844 6.9H14.7844V9.2H12.4844V6.9ZM5.58437 18.4H3.28437V16.1H5.58437V18.4ZM5.58437 13.8H3.28437V11.5H5.58437V13.8ZM5.58437 9.2H3.28437V6.9H5.58437V9.2ZM5.58437 4.6H3.28437V2.3H5.58437V4.6ZM7.88437 2.3H10.1844V4.6H7.88437V2.3ZM7.88437 6.9H10.1844V9.2H7.88437V6.9ZM7.88437 13.8V11.5H10.1844V13.8H7.88437ZM20.5344 13.8V17.25H23.9844V19.55H20.5344V23H18.2344V19.55H14.7844V17.25H18.2344V13.8H20.5344Z" />
  </SvgIcon>
);

Neighbour.muiName = 'Neighbour';
