import { useEffect, useState } from 'react';

import { PriorityHigh, SvgIconComponent } from '@mui/icons-material';
import { Box, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';

import { IconFilledContainer, TooltipIcon } from './styled';
import { MainColor } from '../../styles/types';

interface Props {
  icon?: SvgIconComponent;
  active?: boolean;
  tooltip?: string;
  color?: MainColor;
  width?: string;
  containerWidth?: string;
  label?: string;
  iconSize?: string;
  description?: string;
  parentRef?: React.RefObject<HTMLDivElement>;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const IconFilled = ({
  icon: Icon,
  active,
  tooltip,
  color = 'accent',
  width = '48px',
  containerWidth,
  label,
  iconSize = 'large',
  description,
}: Props) => {
  const [isOverTooltipIcon, setIsOverTooltipIcon] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleScroll = () => {
    setShowTooltip(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const carouselRef = document.getElementsByClassName(
      'react-multi-carousel-track',
    )[0] as HTMLDivElement | null;

    const observer = new MutationObserver((mutationsList) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          handleScroll();
        }
      }
    });

    if (carouselRef) {
      observer.observe(carouselRef, { attributes: true });
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (carouselRef) {
        observer.disconnect();
      }
    };
  }, []);

  const iconContent = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...(containerWidth && { width: containerWidth })}
    >
      <IconFilledContainer active={active} color={color} width={width}>
        {tooltip ? (
          <TooltipIcon
            onMouseEnter={() => setIsOverTooltipIcon(true)}
            onMouseLeave={() => setIsOverTooltipIcon(false)}
          >
            <PriorityHigh
              sx={{
                color: 'common.white',
                fontSize: '0.8rem',
              }}
            />
          </TooltipIcon>
        ) : null}
        {Icon ? (
          <Icon
            sx={(theme) => ({
              color: theme.palette.common.white,
              fontSize: iconSize,
            })}
          />
        ) : null}
      </IconFilledContainer>
      {label ? (
        <Typography variant="caption" color="grey.400" align="center">
          {label}
        </Typography>
      ) : null}
    </Box>
  );
  return (tooltip && tooltip !== '') || description !== '' ? (
    <StyledTooltip
      title={isOverTooltipIcon ? tooltip : description}
      placement="top"
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      sx={{
        [`& ${tooltipClasses.popper}`]: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}
    >
      {iconContent}
    </StyledTooltip>
  ) : (
    iconContent
  );
};
