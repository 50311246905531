import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import { Content, FooterContainer, FooterCopyright, FooterECommerceVerification } from './styled';
import { ContainedInput } from '../contained-input';
import { FundLogo } from '../icons/fund-logo';
import { LogoWhite } from '../icons/logo-white';

const LINKS = [
  {
    label: 'За нас',
    href: `${process.env.REACT_APP_WORDPRESS_BASE_URL}za-kompa/`,
  },
  {
    label: 'Совети',
    href: `${process.env.REACT_APP_WORDPRESS_BASE_URL}soveti/`,
  },
  {
    label: 'Како споредуваме',
    href: `${process.env.REACT_APP_WORDPRESS_BASE_URL}kako-sporeduva-kompa/`,
  },
  {
    label: 'Кариера',
    href: '',
  },
];

export const Footer = () => (
  <FooterContainer>
    <Content
      gridTemplateColumns={{
        xs: '1fr',
        md: '1.5fr 1fr 1fr',
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Box
          marginBottom={{
            xs: 0.25,
            md: 2,
          }}
        >
          <LogoWhite />
          <Typography
            variant="caption"
            color="white"
            fontSize="16px"
            component="p"
            lineHeight="normal"
          >
            не комплицира
          </Typography>
        </Box>
        <Box
          width={{
            xs: '100%',
            md: 'inherit',
          }}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent={{
            xs: 'space-between',
          }}
          gap={1}
        >
          <Typography variant="subtitle1" color="white" fontWeight="normal">
            070 268 000
          </Typography>
          <Typography variant="subtitle1" color="white" fontWeight="normal">
            alo@kompa.mk
          </Typography>
          <FundLogo marginTop={3} />
        </Box>
      </Box>
      <Box
        marginTop={{
          xs: 2,
          md: 0,
        }}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent={{
          xs: 'space-between',
        }}
      >
        <Typography variant="subtitle1" color="common.white" fontWeight="bold" marginBottom={2}>
          Линкови
        </Typography>
        {LINKS.map((link) => (
          <Typography
            key={link.label}
            variant="subtitle2"
            color="common.white"
            marginBottom={1}
            component="a"
            target="_blank"
            {...(link.href && {
              href: link.href,
            })}
            sx={{
              textDecoration: 'none',
            }}
          >
            {link.label}
          </Typography>
        ))}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%" marginTop={3}>
        <Typography variant="subtitle1" color="common.white" fontWeight="bold" marginBottom={2}>
          Претплати се
        </Typography>
        <Typography variant="subtitle2" color="grey.400" marginBottom={1}>
          Само корисни вести, без спам
        </Typography>
        <ContainedInput onSubmit={() => {}} placeholder="Вашата email адреса" />
        <Box display="flex" alignItems="center" width="100%" marginTop={2}>
          <IconButton target="_blank" href="https://www.facebook.com/profile.php?id=61554805121999">
            <Facebook
              sx={{
                color: '#FFF',
              }}
            />
          </IconButton>
          <IconButton target="_blank" href="https://www.linkedin.com/company/kompa-mk">
            <LinkedIn
              sx={{
                color: '#FFF',
              }}
            />
          </IconButton>
          <IconButton target="_blank" href="https://www.instagram.com/kompa.mk">
            <Instagram
              sx={{
                color: '#FFF',
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Content>
    <FooterCopyright>
      <Typography variant="subtitle2" color="info.main">
        © 2024 Компа. Сите права се задржани.
      </Typography>
      <Box
        display="flex"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        alignItems="center"
        gap={{ xs: 0, md: 2 }}
      >
        <Typography
          variant="subtitle2"
          color="info.main"
          component="a"
          target="_blank"
          sx={{
            textDecoration: 'none',
          }}
          href={`${process.env.REACT_APP_WORDPRESS_BASE_URL}politika-na-privatnost/`}
        >
          Политика на приватност
        </Typography>
        <Typography
          variant="subtitle2"
          color="info.main"
          component="a"
          target="_blank"
          sx={{
            textDecoration: 'none',
          }}
          href={`${process.env.REACT_APP_WORDPRESS_BASE_URL}uslovi-za-koristenje/`}
        >
          Услови за користење
        </Typography>
      </Box>
    </FooterCopyright>
    <FooterECommerceVerification>
      <Typography
        variant="caption"
        color="white"
        marginTop={1}
        sx={{
          opacity: 0.4,
        }}
      >
        Дистрибуцијата на осигурувањето е овозможена од Друштво за застапување во осигурувањето -
        ОНЕ Брокер доо Скопје, со лиценца број УП 19-1-258 од 10.10.2017 година.
      </Typography>
      <a target="_blank" href="https://ecommerce.mk/badge/companies/kompa-mk/" rel="noreferrer">
        <img
          src="https://i0.wp.com/ecommerce.mk/wp-content/uploads/2023/11/bedh-za-verifikuvan-e-trgovecz-15.png?fit=225%2C225&ssl=1"
          alt="Verificiran badge"
        />
      </a>
    </FooterECommerceVerification>
  </FooterContainer>
);
