import { useState } from 'react';

import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';

export type Item<T> = {
  title: string;
  key: keyof T;
};

interface Props<T> {
  items: Array<Item<T>>;
  data: T;
  title: string;
  openByDefault?: boolean;
  openKeys?: Array<keyof T>;
}

export const ItemDetails = <T extends Record<string, string | number>>({
  items,
  data,
  title,
  openByDefault,
  openKeys,
}: Props<T>) => {
  const [openAccordions, setOpenAccordions] = useState<Set<keyof T>>(() => {
    if (openByDefault) {
      return new Set(openKeys ?? ['description', 'exception']);
    }

    return new Set();
  });

  const onAccordionClick = (key: keyof T) => () => {
    if (openAccordions.has(key)) {
      const newSet = new Set(openAccordions);
      newSet.delete(key);
      setOpenAccordions(newSet);
    } else {
      setOpenAccordions(new Set([...openAccordions, key]));
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" marginBottom={2}>
      <Typography variant="body1" color="primary.dark" fontWeight={600} marginBottom={2}>
        {title}
      </Typography>
      <Divider />
      {items.map((item) => (
        <Accordion
          elevation={0}
          expanded={openAccordions.has(item.key)}
          onChange={onAccordionClick(item.key)}
          disableGutters
        >
          <AccordionSummary>
            {openAccordions.has(item.key) ? (
              <KeyboardArrowDown
                sx={{
                  color: 'primary.main',
                }}
              />
            ) : (
              <KeyboardArrowRight
                sx={{
                  color: 'primary.main',
                }}
              />
            )}
            <Typography variant="body1" color="primary.main" fontWeight={600}>
              {item.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption" color="primary.dark">
              {data[item.key]}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
