import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Hazard: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 707.8 611.5" sx={{ fill: '#FFF' }}>
    <g id="eXxjlS.tif">
      <g>
        <path
          d="M273.4,386.4c0-42.9,0-85.7,0-128.6c-32.2,0-64.2,0-96.4,0c0,42.8,0,85.7,0,128.6c-37.7,0-75.2,0-112.8,0
			c0-64.4,0-128.6,0-193c-21.4,0-42.7,0-63.9,0c-0.1-0.1-0.2-0.3-0.2-0.4C75,128.7,150,64.4,225.1,0
			c75.1,64.3,150,128.6,225.4,193.2c-21.8,0-43,0-64.5,0c0,64.5,0,128.8,0,193.2C348.5,386.4,311.1,386.4,273.4,386.4z"
        />
        <path
          d="M403.5,68c26.5-22.7,52.8-45.3,79.3-68c75.1,64.3,150.1,128.6,225.1,192.9c-0.1,0.1-0.1,0.3-0.2,0.4
			c-21.2,0-42.4,0-63.8,0c0,64.5,0,128.7,0,193.1c-64.4,0-128.7,0-193.1,0c0-53.6,0-107.2,0-160.9c42.7,0,85.4,0,128.6,0
			C520.5,172.8,462.1,120.5,403.5,68z"
        />
        <path
          d="M450.5,482.9c0-21.3,0-42.5,0-63.6c32.2,32.2,64.2,64.2,96.1,96.1c-31.8,31.8-63.8,63.8-96.1,96.1
			c0-21.2,0-42.4,0-63.8c-64.6,0-128.8,0-193.3,0c0,21.3,0,42.4,0,63.3c-32-32-64.1-64.1-95.9-95.9c31.7-31.7,63.8-63.7,95.9-95.9
			c0,21.1,0,42.4,0,63.7C321.8,482.9,386,482.9,450.5,482.9z"
        />
      </g>
    </g>
  </SvgIcon>
);

Hazard.muiName = 'Hazard';
