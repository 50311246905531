import { useQuery } from '@tanstack/react-query';

import { useSortOrder } from './use-sort-order';
import { fetchPolicySuggestions } from '../../../../api/policies';
import { ApiPolicy, fromApiSubjectType } from '../../../../api/policies/types';
import { riskPriority } from '../../../../components/icons/riskIcons';
import { ConstructionType } from '../../../../types/home-insurance';
import { useBuildingData } from '../../preview-policy/hooks/use-building-data';
import { fromApiPolicy } from '../types';

export const usePolicySuggestions = (
  size: number,
  sortParam: 'recommended' | 'price' | 'risks' = 'recommended',
  activeFilters: string[] = [],
  itemsType: 'standard' | 'lux' = 'standard',
  enabled = true,
  selectedInsuredItems: string[] = [],
) => {
  const { sortOrder } = useSortOrder();
  const buildingData = useBuildingData();
  const objectType = buildingData?.buildingType ?? 'apartment';

  const constructionType: ConstructionType = 'solid'; // no automated suggestions for mixed construction

  const { isLoading, data: apiPolicies } = useQuery({
    queryKey: ['policySuggestions', size, constructionType, objectType],
    queryFn: () => fetchPolicySuggestions(size, constructionType, itemsType, objectType),
    enabled,
  });

  const insuredItemsFromStore = buildingData?.insuredItems ?? [];
  const insuredItems =
    selectedInsuredItems.length > 0 ? selectedInsuredItems : insuredItemsFromStore;

  const sortFn = (a: ApiPolicy, b: ApiPolicy) => {
    if (sortParam === 'price') {
      return sortOrder === 'asc'
        ? a.totalPremium - b.totalPremium
        : b.totalPremium - a.totalPremium;
    }
    if (sortParam === 'risks') {
      return sortOrder === 'asc'
        ? a.risks.length - b.risks.length
        : b.risks.length - a.risks.length;
    }
    return sortOrder === 'asc' ? a.kompaRating - b.kompaRating : b.kompaRating - a.kompaRating;
  };

  const filterFn = (policy: ApiPolicy) => {
    return activeFilters.length === 0 ? true : activeFilters.includes(policy.company.key);
  };

  const filteredPolicies =
    apiPolicies?.filter((offer) =>
      insuredItems.every((selectedItem) =>
        offer.subjects.some((subject) => fromApiSubjectType(subject.type) === selectedItem),
      ),
    ) ?? [];

  const recommendedPolicyId = filteredPolicies?.sort((a, b) => b.kompaRating - a.kompaRating)?.[0]
    ?.id;

  const sortedPolicies = [...(filteredPolicies?.filter(filterFn).sort(sortFn) ?? [])];

  // cheapest policy
  const cheapestPolicy =
    sortParam === 'recommended'
      ? sortedPolicies?.reduce((acc, policy) => {
          if (acc.totalPremium > policy.totalPremium) {
            return policy;
          }

          return acc;
        }, sortedPolicies[0])
      : null;

  return {
    isLoading,
    data: sortedPolicies
      ?.map(fromApiPolicy((policyId) => policyId === recommendedPolicyId))
      .map((policy) => ({
        ...policy,
        risks: policy.risks.sort(
          (a, b) => (riskPriority[a.key] ?? Infinity) - (riskPriority[b.key] ?? Infinity),
        ),
      })),
    cheapestPolicy: cheapestPolicy ? fromApiPolicy(() => false)(cheapestPolicy) : null,
  };
};
