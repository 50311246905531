import styled from 'styled-components';

import { Icon } from '@mui/material';
import { Box } from '@mui/system';

export const NextStepsIconContainer = styled(Box)`
  position: relative;
  width: 100px;
  height: 100px;
  align-self: center;
  justify-self: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NextStepsIcon = styled(Icon)`
  font-size: 40px;
`;

export const NextStepsStepNumber = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.accent.main};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.common.white};
`;

type NextStepsArrowProps = {
  middleIcon?: React.ReactNode;
};

export const Line = ({ width }: { width?: string }) => (
  <svg width={width ?? '50'} height="2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="1" x2="50" y2="1" stroke="#F79256" strokeWidth="2" />
  </svg>
);

export const Circle = ({ middleIcon }: { middleIcon: React.ReactNode }) => (
  <Box
    position="relative"
    width="32px"
    height="32px"
    sx={{
      transform: { xs: 'rotate(-90deg)', md: 'rotate(0deg)' },
    }}
  >
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#F79256" />
    </svg>
    <Box
      position="absolute"
      top="50%"
      left="50%"
      sx={{
        transform: 'translate(-50%, -50%)',
        color: 'white',
        lineHeight: '12px',
        display: 'block',
      }}
    >
      {middleIcon}
    </Box>
  </Box>
);

export const Arrow = () => (
  <svg width="40" height="50" viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 25H40M40 25L30 15M40 25L30 35"
      stroke="#F79256"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const NextStepsArrow = ({ middleIcon }: NextStepsArrowProps) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    paddingY={{
      xs: 6,
      md: 0,
    }}
    sx={{
      transform: { xs: 'rotate(90deg)', md: 'rotate(0deg)' },
    }}
  >
    <Line />
    <Circle middleIcon={middleIcon} />
    <Line width="10" />
    <Arrow />
  </Box>
);
