import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Installation: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 14">
    <path d="M0 0V8H2V6H8L10 4L8 2H2V0H0ZM18 0V2H10L12 4L10 6H18V8H20V0H18ZM10 8C10 8 8 10.17 8 11.5C8 12.0304 8.21071 12.5391 8.58579 12.9142C8.96086 13.2893 9.46957 13.5 10 13.5C10.5304 13.5 11.0391 13.2893 11.4142 12.9142C11.7893 12.5391 12 12.0304 12 11.5C12 10.17 10 8 10 8Z" />
  </SvgIcon>
);

Installation.muiName = 'Installation';
