import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Typography } from '@mui/material';

import IconRadio from '../../../components/form/icon-radio';
import { FormNavigation } from '../../../components/form-navigation';
import { LogoColored } from '../../../components/icons/logo-colored';
import { Mixed } from '../../../components/icons/mixed';
import { Solid } from '../../../components/icons/solid';
import { constructionTypeState, homeInsuranceState } from '../../../stores/home-insurance';
import { useSetRecoilKeyValue } from '../../../stores/use-set-recoil-key-value';
import { useIsTabletOrLarger } from '../../../util/useIsTabletOrLarger';
import { useNavigatePreserveQuery } from '../../../util/useNavigatePreserveQuery';
import { BaseContainer, ChoicesContainer, TitleText } from '../styled';

export const ConstructionType = () => {
  const constructionType = useRecoilValue(constructionTypeState);

  const [selectedConstructionType, setSelectedConstructionType] = useState<'solid' | 'mixed'>(
    constructionType,
  );

  const setKeyValue = useSetRecoilKeyValue(homeInsuranceState);

  const navigate = useNavigatePreserveQuery();

  const isTabletOrLarger = useIsTabletOrLarger();

  const onSubmit = useCallback(() => {
    setKeyValue('constructionType', selectedConstructionType);
    navigate('../extras');
  }, [navigate, selectedConstructionType, setKeyValue]);

  const onBack = useCallback(() => {
    navigate('../building');
  }, [navigate]);

  return (
    <>
      <BaseContainer>
        <LogoColored />
        <TitleText variant="h4" color="primary" fontWeight="bold" align="center">
          Да го избереме најдоброто домаќинско за тебе, заедно!
        </TitleText>
        <Typography variant="subtitle1" color="primary.dark" marginTop={3}>
          Објектот за кој станува збор е од:
        </Typography>
        <ChoicesContainer gridTemplateColumns="1fr 1fr" marginTop={2}>
          <IconRadio
            value="massive"
            label="Цврста градба"
            icon={Solid}
            active={selectedConstructionType === 'solid'}
            onClick={() => setSelectedConstructionType('solid')}
            description="Надворешните ѕидови се од камен, печена тула, бетон, а покривот од керамиди, етернит, салонит, лим, армиран бетон."
          />
          <IconRadio
            value="mixed"
            label="Мешовита градба"
            icon={Mixed}
            active={selectedConstructionType === 'mixed'}
            onClick={() => setSelectedConstructionType('mixed')}
            description="Надворешните ѕидови се од дрвен или челичен костур со салонит, сипорекс, хераклит а покривот од дрвена или челична конструкција, керамиди, етернит, салонит и лим."
          />
        </ChoicesContainer>
      </BaseContainer>
      <FormNavigation
        onNext={onSubmit}
        nextLabel={isTabletOrLarger ? 'Следно прашање' : 'Следно'}
        prevLabel={isTabletOrLarger ? 'Претходно прашање' : 'Претходно'}
        nextSubtitle={selectedConstructionType !== 'mixed' ? 'Уште 1 прашање' : ''}
        onPrev={onBack}
      />
    </>
  );
};
