import { styled } from 'styled-components';

import { Box, Typography } from '@mui/material';

export const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: ${({ theme }) => theme.spacing(2)};
  }
`;

export const TitleText = styled(Typography).attrs({
  variant: 'h6',
})`
  color: ${({ theme }) => theme.palette.accent.main};
`;

export const ChoicesContainer = styled(Box).attrs({
  display: 'grid',
})`
  grid-column-gap: ${({ theme }) => theme.spacing(3)};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-column-gap: ${({ theme }) => theme.spacing(2)};
  }
`;

export const MixedConstructionMessageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(3)};
`;
