import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { homeInsuranceState } from './home-insurance';
import { CITY_LABELS_MAP } from '../data/cities';
import { toBinary } from '../util/string';

export const useHomeInsuranceRequestDetails = () => {
  const [queryParams] = useSearchParams();

  const area = queryParams.get('area');
  const cityFromUrl = queryParams.get('city');

  if (!cityFromUrl) {
    throw new Error('City is required');
  }

  const formattedCity = cityFromUrl.toLowerCase().replace(/-/g, ' ');

  // Cyrillic city names in URL handling
  const city = Object.keys(CITY_LABELS_MAP)
    .map((k) => k.slice().toLowerCase())
    .includes(formattedCity)
    ? cityFromUrl
    : Object.entries(CITY_LABELS_MAP).find(
        ([, value]) => value.slice().toLowerCase() === formattedCity,
      )?.[0];

  if (!city) {
    throw new Error('City is required');
  }

  const parsedArea = Number.parseInt(area ?? '0', 10);

  const homeInsurance = useRecoilValue(homeInsuranceState);

  const details = { ...homeInsurance, area: parsedArea, city };

  return toBinary(JSON.stringify(details));
};
