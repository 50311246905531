import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { createTheme as createMuiTheme } from '@mui/material/styles';

import { BLUE, GRAY, GRAY_PALETTE, GREEN, ORANGE, RED, TEAL, YELLOW } from './colors';

export const createTheme = () => {
  const theme = createMuiTheme({
    typography: {
      fontFamily: ['-apple-system', 'Inter', 'Open Sans', 'Roboto', 'Arial', 'sans-serif'].join(
        ',',
      ),
      h1: {
        fontSize: '80px',
        fontFamily: ['-apple-system', 'Nunito', 'Open Sans', 'Roboto', 'Arial', 'sans-serif'].join(
          ',',
        ),
        fontWeight: 800,
      },
      h2: {
        fontSize: '64px',
        fontWeight: 600,
      },
      h3: {
        fontSize: '24px',
        fontWeight: 600,
        fontFamily: ['-apple-system', 'Nunito', 'Open Sans', 'Roboto', 'Arial', 'sans-serif'].join(
          ',',
        ),
      },
      h4: {
        fontSize: '40px',
        fontWeight: 600,
      },
      h5: {
        fontSize: '20px',
        fontWeight: 600,
      },
      h6: {
        fontSize: '24px',
        fontWeight: 600,
      },
      subtitle1: {
        fontSize: '18px',
        fontWeight: 600,
        color: BLUE[700],
      },
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: '36px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          // @ts-ignore
          root: {
            borderRadius: '24px !important',
            border: 'none',
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          // @ts-ignore
          root: ({ theme: innerTheme }) => ({
            backgroundColor: innerTheme.palette.common.white,
            color: innerTheme.palette.text.primary,
            paddingRight: 0,
            '&.Mui-focused': {
              backgroundColor: innerTheme.palette.common.white,
              color: innerTheme.palette.text.primary,
            },
            '&:hover': {
              backgroundColor: innerTheme.palette.common.white,
              color: innerTheme.palette.text.primary,
            },
          }),
          // @ts-ignore
          input: ({ theme: innerTheme }) => ({
            padding: 0,
            paddingLeft: innerTheme.spacing(1.5),
          }),
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          // @ts-ignore
          root: ({ theme: innerTheme }) => ({
            backgroundColor: innerTheme.palette.common.white,
            color: innerTheme.palette.text.primary,
            paddingRight: 0,
            '&.Mui-focused': {
              backgroundColor: innerTheme.palette.common.white,
              color: innerTheme.palette.text.primary,
            },
            '&:hover': {
              backgroundColor: innerTheme.palette.common.white,
              color: innerTheme.palette.text.primary,
            },
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          // @ts-ignore
          root: ({ theme: innerTheme }) => ({
            borderRadius: innerTheme.spacing(2),
          }),
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          // @ts-ignore
          root: ({ ownerState, theme: innerTheme }) => ({
            fontFamily: [
              '-apple-system',
              'Nunito',
              'Open Sans',
              'Roboto',
              'Arial',
              'sans-serif',
            ].join(','),
            borderRadius: '24px',
            textTransform: 'none',
            ...(ownerState.color === 'primary' && {
              backgroundColor: innerTheme.palette.accent[500],
              '&:hover': {
                backgroundColor: innerTheme.palette.accent[600],
              },
            }),
            ...(ownerState.variant === 'text' && {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }),
          }),
        },
      },
      MuiTab: {
        styleOverrides: {
          // @ts-ignore
          root: ({ theme: innerTheme }) => ({
            color: innerTheme.palette.grey[500],
            padding: innerTheme.spacing(0.5, 1),
            fontSize: '1.125rem',
            textTransform: 'none',
            '&.Mui-selected': {
              color: innerTheme.palette.primary.dark,
              fontWeight: 600,
            },
          }),
        },
      },
      MuiTabs: {
        styleOverrides: {
          // @ts-ignore
          indicator: ({ theme: innerTheme }) => ({
            background: innerTheme.palette.accent.main,
          }),
        },
      },
      MuiTooltip: {
        styleOverrides: {
          // @ts-ignore
          tooltip: ({ theme: innerTheme }) => ({
            color: innerTheme.palette.grey[400],
            backgroundColor: innerTheme.palette.common.white,
            borderRadius: innerTheme.spacing(0.5),
          }),
        },
      },
      MuiCheckbox: {
        defaultProps: {
          icon: <RadioButtonUnchecked fontSize="medium" />,
          checkedIcon: <CheckCircle />,
        },
        styleOverrides: {
          // @ts-ignore
          root: ({ theme: innerTheme }) => ({
            color: innerTheme.palette.grey[400],
            '&.Mui-checked': {
              color: innerTheme.palette.accent.main,
            },
          }),
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          // @ts-ignore
          root: ({ theme: innerTheme }) => ({
            padding: innerTheme.spacing(0),
            // '&.Mui-expanded': {
            //   minHeight: 0,
            // },
          }),
          // content: {
          //   '&.Mui-expanded': {
          //     margin: '12px 0',
          //   },
          // },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          // @ts-ignore
          root: ({ theme: innerTheme }) => ({
            paddingLeft: innerTheme.spacing(1),
          }),
        },
      },
    },
  });

  const orange = theme.palette.augmentColor({
    color: ORANGE,
    lightShade: 50,
    name: 'accent',
  });

  return createMuiTheme(theme, {
    palette: {
      primary: theme.palette.augmentColor({
        color: BLUE,
        lightShade: 100,
        darkShade: 700,
        name: 'primary',
      }),
      secondary: theme.palette.augmentColor({
        color: TEAL,
        lightShade: 100,
        darkShade: 700,
        name: 'secondary',
      }),
      success: GREEN,
      warning: YELLOW,
      error: RED,
      grey: GRAY,
      info: theme.palette.augmentColor({
        color: GRAY_PALETTE,
        name: 'info',
      }),
      accent: {
        main: orange.main,
        light: orange.light,
        dark: orange.dark,
        contrastText: theme.palette.common.white,
      },
    },
  });
};
