import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Defect: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 21 17">
    <path d="M3.5 17V9H0.5L10.5 0L20.5 9H17.5V17H3.5ZM10.5 2.69L5.5 7.19V15H15.5V7.19L10.5 2.69ZM10 15V11H7.5L11 4V8H13.5L10 15Z" />
  </SvgIcon>
);

Defect.muiName = 'Defect';
