import { useEffect } from 'react';
import { useLocation } from 'react-router';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const TrackPageView = () => {
  const location = useLocation();
  useEffect(() => {
    // Push a virtual pageview to GTM when the URL changes
    window.dataLayer.push({
      event: 'virtualPageview',
      pagePath: window.location.pathname,
    });
  }, [location.pathname]);
  return null;
};

export default TrackPageView;
