import Croatia from './logos/croatia.png';
import Eurolink from './logos/eurolink.png';
import Evroins from './logos/evroins.png';
import Halk from './logos/halk.png';
import Makedonija from './logos/makedonija.png';
import Sava from './logos/sava.png';
import Triglav from './logos/triglav.png';
import Uniqa from './logos/uniqa.png';
import Winner from './logos/winner.png';
import { Company } from '../../types';

export const CompanyKeyToShortName: Record<Company, string> = {
  winner: 'Винер',
  sava: 'Сава',
  triglav: 'Триглав',
  eurolink: 'Еуролинк',
  croatia: 'Кроација',
  makedonija: 'Македонија',
  halk_insurance: 'Халк',
  uniqa: 'Уника',
  euroins: 'Евроинс',
};

export const CompanyLogoMap: Record<Company, string> = {
  winner: Winner,
  croatia: Croatia,
  eurolink: Eurolink,
  makedonija: Makedonija,
  sava: Sava,
  triglav: Triglav,
  halk_insurance: Halk,
  uniqa: Uniqa,
  euroins: Evroins,
};
