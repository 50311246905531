import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TreeFall: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 18">
    <path
      d="M6,0C3.5,0,1.5,2,1.5,4.5c0,0.61,0.13,1.21,0.37,1.77C1,7.12,0.5,8.28,0.5,9.5C0.5,12,2.5,14,5,14
	c0.5,0,1-0.11,1.5-0.28V18h2v-5.23C9,12.91,9.5,13,10,13c0.72,0,1.44-0.14,2.1-0.42c0.67-0.28,1.27-0.68,1.78-1.19
	s0.92-1.12,1.19-1.78c0.28-0.67,0.42-1.38,0.42-2.1s-0.14-1.44-0.42-2.1c-0.28-0.67-0.68-1.27-1.19-1.78
	C13.38,3.1,12.77,2.7,12.1,2.42C11.44,2.14,10.72,2,10,2C9.91,2,9.83,2,9.74,2C8.91,0.76,7.5,0,6,0z M6,2c1.32,0,2.41,1.03,2.5,2.35
	C8.96,4.12,9.5,4,10,4c0.93,0,1.82,0.37,2.47,1.03c0.66,0.66,1.03,1.55,1.03,2.47s-0.37,1.82-1.03,2.47C11.82,10.63,10.93,11,10,11
	c-0.96,0-1.87-0.39-2.54-1.09C7.26,11.12,6.22,12,5,12c-0.66,0-1.3-0.26-1.77-0.73C2.76,10.8,2.5,10.16,2.5,9.5
	c0-1.38,0.8-1.96,2-2.71c-0.8-1.03-1-1.63-1-2.29c0-0.66,0.26-1.3,0.73-1.77C4.7,2.26,5.34,2,6,2z"
    />
  </SvgIcon>
);

TreeFall.muiName = 'TreeFall';
