import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { Box } from '@mui/material';

import { Footer } from '../../components/footer';
import TrackPageView from '../../util/TrackPageView';

export const InsuranceBase = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isOrderPage = location.pathname.includes('policy');

  const isInsuranceRoot = location.pathname === '/insurance';

  useEffect(() => {
    if (isInsuranceRoot) {
      navigate('/insurance/home');
    }
  }, [isInsuranceRoot, navigate]);

  return (
    <>
      <Box
        minHeight="75.1vh"
        sx={{
          background: (theme) => (isOrderPage ? '#FFF' : `${theme.palette.accent.light}76`),
        }}
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Outlet />
      </Box>
      <Footer />
      <TrackPageView />
    </>
  );
};
