import { Navigate } from 'react-router';

import { ArrowOutward } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';

import { BasicInfoContainer, Logo, PriceContainer } from './styled';
import { IconFilled } from '../../../../../components/icon-filled';
import { Hazard } from '../../../../../components/icons/hazard';
import { riskIcons, riskShortLabels } from '../../../../../components/icons/riskIcons';
import { CITY_LABELS_MAP } from '../../../../../data/cities';
import { BuildingType } from '../../../../../types/home-insurance';
import { useNavigatePreserveQuery } from '../../../../../util/useNavigatePreserveQuery';
import { THIRD_PARTY_DAMAGE_DESCRIPTION } from '../../../results/components/policy';
import { CompanyLogoMap } from '../../../results/components/policy/constants';
import Ananas from '../../../results/components/policy/logos/ananas.png';
import { PolicySuggestion } from '../../../results/types';
import { useBuildingData } from '../../hooks/use-building-data';

interface Props {
  policy: PolicySuggestion;
}

const BuildingTypeLabel: Record<BuildingType, string> = {
  apartment: 'Стан',
  house: 'Куќа',
};

export const BasicInfo = ({ policy }: Props) => {
  const logoSource = CompanyLogoMap[policy.company];

  const buildingData = useBuildingData();

  const navigate = useNavigatePreserveQuery();

  if (!buildingData) {
    return <Navigate to="/" />;
  }

  const openPolicyDetails = () => {
    navigate('./details');
  };

  const { city, area, buildingType } = buildingData;

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <BasicInfoContainer>
        <Box padding={4}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginLeft={-1}
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap={2}
          >
            <Logo src={logoSource} />
            <Typography variant="h5" color="primary.dark">
              {policy.name}
            </Typography>
          </Box>
          <Box marginTop={3} marginBottom={3}>
            <Divider />
          </Box>
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              <Typography variant="body1" color="primary.dark" fontWeight={300}>
                Објект
              </Typography>
              <Typography variant="body1" color="primary.dark" fontWeight="bold" marginLeft={2}>
                {BuildingTypeLabel[buildingType]}
              </Typography>
            </Box>
            <Box marginTop={2} display="flex">
              <Typography variant="body1" color="primary.dark" fontWeight={300}>
                Општина
              </Typography>
              <Typography variant="body1" color="primary.dark" fontWeight="bold" marginLeft={2}>
                {CITY_LABELS_MAP[city] || city}
              </Typography>
            </Box>
            <Box marginTop={2} display="flex">
              <Typography variant="body1" color="primary.dark" fontWeight={300}>
                Квадратура
              </Typography>
              <Typography variant="body1" color="primary.dark" fontWeight="bold" marginLeft={2}>
                {area} м<sup>2</sup>
              </Typography>
            </Box>
          </Box>
          <Box marginTop={3} marginBottom={3}>
            <Divider />
          </Box>
          <Box display="grid" gridTemplateColumns={{ xs: '1fr', sm: '1fr 8fr' }} gap={1}>
            <Typography variant="body1" color="primary.dark" fontWeight={300}>
              Ризици
            </Typography>
            <Box
              display="flex"
              gap={3}
              flexWrap="wrap"
              justifyContent={{
                xs: 'space-between',
                sm: 'flex-start',
              }}
            >
              {policy.risks.map((risk) => (
                <IconFilled
                  key={risk.key}
                  icon={riskIcons[risk.key]}
                  width="40px"
                  containerWidth="70px"
                  active
                  label={riskShortLabels[risk.key] || risk.key}
                  tooltip={risk.note}
                  description={risk.descriptionTitle}
                />
              ))}
              {/* Third party damage is a subject, not a risk, so it's hardcoded here */}
              {policy.subjects.find((subject) => subject.type === 'THIRD_PARTY_DAMAGE') && (
                <IconFilled
                  icon={Hazard}
                  active
                  label="трети лица"
                  width="40px"
                  containerWidth="70px"
                  description={THIRD_PARTY_DAMAGE_DESCRIPTION}
                />
              )}
            </Box>
          </Box>
          <Box marginTop={3} marginBottom={3}>
            <Divider />
          </Box>
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="body1" color="primary.dark" fontWeight={300}>
                Обработка од страна на Компа
              </Typography>
              <Typography
                variant="body1"
                color="primary.dark"
                fontWeight="bold"
                marginLeft={2}
                fontSize={18}
              >
                бесплатно
              </Typography>
            </Box>
          </Box>
        </Box>
        <PriceContainer display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography
              variant="h6"
              fontWeight={400}
              color="primary.dark"
              textTransform="uppercase"
            >
              Вкупно
            </Typography>
            <Typography
              variant="body1"
              color="primary.dark"
              fontWeight="bold"
              fontSize={26}
              position="relative"
            >
              <Typography
                variant="body1"
                color="grey.600"
                fontWeight={300}
                sx={{
                  position: 'absolute',
                  top: (theme) => `-${theme.spacing(2.25)}`,
                  right: 0,
                }}
              >
                годишно
              </Typography>
              {policy.totalPremium} ден
              <Typography
                variant="body1"
                color="grey.600"
                fontWeight={300}
                sx={{
                  position: 'absolute',
                  bottom: (theme) => `-${theme.spacing(3)}`,
                  right: 0,
                }}
              >
                {policy.couponValue} ден
              </Typography>
            </Typography>
          </Box>
          <Typography>
            + Подарок{' '}
            <Box
              component="img"
              src={Ananas}
              sx={{
                width: 14,
                height: 14,
              }}
            />{' '}
            ваучер
          </Typography>
        </PriceContainer>
      </BasicInfoContainer>
      <Stack
        direction="row"
        width="100%"
        justifyContent={{ xs: 'center', md: 'flex-end' }}
        marginTop={2}
      >
        <Button
          variant="contained"
          color="accent"
          endIcon={<ArrowOutward />}
          disableRipple
          onClick={openPolicyDetails}
        >
          Прочитај ги сите детали за полисата
        </Button>
      </Stack>
    </Box>
  );
};
