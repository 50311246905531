import styled from 'styled-components';

import { Box } from '@mui/material';

export const FooterContainer = styled.footer`
  background-color: transparent;
  background: linear-gradient(128deg, #3c83b1 0%, #1a557a 100%);
  color: ${({ theme }) => theme.palette.primary.contrastText};

  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled(Box).attrs({
  display: 'grid',
})`
  width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing(8)} ${({ theme }) => theme.spacing(12)};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(2)};
  }
`;

export const FooterCopyright = styled(Box)`
  padding: 0 ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => theme.spacing(0.125)} solid;
  border-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    ${({ theme }) => theme.palette.grey[300]} 100%
  );
  border-image-slice: 1;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(12)};
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(2)};
    justify-content: center;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${({ theme }) => theme.spacing(2)};
  }
`;

export const FooterECommerceVerification = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(12)};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(2)};
    justify-content: center;
    img {
      width: 100px;
    }
  }
`;
