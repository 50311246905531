import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Protest: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 17 23">
    <path d="M10.957 7.625H8.89453C8.67969 7.625 8.46484 7.71094 8.20703 7.75391V1.4375C8.20703 1.09375 8.55078 0.75 8.89453 0.75H10.2695C10.6562 0.75 10.957 1.09375 10.957 1.4375V7.625ZM16.457 11.75L16.5 15.5742C16.5 16.5195 15.9414 17.8086 15.2539 18.4961L13.707 20.043V22.75H2.70703V20L1.58984 18.8828C0.558594 17.8516 0 16.4336 0 14.9727V11.6641C0.214844 11.707 0.429688 11.75 0.6875 11.75H2.0625C2.57812 11.75 3.05078 11.5781 3.4375 11.2344C3.78125 11.5781 4.25391 11.75 4.8125 11.75H6.1875C6.48828 11.75 6.83203 11.6641 7.08984 11.5352C7.43359 12.2227 8.03516 12.7812 8.76562 12.9961C8.03516 13.6406 7.43359 14.5 6.74609 15.4883L6.48828 15.918C6.44531 15.9609 6.40234 16.0469 6.40234 16.0898C6.40234 16.2188 6.48828 16.3477 6.57422 16.3906L7.13281 16.7773C7.17578 16.8203 7.26172 16.8203 7.34766 16.8203C7.43359 16.8203 7.5625 16.7773 7.60547 16.6914L7.90625 16.2617C9.23828 14.1992 10.0977 13.2539 12.0312 13.168C12.2031 13.125 12.332 12.9961 12.332 12.8242V12.0938C12.332 11.9219 12.2031 11.75 11.7734 11.75H10.2695C9.15234 11.75 8.20703 10.8477 8.20703 9.73047V9.6875C8.20703 9.34375 8.55078 9 8.89453 9H13.707C15.2539 9 16.457 10.2461 16.457 11.75ZM15.082 7.88281C14.6523 7.75391 14.2227 7.625 13.707 7.625H12.332V2.8125C12.332 2.46875 12.6758 2.125 13.0195 2.125H14.3945C14.7812 2.125 15.082 2.46875 15.082 2.8125V7.88281ZM0.6875 10.375C0.300781 10.375 0 10.0742 0 9.6875V4.1875C0 3.84375 0.300781 3.5 0.6875 3.5H2.0625C2.40625 3.5 2.75 3.84375 2.75 4.1875V9.6875C2.75 10.0742 2.40625 10.375 2.0625 10.375H0.6875ZM4.76953 10.375C4.42578 10.375 4.08203 10.0742 4.08203 9.6875V2.8125C4.08203 2.46875 4.42578 2.125 4.76953 2.125H6.14453C6.53125 2.125 6.83203 2.46875 6.83203 2.8125V9.6875C6.83203 10.0742 6.53125 10.375 6.14453 10.375H4.76953Z" />
  </SvgIcon>
);

Protest.muiName = 'Protest';
