import React, { ComponentType } from 'react';

import { Help } from '@mui/icons-material';
import { Typography, Box, Tooltip } from '@mui/material';

import { ActionPoint, Container, IconContainer, RadioContainer } from './styled';

interface Props<Value extends string> {
  label: string;
  value: Value;
  icon: ComponentType;
  active: boolean;
  onClick: (e: any) => void;
  description?: string;
  actionPoint?: string;
  disabled?: boolean;
}

const IconRadio = <Value extends string>({
  label,
  icon: Icon,
  active,
  value,
  onClick,
  description,
  actionPoint,
  disabled,
}: Props<Value>) => (
  <Container>
    <RadioContainer
      onClick={disabled ? undefined : () => onClick({ target: { value } })}
      elevation={0}
      active={active || disabled}
    >
      {actionPoint ? (
        <ActionPoint>
          <Typography variant="caption" color="common.white" fontSize={12} align="right">
            {actionPoint}
          </Typography>
        </ActionPoint>
      ) : null}
      {/* @ts-ignore */}
      <IconContainer>
        <Icon
          /* @ts-ignore */
          sx={{
            fontSize: '24px',
            color: 'white',
          }}
        />
      </IconContainer>
      <Typography variant="h3" color="primary.dark" fontWeight={600}>
        {label}
      </Typography>
    </RadioContainer>
    {description ? (
      <Tooltip title={description} placement="bottom" enterTouchDelay={0}>
        <Box width="110%" display="flex" alignItems="center" justifyContent="center" marginTop={2}>
          <Help sx={{ color: 'grey.400' }} fontSize="small" />
          <Typography
            variant="caption"
            color="grey.400"
            fontSize={12}
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Што подразбира?
          </Typography>
        </Box>
      </Tooltip>
    ) : null}
  </Container>
);

export default IconRadio;
