type SortOrder = 'asc' | 'desc';

export const sortBy =
  <T>(prop: keyof T, order: SortOrder) =>
  (arr: T[]): T[] =>
    [...arr].sort((a, b) => {
      if (order === 'asc') {
        return a[prop] > b[prop] ? 1 : -1;
      }

      return a[prop] < b[prop] ? 1 : -1;
    });
