import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Avalanche: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 22 12" sx={{ fill: '#FFF' }}>
    <g>
      <path d="M13,0L9.2,5l2.9,3.8L10.5,10C8.8,7.8,6,4,6,4l-6,8h22L13,0z" />
      <circle cx="17" cy="1.6" r="1.2" />
      <circle cx="18.1" cy="4.5" r="0.8" />
      <circle cx="20.9" cy="4.5" r="0.8" />
      <circle cx="19" cy="6.7" r="0.5" />
      <circle cx="21.1" cy="7.7" r="0.2" />
    </g>
  </SvgIcon>
);

Avalanche.muiName = 'Avalanche';
