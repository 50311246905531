import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Fire: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 21.1 18.6"
    sx={{
      fill: '#FFF',
    }}
  >
    <path
      className="st0"
      d="M20.1,11.3L20.1,11.3c0.2,0.3,0.4,0.6,0.6,0.9l0.1,0.2c0.7,1.7,0.2,3.6-1.1,4.9c-1.2,1.1-2.9,1.4-4.4,1.2
	c-1.5-0.2-2.8-1.1-3.6-2.4c-0.2-0.4-0.4-0.8-0.5-1.3c-0.1-0.4-0.2-0.7-0.2-1.1c-0.1-1.6,0.6-3.3,1.8-4.3c-0.6,1.2-0.4,2.7,0.4,3.8
	l0.1,0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.2-0.1,0.3-0.2,0.3-0.4l-0.1-0.2c-0.9-2.3-0.1-5,1.7-6.6c0.5-0.4,1.1-0.8,1.8-1
	c-0.7,1.4-0.5,3.1,0.6,4.2c0.5,0.5,1,0.8,1.5,1.2L20.1,11.3z M17.9,16L17.9,16c0.4-0.4,0.7-1.1,0.7-1.7l0-0.3
	c-0.2-1-1.1-1.3-1.6-2.1l-0.4-0.8c-0.2,0.5-0.2,1-0.1,1.5c0.1,0.6,0.3,1.1,0.2,1.7c-0.2,0.6-0.7,1.3-1.6,1.5
	c0.5,0.5,1.3,0.9,2.1,0.6C17.3,16.4,17.6,16.2,17.9,16z M1,12h8.1L9,13c0,1.4,0.4,2.7,1,3.9V17H1V12z M0,6h6v5H0V6z M7,6h6v0.1
	C11.2,7.1,9.8,8.9,9.2,11H7V6z M1,0h8v5H1V0z M10,0h9v5h-9V0z"
    />
  </SvgIcon>
);

Fire.muiName = 'Fire';
