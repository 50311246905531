export const toBinary = (str: string): string => {
  const codeUnits = new Uint16Array(str.length);
  for (let i = 0; i < codeUnits.length; i += 1) {
    codeUnits[i] = str.charCodeAt(i);
  }

  return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
};

export const fromBinary = (str: string): string => {
  const binary = atob(str);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i += 1) {
    bytes[i] = binary.charCodeAt(i);
  }

  return String.fromCharCode(...new Uint16Array(bytes.buffer));
};
