import styled, { css } from 'styled-components';

import { Box } from '@mui/material';

export const FilterPillContainer = styled(Box)<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)};
  background: ${({ theme }) => theme.palette.accent.light};
  border-radius: 50px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.palette.accent.main};
    `}

  transition: background 0.2s ease-in-out;
`;
