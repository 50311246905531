import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowReturn: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 13">
    <path
      opacity="0.6"
      d="M1.40909 8.03409V6.78409H11.4886C11.9508 6.78409 12.3712 6.67045 12.75 6.44318C13.1326 6.21591 13.4356 5.91098 13.6591 5.52841C13.8864 5.14583 14 4.72348 14 4.26136C14 3.79924 13.8864 3.37879 13.6591 3C13.4318 2.62121 13.1288 2.31818 12.75 2.09091C12.3712 1.86364 11.9508 1.75 11.4886 1.75H10.9091V0.5H11.4886C12.1818 0.5 12.8125 0.670454 13.3807 1.01136C13.9489 1.34848 14.4015 1.80114 14.7386 2.36932C15.0795 2.9375 15.25 3.56818 15.25 4.26136C15.25 4.7803 15.1515 5.26894 14.9545 5.72727C14.7614 6.18182 14.4924 6.58333 14.1477 6.93182C13.803 7.27652 13.4034 7.54735 12.9489 7.74432C12.4943 7.9375 12.0076 8.03409 11.4886 8.03409H1.40909ZM5.17045 12.5L0.0795455 7.40909L5.17045 2.31818L6.03977 3.1875L1.8125 7.40909L6.03977 11.6307L5.17045 12.5Z"
      fill="#092854"
    />
  </SvgIcon>
);

ArrowReturn.muiName = 'ArrowReturn';
