import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const House: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 29 21">
    <path d="M14.1562 5.4375C14.25 5.34375 14.3906 5.29688 14.5312 5.29688C14.625 5.29688 14.7656 5.34375 14.8594 5.4375L23.5312 12.5156V20.2031C23.5312 20.625 23.1562 20.9531 22.7812 20.9531H17.4844C17.1094 20.9531 16.7344 20.625 16.7344 20.2031V15.7031C16.7344 15.3281 16.4062 14.9531 15.9844 14.9531H12.9844C12.6094 14.9531 12.2344 15.3281 12.2344 15.7031V20.2031C12.2344 20.625 11.9062 20.9531 11.5312 20.9531H6.28125C5.85938 20.9531 5.53125 20.625 5.53125 20.2031V12.5625L14.1562 5.4375ZM27.7969 10.2656C27.9375 10.3594 28.0312 10.5469 28.0312 10.6875C28.0312 10.8281 27.9375 10.9688 27.8906 11.0625L26.6719 12.5156C26.5781 12.6094 26.3906 12.7031 26.25 12.7031C26.1562 12.7031 25.9688 12.6562 25.875 12.5625L14.8594 3.51562C14.7656 3.42188 14.625 3.375 14.5312 3.375C14.3906 3.375 14.25 3.42188 14.1562 3.51562L3.14062 12.5625C3.04688 12.6562 2.85938 12.7031 2.76562 12.7031C2.625 12.7031 2.4375 12.6094 2.34375 12.5156L1.125 11.0625C1.07812 10.9688 0.984375 10.8281 0.984375 10.6875C0.984375 10.5469 1.07812 10.3594 1.21875 10.2656L13.0781 0.46875C13.4062 0.1875 14.0625 0 14.4844 0C14.9531 0 15.6094 0.1875 15.9375 0.46875L20.1562 3.9375V0.5625C20.1562 0.234375 20.3906 0 20.7188 0H23.3438C23.625 0 23.9062 0.234375 23.9062 0.5625V7.03125L27.7969 10.2656Z" />
  </SvgIcon>
);

House.muiName = 'House';
