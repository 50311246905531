import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Solid: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 27 23">
    <path d="M2.28437 16.2353H13.9844V23H2.28437V16.2353ZM0.984375 8.11765H8.78437V14.8824H0.984375V8.11765ZM10.0844 8.11765H17.8844V14.8824H10.0844V8.11765ZM19.1844 8.11765H26.9844V14.8824H19.1844V8.11765ZM15.2844 16.2353H25.6844V23H15.2844V16.2353ZM2.28437 0H12.6844V6.76471H2.28437V0ZM13.9844 0H25.6844V6.76471H13.9844V0Z" />
  </SvgIcon>
);

Solid.muiName = 'Mixed';
