import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Couch: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 31 18">
    <path d="M8.48438 7.5C8.48438 5.85938 7.125 4.5 5.48438 4.5H3.98438C3.98438 2.01562 6 0 8.48438 0H23.4844C25.9688 0 27.9844 2.01562 27.9844 4.5H26.4844C24.7969 4.5 23.4844 5.85938 23.4844 7.5V10.5H8.48438V7.5ZM27.9844 6C29.625 6 30.9844 7.35938 30.9844 9C30.9844 10.125 30.375 11.0625 29.4844 11.625V17.25C29.4844 17.6719 29.1094 18 28.7344 18H25.7344C25.3125 18 24.9844 17.6719 24.9844 17.25V16.5H6.98438V17.25C6.98438 17.6719 6.60938 18 6.23438 18H3.23438C2.8125 18 2.48438 17.6719 2.48438 17.25V11.625C1.59375 11.0625 0.984375 10.125 0.984375 9C0.984375 7.35938 2.29688 6 3.98438 6H5.48438C6.28125 6 6.98438 6.70312 6.98438 7.5V12H24.9844V7.5C24.9844 6.70312 25.6406 6 26.4844 6H27.9844Z" />
  </SvgIcon>
);

Couch.muiName = 'Couch';
