import { CheckCircle, CircleOutlined } from '@mui/icons-material';
import { SxProps, Theme, Typography } from '@mui/material';

import { FilterPillContainer } from './styled';

interface Props {
  active?: boolean;
  label?: string;
  onClick?: (active: boolean) => void;
  sx?: SxProps<Theme>;
}

export const FilterPill = ({ active, label, onClick, sx }: Props) => (
  <FilterPillContainer active={active} onClick={() => onClick?.(!active)} sx={sx}>
    <Typography
      variant="body1"
      fontWeight={300}
      fontSize="0.75rem"
      color={active ? 'common.white' : 'accent.main'}
      marginRight={1}
    >
      {label}
    </Typography>
    {active ? (
      <CheckCircle
        fontSize="small"
        sx={{
          color: (theme) => theme.palette.common.white,
        }}
      />
    ) : (
      <CircleOutlined
        fontSize="small"
        sx={{
          color: (theme) => theme.palette.accent.main,
        }}
      />
    )}
  </FilterPillContainer>
);
