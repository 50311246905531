import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-multi-carousel/lib/styles.css';
import { pdfjs } from 'react-pdf';
import { Navigate, RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { HomeInsurance } from './pages/home-insurance';
import { BasicInfo } from './pages/home-insurance/basic-info';
import { BuildingType } from './pages/home-insurance/building-type';
import { ConstructionType } from './pages/home-insurance/construction-type';
import { Extras } from './pages/home-insurance/extras';
import { ItemsType } from './pages/home-insurance/items-type';
import { PolicyDetails } from './pages/home-insurance/policy-details';
import { PolicyPayment } from './pages/home-insurance/policy-payment';
import { PreviewPolicy } from './pages/home-insurance/preview-policy';
import { Results } from './pages/home-insurance/results';
import { InsuranceBase } from './pages/insurance-base';
import { createTheme } from './styles/theme';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const theme = createTheme();

const queryClient = new QueryClient();

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
  }
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #F79256 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #F79256;
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  .react-multi-carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/insurance/home" replace />,
  },
  {
    path: '/insurance',
    element: <InsuranceBase />,
    children: [
      {
        path: 'home',
        element: <HomeInsurance />,
        children: [
          {
            element: <BasicInfo />,
            index: true,
          },
          {
            path: 'building',
            element: <BuildingType />,
          },
          {
            path: 'construction',
            element: <ConstructionType />,
          },
          {
            path: 'items',
            element: <ItemsType />,
          },
          {
            path: 'extras',
            element: <Extras />,
          },
          {
            path: 'results',
            element: <Results />,
          },
          {
            path: 'policy/:policyId',
            element: <PreviewPolicy />,
          },
          {
            path: 'policy/:policyId/details',
            element: <PolicyDetails />,
          },
          {
            path: 'policy/payment',
            element: <PolicyPayment variant="payment" />,
          },
          {
            path: 'policy/thank-you',
            element: <PolicyPayment variant="thank-you" />,
          },
        ],
      },
      {
        path: 'checkout',
        children: [
          {
            path: 'success',
            element: <PolicyPayment variant="payment-success" />,
          },
          {
            path: 'failed-payment',
            element: <PolicyPayment variant="payment-error" />,
          },
        ],
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={theme}>
              <GlobalStyles />
              <RouterProvider router={router} />
            </MuiThemeProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </RecoilRoot>
  </React.StrictMode>,
);
