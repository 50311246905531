import {
  ApiCompanyKey,
  ApiPolicy,
  ApiReview,
  ApiRisk,
  ApiSubject,
} from '../../../../api/policies/types';
import { sortBy } from '../../../../util/array';

export type Company = ApiCompanyKey;

export type Review = ApiReview;

export interface PolicySuggestion {
  id: number;
  name: string;
  company: Company;
  kompaRating: number;
  totalPremium: number;
  oldPrice?: number;
  couponValue?: number;
  frameText?: string;
  risks: Array<{
    name: string;
    key: string;
    note?: string;
    description: string;
    descriptionTitle: string;
    deductible: string;
    gracePeriod: string;
    exception: string;
    limit: string;
  }>;
  subjects: Array<{
    type: string;
    totalSum: number;
    note?: string;
    deductible: string;
    gracePeriod: string;
    exception: string;
    limit: string;
  }>;
  recommended: boolean;
  pros: Array<string>;
  cons: Array<string>;
  reviews: Array<Review>;
  kompaReview: string;
  url: string;
}

export const fromApiPolicy =
  (recommendedPredicate: (policyId: number) => boolean) =>
  (apiPolicy: ApiPolicy): PolicySuggestion => ({
    id: apiPolicy.id,
    name: apiPolicy.name,
    company: apiPolicy.company.key,
    kompaRating: apiPolicy.kompaRating,
    risks: sortBy<ApiRisk>(
      'id',
      'asc',
    )(apiPolicy.risks).map(
      ({
        name,
        key,
        note,
        gracePeriod,
        exception,
        deductible,
        descriptionText,
        descriptionTitle,
        limit,
      }) => ({
        name,
        key,
        note,
        description: descriptionText,
        descriptionTitle,
        deductible,
        gracePeriod,
        exception,
        limit,
      }),
    ),
    subjects: sortBy<ApiSubject>(
      'id',
      'asc',
    )(apiPolicy.subjects).map(
      ({ type, totalSum, note, exception, gracePeriod, deductible, limit }) => ({
        type,
        totalSum,
        note,
        exception,
        gracePeriod,
        deductible,
        limit,
      }),
    ),
    totalPremium: apiPolicy.totalPremium,
    oldPrice: apiPolicy.oldPrice,
    couponValue: apiPolicy.couponValue,
    recommended: recommendedPredicate(apiPolicy.id),
    pros: apiPolicy.pros,
    cons: apiPolicy.cons,
    reviews: apiPolicy.reviews,
    kompaReview: apiPolicy.kompaReview,
    frameText: apiPolicy.frameText,
    url: apiPolicy.url,
  });
