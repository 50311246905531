import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Logo from './assets/kompa.png';

const LogoImage = styled.img`
  width: auto;
  height: 75px;

  @media (max-width: 768px) {
    height: 50px;
  }
`;

export const LogoColored = () => (
  <Link to={`${process.env.REACT_APP_WORDPRESS_BASE_URL}domakinsko`}>
    <LogoImage src={Logo} alt="Компа" />
  </Link>
);
