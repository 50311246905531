import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TravelInsurance: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 21 19">
    <path d="M18.5 12H2.5V6H5.5V8H7.5V6H13.5V8H15.5V6H18.5M18.5 17H2.5V15H18.5M7.5 2H13.5V4H7.5M18.5 4H15.5V2C15.5 0.89 14.61 0 13.5 0H7.5C6.39 0 5.5 0.89 5.5 2V4H2.5C1.39 4 0.5 4.89 0.5 6V17C0.5 18.11 1.39 19 2.5 19H18.5C19.61 19 20.5 18.11 20.5 17V6C20.5 4.89 19.61 4 18.5 4Z" />
  </SvgIcon>
);

TravelInsurance.muiName = 'TravelInsurance';
