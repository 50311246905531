import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LandslideCro: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 230.8 242.2"
    xmlSpace="preserve"
    {...props}
  >
    <g id="Wf1iUm.tif">
      <g>
        <path
          d="M-997.7,207.6c-32.9,0-65.7,0-98.6,0c-3.5,0-3.3,0.3-3.3-3.2c0-9.1,0-18.3,0-27.4c0-0.6,0-1.2,0-1.7c0-0.8,0.5-1,1.2-0.7
			c0.7,0.3,1.4,0.6,2,1c3.6,1.9,6.4,4.7,8.9,7.8c1.8,2.3,3.5,4.7,5.3,6.9c3.2,4,7.1,7.2,12.2,8.7c0.6,0.2,1.3,0.4,1.9,0.4
			c1,0.1,1.8-0.4,2-1.5c0.2-1,0-1.8-0.9-2.4c-0.4-0.2-0.7-0.4-1.1-0.5c-4.8-1.4-8.3-4.5-11.3-8.3c-1.6-2.1-3.2-4.2-4.8-6.3
			c-3.7-4.7-8.1-8.4-13.7-10.4c-1.3-0.5-1.8-1.2-1.7-2.6c0.1-4.2,0-8.3,0-12.5c0-28.4,0-56.9,0-85.3c0-0.9,0.3-1.9,0.4-2.8
			c0.7,0.5,1.5,1,2.1,1.6c8.6,8.4,16.3,17.6,21.8,28.4c2.8,5.6,5,11.4,6,17.6c0.3,2,0.3,2-1.8,2.2c-3.5,0.3-6.6,1.4-9.6,3.1
			c-0.5,0.3-1,0.6-1.5,1c-1,0.8-1.2,1.8-0.6,2.9c0.4,0.9,1.6,1.2,2.8,0.7c0.7-0.3,1.3-0.8,1.9-1.1c12-6.6,27,0.7,29.1,14.2
			c0.7,4.8,3.1,7.9,7.8,9.2c5.1,1.5,8.9,4.7,11.3,9.5c1.6,3.1,4,5.2,7.5,5.5c1.9,0.1,3.9-0.4,5.8-0.9c6.2-1.7,11.3,0,15.5,4.7
			c0.9,1,1.6,2.3,2.2,3.6c2,4.3,5.4,6.4,10.2,6c0.7-0.1,1.5-0.1,2.2-0.1c3.3,0,5.9,1.4,7.9,4c0.4,0.5,0.7,1.1,1.1,1.7
			c2.6,3.8,6.2,5.2,10.6,4c4.6-1.2,9.1-1.6,13.8-0.1c3.6,1.1,6.9,3,9.6,5.6c1.7,1.7,1.7,1.8-0.7,2.6c-4.3,1.4-8.3,3.4-12.1,5.9
			c-0.6,0.4-1.3,0.8-1.8,1.4c-0.7,0.7-0.8,1.6-0.2,2.5c0.6,1,1.5,1.3,2.6,0.8c0.7-0.3,1.3-0.8,1.9-1.2c9-5.9,19-8.4,29.6-7.5
			c10,0.9,19,4.7,26.6,11.3c0.3,0.3,0.7,0.5,0.9,0.9c0.1,0.2-0.2,0.8-0.3,0.8c-0.7,0.1-1.5,0.1-2.2,0.1
			C-931.9,207.6-964.8,207.6-997.7,207.6z"
        />
        <path
          d="M-954.4,96c0.6-0.2,1.1,0.2,1.5,1.2c2,5.4,4.1,10.9,6.2,16.3c0.4,1.1-0.1,1.5-1.1,1.7c-2.6,0.6-5.2,1.1-7.8,1.7
			c-2.9,0.6-3.4,1.5-2.4,4.4c0.4,1.2,0.9,2.3,1.3,3.5c0.5,1.1,0.1,1.7-1,2.1c-1.7,0.6-3.4,1.3-5.1,1.9c-0.4,0.1-0.8,0.3-1.2,0.4
			c-1.5,0.7-2,1.8-1.5,3c0.4,1,1.5,1.5,2.9,1c1.6-0.5,3.1-1.1,4.7-1.7c1.6-0.6,3.3-1.2,4.9-1.9c1.6-0.7,2-1.5,1.5-3.2
			c-0.4-1.4-1-2.8-1.5-4.2c-0.4-0.9-0.2-1.4,0.8-1.6c1.9-0.4,3.8-0.7,5.6-1.3c1.5-0.4,2.1,0.1,2.6,1.4c1.7,4.8,3.6,9.5,5.4,14.2
			c1.3,3.6,2.7,7.1,4,10.7c0.2,0.6,0.5,1.2,0.6,1.9c0.3,2.2-1.6,3.7-3.6,2.7c-1.6-0.7-3-1.7-4.6-2.6c-2.6-1.5-3.3-1.3-4.8,1.3
			c-5.6,9.5-11.2,19.1-16.7,28.6c-1.2,2.1-4.1,3.5-6.5,3c-1.5-0.3-2.7-1.2-3.5-2.5c-0.9-1.3-1.8-2.6-2.9-3.7c-1.1-1-1-1.8-0.3-3
			c4-6.7,7.8-13.5,11.7-20.3c2.3-4.1,2.3-4.3-1.8-6.7c-6.9-4-13.8-8-20.7-12c-0.8-0.5-1.6-0.9-2.4-1.4c-2.3-1.3-3-1.1-4.4,1.3
			c-1.3,2.2-2.6,4.5-3.9,6.7c-3,5.3-6.1,10.5-9.1,15.8c-0.5,0.9-1,1.5-2.1,1.2c-3.1-0.7-6.1,0-9.1,1c-1.4,0.4-2.7,0.7-4.1,0.2
			c-2-0.8-2.3-1.5-1.2-3.3c4.8-8.3,9.6-16.5,14.3-24.8c2.4-4.2,4.8-8.3,7.2-12.5c1.3-2.2,1.1-2.9-1.1-4.2c-1.3-0.8-2.7-1.6-4.1-2.4
			c-1.6-1-2.2-1.9-1.9-3.1c0.4-1.4,1.4-1.9,2.8-2.1c10.3-1.7,20.6-3.4,31-5.1c1.9-0.3,2-0.2,1.4,1.7c-0.3,0.9-0.7,1.9-1,2.8
			c-0.5,1.7-0.2,2.6,1.4,3.2c3.2,1.3,6.5,2.5,9.8,3.7c1.3,0.5,2.4-0.1,2.9-1.2c0.4-1.1,0-2.2-1.4-2.8c-2.1-0.9-4.3-1.7-6.5-2.5
			c-1.1-0.4-1.3-0.9-0.9-2c1.3-4.1,1.3-4.1,5.5-4.8C-961.5,97.1-958.2,96.6-954.4,96z"
        />
        <path
          d="M-1051.3,47.7c4,0.1,7,1.5,8.8,4.6c1.5,2.6,2.9,5.3,4.1,8c1.7,3.8,1,7.3-1.7,10.4c-1.8,2-3.8,4-5.8,5.8
			c-3.2,2.9-7,3.4-10.9,1.6c-2.3-1.1-4.6-2.2-6.9-3.5c-4.1-2.3-5.8-6.1-5-10.7c0.4-2.2,0.7-4.4,1.1-6.6c0.8-4.5,3.5-7.3,7.9-8.2
			C-1056.8,48.4-1053.8,48.1-1051.3,47.7z"
        />
        <path
          d="M-1055.7,106.3c0.3-1.8,0.7-4.9,1.5-8c0.9-3.8,3.5-6.2,7.3-7c2.8-0.6,5.7-1,8.6-1.3c4-0.4,7.1,1.3,9.2,4.8
			c1.4,2.4,2.7,5,3.8,7.5c1.7,3.8,1.1,7.4-1.7,10.4c-1.9,2.1-3.9,4.1-6,6c-3.1,2.7-6.6,3.4-10.4,1.6c-2.6-1.2-5.2-2.5-7.7-4
			C-1054.4,114.5-1055.9,111.4-1055.7,106.3z"
        />
        <path
          d="M-983,170.7c-2.1,0-4.1-0.1-6.2,0c-2.6,0.2-4.4-0.9-5.4-3.2c-1.8-4-4.5-7.2-8.3-9.3c-1.1-0.6-1-1.2-0.5-2.2
			c3.7-6.4,7.4-12.8,11.1-19.2c0.6-1.1,1.2-1.3,2.3-0.6c6.5,3.8,13.1,7.6,19.6,11.3c1.1,0.6,1.3,1.2,0.7,2.4
			c-3.9,6.6-7.6,13.2-11.5,19.8c-0.3,0.6-1.1,1-1.6,1.4C-982.8,171.1-982.9,170.9-983,170.7z"
        />
        <path
          d="M-1021.2,87.5c-1.2-0.3-2.1-0.4-2.8-0.7c-1.8-0.8-3.6-1.7-5.3-2.7c-2.3-1.4-3.6-3.5-3.3-6.2c0.2-2.1,0.5-4.3,1-6.4
			c0.7-2.6,2.6-4.2,5.2-4.8c1.8-0.4,3.6-0.7,5.4-0.9c2.7-0.2,4.9,0.8,6.4,3.1c1.2,1.8,2.2,3.7,2.9,5.7c0.9,2.3,0.6,4.7-1,6.6
			c-1.4,1.7-3,3.3-4.8,4.7C-1018.6,86.7-1020.1,87.1-1021.2,87.5z"
        />
        <path
          d="M-1018.3,93.6c1.4,0,2.6,1.1,2.6,2.4c0,1.3-1.3,2.6-2.6,2.6c-1.2,0-2.4-1.1-2.5-2.4C-1020.8,94.9-1019.7,93.7-1018.3,93.6
			z"
        />
        <path
          d="M-1027.7,132.7c-0.1,1.3-1.4,2.5-2.7,2.3c-1.3-0.1-2.3-1.4-2.2-2.8c0.1-1.3,1.2-2.2,2.6-2.2
			C-1028.7,130.1-1027.6,131.3-1027.7,132.7z"
        />
        <path
          d="M-1059.2,89.8c0,1.4-1,2.5-2.5,2.5c-1.4,0-2.4-0.9-2.5-2.2c-0.1-1.4,0.9-2.6,2.3-2.7
			C-1060.5,87.3-1059.3,88.4-1059.2,89.8z"
        />
      </g>
    </g>
    <g id="B2XI9u.tif">
      <g>
        <path
          d="M-1308.6,208.3c-31.9,0-63.7,0-95.6,0c-4.1,0-3.6,0-3.6-3.7c0-52.1,0-104.1,0-156.2c0-0.1,0-0.2,0-0.2c0.2-1,0.3-2,0.5-3
			c1,0.4,2,0.7,2.9,1.3c4.2,2.6,7.9,5.8,10.8,9.8c6.2,8.7,11.7,17.8,16.8,27.1c9.8,17.7,19.3,35.5,29.1,53.2c8.3,15,16.6,30,26.5,44
			c2.5,3.5,5.4,6.7,8.2,10c2,2.3,4.5,3.3,7.7,3.2c7.4-0.2,14.8-0.1,22.2-0.1c2.6,0,2.7,0,2.7-2.6c0-7.5,0.1-15,0-22.5
			c-0.1-4.2,1.2-7.4,4.3-10.4c8.4-8.1,16.6-16.5,24.9-24.8c0.8-0.8,1.5-1.5,2.4-2.2c2.7-1.9,5.9-1.9,8.6,0.1c1.1,0.8,2,1.7,3,2.7
			c8.2,8.2,16.4,16.5,24.7,24.7c2.5,2.5,3.7,5.2,3.7,8.8c-0.2,12.5-0.1,25-0.1,37.4c0,0.7,0,1.3,0,2c0,0.9-0.5,1.4-1.4,1.4
			c-0.8,0-1.7,0-2.5,0C-1244.7,208.3-1276.6,208.3-1308.6,208.3z M-1254.8,182.1c0,3.2,0,6.5,0,9.7c0,1.2,0.3,1.8,1.7,1.8
			c5.8-0.1,11.6,0,17.5,0c1.1,0,1.6-0.4,1.6-1.6c0-6.6,0.1-13.1-0.1-19.7c-0.1-3.8-3.1-6.6-6.8-6.6c-2.3,0.1-4.7,0-7,0
			c-4.4,0.1-6.9,2.7-6.9,7.1C-1254.8,176-1254.8,179-1254.8,182.1z"
        />
        <path
          d="M-1343.7,80.6c-11.8,0-21.7-9.8-21.5-21.6c0.1-11.2,8.7-21.4,21.5-21.4c12.9,0,21.6,10.2,21.5,21.6
			C-1322.4,71.8-1332.5,80.5-1343.7,80.6z"
        />
        <path
          d="M-1308.3,144.4c-11.7,0-21.5-9.7-21.6-21.4c-0.1-11.8,9.7-21.7,21.5-21.7c11.8,0,21.6,9.8,21.6,21.6
			C-1286.8,134.6-1296.6,144.4-1308.3,144.4z"
        />
        <path
          d="M-1301.1,165.5c0,4-3.3,7.4-7.3,7.3c-3.9,0-7.2-3.4-7.2-7.4c0-4.1,3.1-7.2,7.3-7.2
			C-1304.1,158.2-1301.1,161.3-1301.1,165.5z"
        />
        <path
          d="M-1336.6,101.5c0,4-3.2,7.4-7.1,7.4c-3.9,0.1-7.3-3.3-7.4-7.3c-0.1-4,3.2-7.3,7.2-7.4
			C-1339.8,94.3-1336.6,97.4-1336.6,101.5z"
        />
      </g>
    </g>
    <g id="cyfOqX.tif">
      <g>
        <path
          d="M-706.2,120.8c0-3.1,0-6.1,0-9.2c0-1,0.3-1.5,1.3-1.6c1-0.1,2.1-0.2,3.1-0.4c3.6-0.6,6.6-2.6,9.7-4.4
			c4.4-2.6,9.1-4.6,14.2-5.2c3.7-0.5,7.5-0.8,11.2-0.2c0.8,0.1,1.1,0,0.8-0.9c-1.2-4.4-2.3-8.8-3.5-13.1c-1.7-6.5-3.5-13-5.3-19.5
			c-0.1-0.5-0.3-0.9-0.5-1.3c-0.4,0.3-0.8,0.6-1.1,0.9c-3.5,4.4-6.9,8.8-10.3,13.2c-0.7,0.9-1.4,1.8-2,2.6c-0.9,1.1-1.1,1.1-2.2,0.2
			c-3.1-2.4-6.1-4.8-9.1-7.2c-1.7-1.4-3.5-2.7-5.2-4.1c-1.2-1-1.3-1.1-0.2-2.3c4.9-6.1,9.9-12.2,14.8-18.4
			c6.1-7.5,12.2-15.1,18.3-22.6c10.9-13.5,21.8-26.9,32.7-40.4c4.4-5.5,8.9-11,13.3-16.5c0.3-0.3,0.6-0.6,0.8-1
			c0.5-0.8,1.1-0.9,2-0.5c4.7,1.8,9.4,3.6,14.1,5.4c13.1,5,26.3,10,39.4,15.1c10.7,4.1,21.3,8.2,32,12.3
			c10.9,4.2,21.9,8.4,32.9,12.6c1.8,0.7,1.8,0.7,1.1,2.5c-2.1,5.3-4.2,10.6-6.3,15.9c-0.1,0.3-0.2,0.6-0.4,0.8
			c-0.6,1.4-0.6,1.4-2,0.9c-2.7-1.1-5.3-2.2-8-3.3c-3.6-1.5-7.2-2.9-10.7-4.4c-0.3-0.1-0.6-0.1-0.9-0.2c0,0.3-0.1,0.7,0,1
			c1.8,6.9,3.6,13.7,5.4,20.6c4.3,16.2,8.6,32.4,12.9,48.6c0.6,2.1,1.2,4.2,1.7,6.4c0.2,0.9,0.7,1.5,1.5,1.9
			c2.4,1.2,4.7,2.6,7.2,3.7c1.5,0.7,3.3,0.8,5,1.2c0.3,0.1,0.6,0,0.9,0.1c1.5,0.3,1.6,0.3,1.6,1.9c0,6,0,11.9,0,17.9
			c0,1.7-0.1,1.8-1.8,1.8c-6.4,0-12.4-1.5-18-4.6c-2.6-1.4-5.1-3-7.8-4.1c-5.7-2.5-11.4-2.3-16.9,0.6c-2.5,1.3-4.9,2.7-7.4,4.1
			c-5.1,2.8-10.7,4-16.5,4.1c-5.1,0.1-10.1-0.2-14.9-1.8c-3.3-1.1-6.4-2.8-9.4-4.5c-2.7-1.5-5.4-3-8.4-3.6c-5.2-1-10-0.2-14.6,2.4
			c-3.9,2.2-7.6,4.8-12,5.9c-2.8,0.7-5.8,1.3-8.7,1.5c-4.5,0.3-9.1,0.2-13.6-0.8c-4.8-1.1-9.1-3.3-13.3-5.8c-2.4-1.4-5-2.7-7.8-3.3
			c-3.8-0.7-7.6-0.6-11.2,1c-2.2,1-4.4,2.1-6.6,3.3c-6.1,3.5-12.5,5.6-19.6,5.7c-0.2,0-0.5,0-0.7,0c-0.9,0.1-1.3-0.4-1.3-1.3
			c0-2.1,0-4.2,0-6.3C-706.2,122.9-706.2,121.9-706.2,120.8z M-566.5,110.2c1.4,0,1.4,0,1-1.5c-3.5-12.9-6.9-25.9-10.4-38.9
			c-1.2-4.5-2.4-9-3.6-13.5c-0.4-1.3-0.5-1.4-1.8-1.1c-0.4,0.1-0.8,0.2-1.2,0.3c-11.9,3.2-23.8,6.3-35.7,9.5
			c-1.7,0.4-1.6,0.4-1.2,2.1c2,7.6,4,15.1,6.1,22.7c0.8,3.1,1.7,6.3,2.5,9.4c0.2,0.7,0.4,1.2,1.5,1c4.3-0.8,8.6-1,13-0.5
			c4.4,0.4,8.6,1.4,12.5,3.4c2.7,1.4,5.3,2.9,7.9,4.3C-573.1,109.2-570,110-566.5,110.2z"
        />
        <path
          d="M-706.2,167.9c0-3.1,0-6.1,0-9.2c0-1,0.2-1.5,1.3-1.5c4.5,0,8.3-2,12-4.2c4.4-2.7,9.1-4.8,14.3-5.6
			c4.1-0.6,8.3-0.7,12.5-0.3c6.6,0.5,12.3,3,17.8,6.4c2.5,1.5,5,2.9,7.9,3.4c4.4,0.8,8.7,0.6,12.8-1.4c2.7-1.4,5.3-2.9,7.9-4.3
			c4.2-2.2,8.6-3.5,13.3-4c5.8-0.6,11.4-0.3,17,1.2c3.8,1,7.2,2.8,10.5,4.8c2.6,1.5,5.1,3,8,3.6c5.5,1.2,10.7,0.4,15.6-2.5
			c3.3-2,6.5-4,10.1-5.2c6.1-2.1,12.4-2.5,18.9-1.9c6.3,0.6,11.9,2.8,17.2,6.1c2.7,1.7,5.5,3.2,8.7,3.8c0.9,0.2,1.8,0.2,2.7,0.3
			c1.5,0.1,1.7,0.2,1.7,1.7c0,4.8,0,9.6,0,14.4c0,1.3,0,2.6,0,3.9c0,1.6-0.1,1.7-1.7,1.7c-6.8,0-13.1-1.7-18.9-5.2
			c-1.8-1.1-3.7-2.2-5.6-3.1c-6.6-3.2-13.2-2.8-19.6,0.8c-2.7,1.5-5.5,3-8.3,4.4c-3.8,1.9-7.9,2.7-12.1,3
			c-5.3,0.4-10.5,0.1-15.7-1.4c-3.3-1-6.4-2.5-9.4-4.2c-3.1-1.8-6.1-3.7-9.7-4.3c-4.4-0.8-8.7-0.6-12.7,1.5
			c-2.6,1.3-5.2,2.8-7.8,4.2c-4.1,2.2-8.4,3.5-13.1,4c-5.4,0.6-10.7,0.4-15.9-0.8c-4.3-1-8.2-3-11.9-5.2c-2.8-1.7-5.6-3.2-8.9-3.8
			c-4.1-0.6-8.1-0.4-11.9,1.4c-2.3,1.1-4.6,2.4-6.8,3.7c-4.4,2.5-9.1,4.3-14.2,4.7c-1.5,0.1-3.1,0.3-4.6,0.3c-1.4,0-1.4-0.1-1.4-1.5
			C-706.2,174.1-706.2,171-706.2,167.9z"
        />
      </g>
    </g>
    <g>
      <path
        d="M36.6,44.8c0.1,0.8,0.4,1.2,1.1,1.5c4.5,2.1,8.9,4.3,13.4,6.4c2.8,1.4,5.6,2.7,8.5,4.1c0.5,0.2,0.8,0.3,1.3,0
		c1.9-1.1,3.7-2.2,5.6-3.2c8.9-5.1,17.8-10.2,26.7-15.2c0.8-0.5,0.8-0.5,0.7-1.5c-0.8-5.6-1.6-11.3-2.4-16.9
		C90.8,14.4,90,8.8,89.3,3.3c0-0.2-0.1-0.4-0.1-0.7c0-0.3-0.2-0.5-0.5-0.5c-1.7-0.1-3.4-0.2-5.1-0.3c-2-0.1-3.9-0.2-5.9-0.3
		c-2.1-0.1-4.2-0.3-6.3-0.4c-1.7-0.1-3.3-0.1-5-0.2c-1.5-0.1-3-0.2-4.4-0.3c-1.6-0.1-3.2-0.2-4.8-0.3c-1.4-0.1-2.8-0.1-4.2-0.2
		c-0.4,0-0.8,0.1-1.1,0.4c-0.5,0.5-1,0.9-1.5,1.4c-5.6,5-11.2,9.9-16.8,14.9c-0.6,0.5-0.7,1-0.6,1.7C34.2,27.2,35.4,36,36.6,44.8z"
      />
      <path
        d="M88.9,67c0.7,5.2,1.5,10.3,2.2,15.5c0.1,0.5,0.3,0.9,0.8,1.1c1.6,0.9,3.3,1.9,4.9,2.9c3.2,1.9,6.3,3.8,9.5,5.7
		c0.3,0.2,0.6,0.2,0.9,0c2.3-1.5,4.6-2.9,7-4.3c2.8-1.8,5.7-3.5,8.5-5.3c2.3-1.5,4.7-2.9,7-4.4c0.5-0.3,0.7-0.7,0.6-1.3
		c-0.5-3.5-1-6.9-1.5-10.4c-0.5-3.5-1-7.1-1.5-10.6c-0.1-0.7-0.1-0.7-0.8-0.8c-2-0.2-4.1-0.3-6.1-0.5c-0.9-0.1-1.9-0.2-2.8-0.2
		c-1.4-0.1-2.8-0.2-4.2-0.3c-1.4-0.1-2.9-0.2-4.3-0.4c-1.4-0.1-2.8-0.2-4.2-0.3c-1-0.1-2.1-0.2-3.1-0.3c-0.3,0-0.6,0-0.9,0.3
		c-0.3,0.3-0.6,0.6-0.9,0.9c-3.6,3.8-7.1,7.5-10.7,11.3C89,66.2,88.8,66.5,88.9,67z"
      />
      <path
        d="M116.9,29.2c1.3,0.6,2.5,1.2,3.8,1.8c0.8,0.4,1.6,0.8,2.4,1.1c0.1,0.1,0.2,0.1,0.4,0c0.5-0.3,1.1-0.6,1.6-0.9
		c2.5-1.4,5-2.9,7.5-4.3c0.2-0.1,0.2-0.1,0.2-0.4c-0.2-1.6-0.4-3.2-0.7-4.8c-0.2-1.6-0.4-3.1-0.7-4.7c0-0.1,0-0.1,0-0.2
		c0-0.1-0.1-0.1-0.2-0.1c-0.5,0-1-0.1-1.4-0.1c-0.6,0-1.1-0.1-1.7-0.1c-0.6,0-1.2-0.1-1.8-0.1c-0.5,0-0.9,0-1.4-0.1
		c-0.4,0-0.8-0.1-1.2-0.1c-0.4,0-0.9,0-1.3-0.1c-0.4,0-0.8,0-1.2-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.3,0.3-0.4,0.4
		c-1.6,1.4-3.1,2.8-4.7,4.2c-0.2,0.1-0.2,0.3-0.2,0.5c0.4,2.5,0.7,5,1,7.5C116.6,29,116.7,29.1,116.9,29.2z"
      />
      <path
        d="M139.4,91.8c0.6,0.3,1.2,0.7,1.8,1c1.1,0.7,2.3,1.4,3.4,2c0.1,0.1,0.2,0.1,0.3,0c0.8-0.5,1.7-1,2.5-1.6c1-0.6,2-1.3,3-1.9
		c0.8-0.5,1.7-1.1,2.5-1.6c0.2-0.1,0.2-0.2,0.2-0.5c-0.2-1.2-0.4-2.5-0.5-3.7c-0.2-1.3-0.4-2.5-0.5-3.8c0-0.3,0-0.3-0.3-0.3
		c-0.7-0.1-1.5-0.1-2.2-0.2c-0.3,0-0.7-0.1-1-0.1c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1-0.1-1.5-0.1
		c-0.4,0-0.7-0.1-1.1-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-1.3,1.4-2.6,2.7-3.8,4.1c-0.1,0.1-0.2,0.3-0.1,0.4
		c0.3,1.9,0.5,3.7,0.8,5.6C139.2,91.6,139.3,91.7,139.4,91.8z"
      />
      <path
        d="M212.8,151.2c-1.2-3.1-2.3-6.2-3.5-9.4c-1.4-3.9-2.9-7.7-4.3-11.6c-2.6-6.9-5.1-13.8-7.7-20.6c-1-2.8-2.1-5.6-3.1-8.4
		c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.4-0.3-0.5-0.7-0.5c-2.1,0.1-4.3,0.2-6.4,0.3c-6,0.2-11.9,0.5-17.9,0.7c-4.8,0.2-9.7,0.4-14.5,0.6
		c-5,0.2-9.9,0.4-14.9,0.6c-0.8,0-0.8,0-0.8,0.8c0.1,2.4,0.3,4.8,0.4,7.2c0,0.1,0,0.3,0,0.4c0.1,0.7,0.1,0.7,0.7,0.6
		c1.2-0.1,2.4-0.1,3.7-0.2c1.6-0.1,3.3-0.2,4.9-0.3c0.1,0,0.2,0,0.4,0c0,0.1,0,0.3-0.1,0.4c-1.6,2.5-3.3,5-4.9,7.5
		c-1.3,1.9-3.7,5.5-6.4,9.6l-24.1-11.2L89,103.8c-2-2.6-3.9-5.2-5.9-7.9c-6.4-8.5-12.7-16.9-19-25.4c-0.6-0.8-1.2-1.1-2.2-1.1
		c-10,0-20,0-30.1,0h-30c-1.6,0-1.6,0-1.6,1.6c0,11.4,0,22.7,0,34.1c0,1.1-0.1,1.6,0,2l0,20.4c0,0.2,0,0.4,0,0.8c0,0.2,0,0.5,0,0.7
		v9.1c0,3.2,0,6.5,0,9.7c0,0.5,0,0.9,0.1,1.2l0,14.2h108.6l-8.9,14.2H0.1c0,12.3,0,24.6,0,36.8c0,2.2-0.2,2,2,2h206
		c0.4,0,0.9-0.1,1.3-0.2c-0.2-0.4-0.3-0.8-0.6-1.2c-3.7-5-7.4-9.9-11.2-14.9c-4.2-5.6-8.4-11.3-12.7-17c-1.5-2-3.1-4.1-4.6-6.1
		c3.3-5.2,6.9-10.8,7.4-11.5c1.6-2.4,11.3-16.9,12.9-19.3c0.1-0.2,0.3-0.3,0.4-0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.8,2.2,1.6,4.5,2.4,6.7
		c0.2,0.4,0.3,0.9,0.5,1.3c0.2,0.6,0.3,0.6,0.9,0.4c1.5-0.5,3.1-1.1,4.6-1.6c0.9-0.3,1.8-0.6,2.7-1
		C213,151.9,213.1,151.8,212.8,151.2z M178.5,139.2c-1.6,2.4-9.2,13.8-12.4,18.7c-2.5-3.4-5.1-6.8-7.6-10.1
		c-0.4-0.5-0.6-0.8-0.9-0.9l-3.2-3.2c3.1-4.6,8.7-13,9.5-14.2c0.3-0.5,0.4-0.5,0.9-0.2c0.2,0.1,0.3,0.2,0.5,0.3
		c4.4,2.9,8.7,5.8,13.1,8.7C178.9,138.6,178.9,138.6,178.5,139.2z"
      />
      <polygon points="218.3,225.7 0,225.7 0,242.2 230.8,242.2 	" />
    </g>
  </SvgIcon>
);

LandslideCro.muiName = 'LandslideCro';
