import { add } from 'date-fns';
import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { Link, createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import {
  AccessTime,
  ArrowForward,
  CreditCard,
  InsertDriveFileOutlined,
  Mail,
  Visibility,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import {
  NextStepsArrow,
  NextStepsIcon,
  NextStepsIconContainer,
  NextStepsStepNumber,
} from './styled';
import { PurchaseFormValues } from './types';
import { PolicySuggestion } from '../../../results/types';
import { useBuildingData } from '../../hooks/use-building-data';
import { useCreateOrder } from '../../hooks/use-create-order';

interface Props {
  policy: PolicySuggestion;
}

export const PurchaseForm = ({ policy }: Props) => {
  const buildingData = useBuildingData();

  const [searchParams] = useSearchParams();

  const [isNotARobot, setIsNotARobot] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm<PurchaseFormValues>({
    defaultValues: {
      ownerFirstName: '',
      ownerLastName: '',
      address: '',
      objectSize: '',
      ownerIdNumber: '',
      email: '',
      phoneNumber: '',
      ownerAddress: '',
      recaptcha: '',
      insuranceStartDate: null,
      policyTermsAndConditions: false,
      dataProcessingAgreement: false,
      contactAgreement: false,
      permanentlyInhabited: false,
      isAddressSameAsOwner: true,
    },
    mode: 'onChange',
  });

  const watchIsAddressSame = watch('isAddressSameAsOwner');
  const watchObjectSize = watch('objectSize');

  useEffect(() => {
    if (buildingData?.area && !watchObjectSize) {
      setValue('objectSize', buildingData.area.toString());
    }
  }, [buildingData, watchObjectSize, setValue]);

  const { createOrder, isSuccess, isError, isLoading, reset, data } = useCreateOrder(policy);

  if (isSuccess) {
    navigate({
      pathname: '/insurance/home/policy/thank-you',
      search: `?${createSearchParams({
        insurancePackageName: policy.name,
        insuranceCompanyKey: policy.company,
        objectSize: watchObjectSize,
        address: watch('address'),
        ownerAddress: watch('ownerAddress'),
        ownerFirstName: watch('ownerFirstName'),
        ownerLastName: watch('ownerLastName'),
        totalPrice: policy.totalPremium.toString(),
        orderNumber: data?.orderNumber ?? '',
      })}`,
    });
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      component="form"
      onSubmit={handleSubmit(createOrder)}
      marginTop={4}
      marginLeft="auto"
      marginRight="auto"
      width="100%"
      boxSizing="border-box"
    >
      <Box
        display="flex"
        flexDirection="column"
        borderRadius={4}
        padding={3}
        sx={{
          backgroundColor: 'accent.light',
          boxShadow: 1,
        }}
      >
        <Typography variant="h6" color="primary.dark">
          Ваши лични податоци
        </Typography>
        <Box
          display="flex"
          marginTop={2}
          flexDirection={{ xs: 'column', md: 'row' }}
          gap={{
            xs: 0.5,
            md: 2,
          }}
          width="100%"
        >
          <Controller
            control={control}
            name="ownerFirstName"
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                placeholder="Име"
                fullWidth
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name="ownerLastName"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                placeholder="Презиме"
                fullWidth
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <Box>
          <Typography variant="body1" color="primary.dark" fontWeight={300}>
            (на сопственикот на објектот)
          </Typography>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: '1fr',
            sm: '8fr 2fr',
          }}
          gap={1}
          marginTop={2}
          alignItems="center"
        >
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name="address"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                placeholder="Целосна адреса и број на објектот што се осигурува"
                fullWidth
                helperText={fieldState.error?.message}
                sx={{
                  input: {
                    '&::placeholder': {
                      fontSize: {
                        xs: '0.7rem',
                        sm: '1rem',
                      },
                    },
                  },
                }}
              />
            )}
          />
          <Box
            display={{
              xs: 'none',
              sm: 'flex',
            }}
            alignItems="center"
          >
            <Controller
              control={control}
              name="objectSize"
              disabled
              rules={{
                required: true,
                validate: (value) => !Number.isNaN(Number(value)) || 'Внесете валидна квадратура',
              }}
              render={({ field, fieldState }) => (
                <TextField
                  disabled
                  {...field}
                  error={!!fieldState.error}
                  placeholder="Квадратура"
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Typography variant="body1" color="grey.400" marginLeft={1}>
              м2
            </Typography>
          </Box>
        </Box>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          alignItems={{
            xs: 'flex-start',
            md: 'center',
          }}
          gap={{
            xs: 0,
            md: 2,
          }}
        >
          <Typography variant="body1" color="primary.dark" fontWeight={300}>
            (како што е во Имотен лист)
          </Typography>
          <Controller
            control={control}
            name="isAddressSameAsOwner"
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox />}
                {...field}
                checked={field.value}
                onChange={(_, checked) => field.onChange(checked)}
                label={
                  <Typography variant="body1" fontSize={14} color="grey.400">
                    Адресата на објектот е иста со адресата од лична карта на сопственикот
                  </Typography>
                }
              />
            )}
          />
        </Stack>
        {!watchIsAddressSame ? (
          <Box display="grid" gridTemplateColumns="1fr" marginTop={2}>
            <Controller
              control={control}
              name="ownerAddress"
              rules={{
                validate: (value, { isAddressSameAsOwner }) =>
                  isAddressSameAsOwner
                    ? true
                    : (value && value !== '') ||
                      'Потребно е да ја внесете адресата на сопственикот',
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={!!fieldState.error}
                  placeholder="Целосна адреса и број на сопственикот"
                  fullWidth
                  helperText={fieldState.error?.message}
                  sx={{
                    input: {
                      '&::placeholder': {
                        fontSize: {
                          xs: '0.7rem',
                          sm: '1rem',
                        },
                      },
                    },
                  }}
                />
              )}
            />
          </Box>
        ) : null}
        <Box display="grid" gridTemplateColumns="1fr" marginTop={2} alignItems="center">
          <Controller
            control={control}
            rules={{
              required: true,
              validate: (value) =>
                (!Number.isNaN(Number(value)) && value.length === 13) || 'Внесете валиден ЕМБГ',
            }}
            name="ownerIdNumber"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                placeholder="ЕМБГ"
                fullWidth
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <Box>
          <Typography variant="body1" color="primary.dark" fontWeight={300}>
            (потребно за ваша идентификација како овластено лице во случај на штета)
          </Typography>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }}
          gridTemplateRows="auto auto"
          marginTop={2}
          gap={1}
          alignItems="start"
        >
          <Box display="flex" flexDirection="column">
            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Внесете валидна мејл адреса',
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={!!fieldState.error}
                  placeholder="Ваша мејл адреса"
                  fullWidth
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Box>
              <Typography variant="body1" color="primary.dark" fontWeight={300}>
                (за достава на полисата)
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                required: true,
                validate: (v) => {
                  const value = v
                    .split('')
                    .filter((c) => c !== ' ')
                    .join('');

                  return (
                    (value.match(/^07[0-9]{7}$/) && value.length === 9) ||
                    (value.match(/^\+*3890*7[0-9]{7}$/) && value.length <= 13) ||
                    'Внесете валиден број'
                  );
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  placeholder="Ваш мобилен број"
                  fullWidth
                />
              )}
            />
            <Box>
              <Typography variant="body1" color="primary.dark" fontWeight={300}>
                (за побрза комуникација во случај на потреба)
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: '1fr',
            sm: '3fr 1fr 1fr',
          }}
          marginTop={2}
          gap={{
            xs: 1,
            sm: 0,
          }}
          alignItems="center"
        >
          <Controller
            control={control}
            name="insuranceStartDate"
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <DatePicker
                format="dd/MM/yyyy"
                disablePast
                minDate={add(new Date(), { days: 1 })}
                {...field}
                label="Датум на почеток на осигурувањето"
                sx={{
                  '.MuiInputAdornment-root': {
                    position: 'absolute',
                    right: '10px',
                  },
                }}
                slotProps={{
                  textField: {
                    error: !!fieldState.error,
                    helperText: fieldState.error?.message,
                  },
                }}
              />
            )}
          />
          <Button
            variant="contained"
            color="accent"
            size="large"
            sx={{
              width: 'max-content',
              marginLeft: {
                xs: 0,
                sm: 2,
              },
            }}
            onClick={() =>
              setValue('insuranceStartDate', add(new Date(), { days: 1 }), { shouldValidate: true })
            }
          >
            Првиот можен
          </Button>
        </Box>
        <Box>
          <Typography variant="body1" color="primary.dark" fontWeight={300}>
            (во формат ДД/ММ/ГГГГ)
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" marginTop={4} gap={1}>
        <Controller
          control={control}
          name="policyTermsAndConditions"
          rules={{
            validate: (value) => value || 'Потребно е да ги прифатите условите на осигурување',
          }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox />}
              {...field}
              onChange={(_, checked) => field.onChange(checked)}
              label={
                <Typography variant="body1" fontSize={14} color="grey.400">
                  Ги прочитав и запознаен/а сум со{' '}
                  <Link
                    to={{
                      pathname: './details',
                      search: `${
                        searchParams.toString().includes('tab')
                          ? searchParams.toString().replace(/tab=[^&?]*(?=&|$|\?)/, 'tab=risks')
                          : `${searchParams.toString()}&tab=risks`
                      }`,
                    }}
                  >
                    деталите за полисата
                  </Link>
                  , како и со{' '}
                  <Link
                    to={{
                      pathname: './details',
                      search: `${
                        searchParams.toString().includes('tab')
                          ? searchParams.toString().replace(/tab=[^&?]*(?=&|$|\?)/, 'tab=terms')
                          : `${searchParams.toString()}&tab=terms`
                      }`,
                    }}
                  >
                    Условите на осигурување (.pdf)
                  </Link>{' '}
                  врз основа на кои се склучува ова осигурување.{' '}
                  <Typography component="span" color="accent.main">
                    *
                  </Typography>
                </Typography>
              }
            />
          )}
        />
        <Controller
          control={control}
          name="dataProcessingAgreement"
          rules={{
            validate: (value) => value || 'Потребно е да се согласите за процесирање на податоци',
          }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox />}
              {...field}
              onChange={(_, checked) => field.onChange(checked)}
              label={
                <Typography variant="body1" fontSize={14} color="grey.400">
                  Изјавувам дека сум согласен мојот единствен матичен број како личен податок, моето
                  име и презиме, живеалиште, е-мејл адреса и мобилен број како лични податоци да се
                  користат и обработуват од страна на Компа Дигитал со цел моја идентификација,
                  достава на полисата, побрза комуникација во случај на потреба, а во смисла на
                  членот 109 од Законот за супервизија на осигурување, како и да се споделат до
                  соодветното друштво за осигурување за истите цели.
                  <Typography component="span" color="accent.main">
                    *
                  </Typography>
                </Typography>
              }
            />
          )}
        />
        <Controller
          control={control}
          name="permanentlyInhabited"
          rules={{
            validate: (value) => value || 'Потребно е да потврдите дека објектот е трајно населен',
          }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox />}
              {...field}
              onChange={(_, checked) => field.onChange(checked)}
              label={
                <Typography variant="body1" fontSize={14} color="grey.400">
                  Потврдувам дека објектот е трајно населен, односно дека не е ненаселен, не е
                  викендица или објект кој се изнајмува за краток престој (AirBnb, стан на ден и
                  сл.) и дека во време на нарачка на полисата не е настаната штета на предметите во
                  станот од некој од осигурените ризици. (Доколку ова не е случај, јавете се во
                  нашиот кориснички центар на 070 268 000 за асистенција при вадење на
                  осигурувањето, бидејќи истото нема да може да биде спроведено онлајн){' '}
                  <Typography component="span" color="accent.main">
                    *
                  </Typography>
                </Typography>
              }
            />
          )}
        />
        <Controller
          control={control}
          name="contactAgreement"
          rules={{
            required: false,
            validate: () => true,
          }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox />}
              {...field}
              onChange={(_, checked) => field.onChange(checked)}
              label={
                <Typography variant="body1" fontSize={14} color="grey.400">
                  Се согласувам Компа да ме контактира со информации за истекување на важноста на
                  полисата, како и информации за поволности при обнова и вадење на ист или друг тип
                  осигурување.
                </Typography>
              }
            />
          )}
        />
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
          onChange={(v) => {
            if (!v) {
              return;
            }
            setIsNotARobot(true);
            setValue('recaptcha', v);
          }}
        />
      </Box>
      <Stack
        spacing={2}
        marginTop={4}
        borderRadius={4}
        padding={{ xs: 2, md: 4 }}
        width="100%"
        boxSizing="border-box"
        sx={{
          backgroundColor: '#F5F5F5',
        }}
      >
        <Typography variant="h6" fontWeight="bold" color="primary.dark">
          Што е следно?
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={{
            xs: 'row',
            md: 'column',
          }}
        >
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: '1fr',
              md: '2fr 1fr 2fr 1fr 2fr',
            }}
            width={{
              xs: '40%',
              md: '100%',
            }}
            paddingX={{
              xs: 2,
              md: 8,
            }}
            boxSizing="border-box"
          >
            <NextStepsIconContainer>
              <NextStepsIcon as={Visibility} />
              <NextStepsStepNumber>
                <Typography fontWeight="bold">1</Typography>
              </NextStepsStepNumber>
            </NextStepsIconContainer>
            <NextStepsArrow middleIcon={<AccessTime />} />
            <NextStepsIconContainer>
              <NextStepsIcon as={CreditCard} />
              <NextStepsStepNumber>
                <Typography fontWeight="bold">2</Typography>
              </NextStepsStepNumber>
            </NextStepsIconContainer>
            <NextStepsArrow middleIcon={<Mail />} />
            <NextStepsIconContainer>
              <NextStepsIcon as={InsertDriveFileOutlined} />
              <NextStepsStepNumber>
                <Typography fontWeight="bold">3</Typography>
              </NextStepsStepNumber>
            </NextStepsIconContainer>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: '1fr',
              md: '1fr 1fr 1fr',
            }}
            width="100%"
            paddingX={2}
            boxSizing="border-box"
            gap={1}
            height="100%"
          >
            <Typography
              variant="body1"
              color="info.dark"
              fontSize="14px"
              align="center"
              alignSelf={{
                xs: 'flex-start',
                md: 'center',
              }}
            >
              Ги проверуваме податоците и ти испраќаме мејл со потврда дека полисата може да биде
              изработена и линк за плаќање на полисата (очекувано време - 15 минути)
            </Typography>
            <Typography
              variant="body1"
              color="info.dark"
              fontSize="14px"
              align="center"
              alignSelf="center"
            >
              По евидентираната уплата ја изработуваме полисата и ти испраќаме електронски примерок
              на мејл (истиот или најдоцна следниот работен ден).
            </Typography>
            <Typography
              variant="body1"
              color="info.dark"
              fontSize="14px"
              align="center"
              alignSelf={{
                xs: 'flex-end',
                md: 'center',
              }}
            >
              Важноста на полисата започнува по истекот на 24-от час на денот на издавање, освен за
              ризици за кои е децидно наведено поинаку во условите на осигурување.
            </Typography>
          </Box>
        </Box>
      </Stack>

      <Box display="flex" justifyContent="flex-end" alignItems="center" marginY={6}>
        <Button
          variant="contained"
          color="accent"
          size="large"
          type="submit"
          disabled={!isValid || !isNotARobot || isSuccess || isLoading}
          endIcon={
            <ArrowForward
              sx={{
                color: 'primary.dark',
              }}
            />
          }
          sx={{
            fontWeight: 'bold',
            fontSize: '1.25rem',
          }}
        >
          НАРАЧАЈ ЈА ПОЛИСАТА
        </Button>
      </Box>
      <Snackbar
        open={!!isError}
        onClose={reset}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity="error" variant="filled">
          Настана грешка при креирањето на нарачката
        </Alert>
      </Snackbar>
    </Box>
  );
};
