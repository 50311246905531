import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Content: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 25 22">
    <path d="M8.37638 0L6.68438 1.72333L9.79238 4.88889H3.38438C2.05238 4.88889 0.984375 5.97667 0.984375 7.33333V19.5556C0.984375 20.9122 2.05238 22 3.38438 22H22.5844C23.9164 22 24.9844 20.9122 24.9844 19.5556V7.33333C24.9844 5.97667 23.9164 4.88889 22.5844 4.88889H16.1764L19.2844 1.72333L17.5924 0L12.9844 4.69333L8.37638 0ZM3.38438 7.33333H18.9844V19.5556H3.38438V7.33333ZM21.9844 7.33333C22.3026 7.33333 22.6079 7.4621 22.8329 7.69131C23.0579 7.92053 23.1844 8.2314 23.1844 8.55556C23.1844 8.87971 23.0579 9.19059 22.8329 9.4198C22.6079 9.64901 22.3026 9.77778 21.9844 9.77778C21.6661 9.77778 21.3609 9.64901 21.1358 9.4198C20.9108 9.19059 20.7844 8.87971 20.7844 8.55556C20.7844 8.2314 20.9108 7.92053 21.1358 7.69131C21.3609 7.4621 21.6661 7.33333 21.9844 7.33333ZM21.9844 11C22.3026 11 22.6079 11.1288 22.8329 11.358C23.0579 11.5872 23.1844 11.8981 23.1844 12.2222C23.1844 12.5464 23.0579 12.8573 22.8329 13.0865C22.6079 13.3157 22.3026 13.4444 21.9844 13.4444C21.6661 13.4444 21.3609 13.3157 21.1358 13.0865C20.9108 12.8573 20.7844 12.5464 20.7844 12.2222C20.7844 11.8981 20.9108 11.5872 21.1358 11.358C21.3609 11.1288 21.6661 11 21.9844 11Z" />
  </SvgIcon>
);

Content.muiName = 'Content';
