import Carousel from 'react-multi-carousel';
import styled from 'styled-components';

import { Star } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';

import { Review as ReviewType } from '../../types';
import { Review } from '../review';

interface Props {
  reviews: Array<ReviewType>;
  label?: string;
}

export const CustomDot = styled.div<{ active: boolean }>`
  width: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(1)};
  border-radius: 50%;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.accent.main : theme.palette.grey[400]};
  margin: 0 ${({ theme }) => theme.spacing(0.5)};
`;

export const Reviews = ({ reviews, label }: Props) => {
  const averageRating =
    reviews.length === 0
      ? 0
      : (reviews.reduce((acc, review) => acc + review.grade, 0) / reviews.length).toFixed(1);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      padding={2}
      boxSizing="border-box"
    >
      {reviews.length === 0 ? (
        <Typography variant="body1" fontWeight="bold" color="primary.dark">
          Нема коментари
        </Typography>
      ) : null}
      {reviews.length !== 0 ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          marginBottom={2}
        >
          {!!label && (
            <Typography variant="body1" fontWeight="bold" color="primary.dark">
              {label}
            </Typography>
          )}
          <Typography
            variant="body2"
            color="primary.dark"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            Просечна оцена на пакетот:
            <Box component="span" display="flex" alignItems="center" marginLeft={1}>
              <Star sx={{ color: 'accent.main' }} />
              <b>{averageRating}/5</b>
            </Box>
          </Typography>
        </Box>
      ) : null}
      <Divider sx={{ height: '1px', width: '100%' }} />
      {reviews.length > 0 ? (
        <Box display="grid" gridTemplateColumns="1fr" width="100%" paddingTop={2}>
          <Carousel
            swipeable={false}
            arrows
            showDots
            responsive={{
              desktop: {
                breakpoint: { max: 4000, min: 1280 },
                items: 1,
              },
              tablet: {
                breakpoint: { max: 1279, min: 640 },
                items: 1,
              },
              mobile: {
                breakpoint: { max: 640, min: 0 },
                items: 1,
              },
            }}
            // @ts-ignore
            customDot={<CustomDot />}
          >
            {reviews.map((review) => (
              <Review key={review.id} review={review} />
            ))}
          </Carousel>
        </Box>
      ) : null}
    </Box>
  );
};
