import { useEffect, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Navigate, useLocation, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { Close, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useClickAway, useWindowSize } from '@uidotdev/usehooks';

import { Item, ItemDetails } from './components/ItemDetails';
import { usePdfFromUrl } from './hooks/use-pdf-from-url';
import { DetailsContainer } from './styled';
import { LogoColored } from '../../../components/icons/logo-colored';
import { useNavigatePreserveQuery } from '../../../util/useNavigatePreserveQuery';
import { usePolicyData } from '../preview-policy/hooks/use-policy-data';
import { CompanyLogoMap } from '../results/components/policy/constants';
import { CompanyLogo } from '../results/components/policy/styled';
import { SubjectTypeToLabelMapping } from '../results/components/policy-details';
import { PolicySuggestion } from '../results/types';
import { TitleText } from '../styled';

type RisksDetailsItems = Array<Item<PolicySuggestion['risks'][number]>>;

type SubjectsDetailsItems = Array<Item<PolicySuggestion['subjects'][number]>>;

const subjectsDetailsItems: SubjectsDetailsItems = [
  { title: 'Исклучоци', key: 'exception' },
  { title: 'Лимити', key: 'limit' },
  { title: 'Франшиза', key: 'deductible' },
  { title: 'Каренца', key: 'gracePeriod' },
];

const risksDetailsItems: RisksDetailsItems = [
  { title: 'Опис на ризик', key: 'description' },
  { title: 'Исклучоци', key: 'exception' },
  { title: 'Лимити', key: 'limit' },
  { title: 'Франшиза', key: 'deductible' },
  { title: 'Каренца', key: 'gracePeriod' },
];

export const PolicyDetails = () => {
  const navigate = useNavigatePreserveQuery();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerRef = useClickAway((e) => {
    if (e.target instanceof HTMLAnchorElement) {
      return;
    }
    navigate(location.pathname.replace('/details', ''));
  });

  const windowSize = useWindowSize();
  const width = windowSize.width || 0;

  const { policyId: policyIdParam } = useParams<{ policyId: string }>();

  const policyId = Number.parseInt(policyIdParam ?? '', 10);

  const { policy, isLoading } = usePolicyData(policyId);

  const { data: policyPdf } = usePdfFromUrl(policy?.url || '', {
    enabled: policy && !!policy.url,
  });

  const [numPages, setNumPages] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTab = (searchParams.get('tab') as 'risks' | 'subjects' | 'terms') || 'risks';
  const setSelectedTab = (tab: 'risks' | 'subjects' | 'terms') =>
    setSearchParams((params) => {
      params.set('tab', tab);
      return params;
    });

  const [searchValue, setSearchValue] = useState('');

  const filteredRisks = useMemo(
    () =>
      policy?.risks.filter(
        (risk) =>
          risk.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          risk.description?.toLowerCase().includes(searchValue.toLowerCase()) ||
          risk.exception?.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [policy, searchValue],
  );

  const filteredSubjects = useMemo(
    () =>
      policy?.subjects.filter(
        (subject) =>
          SubjectTypeToLabelMapping[subject.type]
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          subject.type?.toLowerCase().includes(searchValue.toLowerCase()) ||
          subject.exception?.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [policy, searchValue],
  );

  const handleTabChange = (e: React.SyntheticEvent<any>, v: 'risks' | 'subjects' | 'terms') =>
    setSelectedTab(v);

  if (!policyId || Number.isNaN(policyId)) {
    return <Navigate to="../../" />;
  }

  if (!policy || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const logoSource = CompanyLogoMap[policy.company];

  return (
    <Box display="flex" alignItems="center" flexDirection="column" paddingTop={3}>
      <Box display="flex" justifyContent="center">
        <LogoColored />
      </Box>
      <Box display="flex" justifyContent="center">
        <TitleText variant="h4" color="primary" fontWeight="bold" align="center">
          Сите детали за одбраната понуда
        </TitleText>
      </Box>
      <DetailsContainer
        display="flex"
        flexDirection="column"
        alignItems="center"
        ref={containerRef}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          position="relative"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <CompanyLogo src={logoSource} />
            <Typography variant="caption" color="primary.dark" marginTop={1}>
              {policy.name}
            </Typography>
          </Box>
          {selectedTab !== 'terms' ? (
            <Box width="30%" display="flex" justifyContent="center" alignItems="center">
              <TextField
                label="Пребарај"
                fullWidth
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <Search />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          ) : null}
          <IconButton
            onClick={() => navigate(location.pathname.replace('/details', ''))}
            sx={{ position: 'absolute', top: -25, right: -25 }}
          >
            <Close />
          </IconButton>
        </Box>

        <Box marginTop={2}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            centered
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab value="risks" label="Осигурени ризици" />
            <Tab value="subjects" label="Предмети на осигурување" />
            <Tab value="terms" label="Услови на осигурувањето (.pdf)" />
          </Tabs>
        </Box>
        <Box marginTop={4} width="100%" display="flex" flexDirection="column">
          {selectedTab === 'risks'
            ? filteredRisks?.map((risk, index) => (
                <ItemDetails
                  key={risk.key}
                  openByDefault={index === 0}
                  data={risk}
                  items={risksDetailsItems}
                  title={risk.name}
                />
              ))
            : null}
          {selectedTab === 'subjects'
            ? filteredSubjects?.map((subject, index) => (
                <ItemDetails
                  key={subject.type}
                  openByDefault={index === 0}
                  data={subject}
                  items={subjectsDetailsItems}
                  title={SubjectTypeToLabelMapping[subject.type]}
                  openKeys={['exception', 'limit']}
                />
              ))
            : null}
          {/* eslint-disable-next-line no-nested-ternary */}
          {selectedTab === 'terms' ? (
            width > 600 ? (
              <Document
                file={policyPdf}
                onLoadSuccess={({ numPages: pages }) => setNumPages(pages)}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    width={width > 1280 ? 1200 : width * 0.95}
                  />
                ))}
              </Document>
            ) : (
              <Button
                variant="contained"
                color="accent"
                href={policy.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Преземи услови
              </Button>
            )
          ) : null}
        </Box>
      </DetailsContainer>
    </Box>
  );
};
