import { useEffect, useMemo, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { ArrowDownward, ArrowForward, ArrowUpward, Clear } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useMediaQuery, useWindowSize } from '@uidotdev/usehooks';

import { Policy } from './components/policy';
import { CustomDot } from './components/reviews';
import { usePolicySuggestions } from './hooks/use-policy-suggestions';
import { useSortOrder } from './hooks/use-sort-order';
import PhotoRight from './images/photo-right.jpeg';
import { FilterPill } from '../../../components/filter-pill';
import { LogoColored } from '../../../components/icons/logo-colored';
import { CITY_LABELS_MAP } from '../../../data/cities';
import { HomeInsuranceForm } from '../../../types/home-insurance';
import { useBuildingData } from '../preview-policy/hooks/use-building-data';
import { BaseContainer, TitleText } from '../styled';

const Container = styled(BaseContainer)`
  background: ${({ theme }) => theme.palette.grey[50]};
`;

const gridTemplateColumns = {
  recommended: 'repeat(auto-fit, minmax(320px, 1fr))',
  price: 'repeat(auto-fit, minmax(320px, 1fr))',
  risks: 'repeat(auto-fit, minmax(320px, 1fr))',
};

const AVAILABLE_COMPANIES = [
  { key: 'sava', name: 'Сава' },
  { key: 'eurolink', name: 'Еуролинк' },
  { key: 'triglav', name: 'Триглав' },
  { key: 'croatia', name: 'Кроација' },
  { key: 'makedonija', name: 'Осигурување Македонија' },
  { key: 'winner', name: 'Винер' },
  { key: 'halk_insurance', name: 'Халк Осигурување' },
  { key: 'euroins', name: 'Евроинс' },
  { key: 'uniqa', name: 'Уника' },
];

const baseWordpressUrl = process.env.REACT_APP_WORDPRESS_BASE_URL;

const BuildingTypeLabelMap: Record<HomeInsuranceForm['buildingType'], string> = {
  apartment: 'стан',
  house: 'куќа',
};

export const Results = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const companies = searchParams.get('companies');
  const sorting = searchParams.get('sorting');

  const isMobileOrTablet = useMediaQuery('(max-width: 704px)');
  const currentWidth = useWindowSize().width ?? 0;

  const getGutter = (width: number): number => {
    let gutter = 0;
    if (width >= 400) {
      gutter = width * 0.1;
    } else if (width >= 300) {
      gutter = width * 0.035;
    } else {
      gutter = width * 0.02;
    }
    return gutter;
  };

  const { sortOrder, updateSortOrder } = useSortOrder();

  const [selectedSorting, setSelectedSorting] = useState<'recommended' | 'price' | 'risks'>(
    sorting ? (sorting as 'recommended' | 'price' | 'risks') : 'recommended',
  );

  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    companies ? companies.split(',') : [],
  );

  useEffect(() => {
    setSearchParams((params) => {
      params.set('companies', selectedFilters.join(','));
      return params;
    });
  }, [selectedFilters, setSearchParams]);

  useEffect(() => {
    setSearchParams((params) => {
      params.set('sorting', selectedSorting);
      return params;
    });
  }, [selectedSorting, setSearchParams]);

  const handleSortingOrderSelect = (value: 'recommended' | 'price' | 'risks') => {
    setSelectedSorting(value);

    if (value === 'recommended' || value === 'risks') {
      updateSortOrder('desc');
    } else {
      updateSortOrder('asc');
    }
  };

  const buildingData = useBuildingData();

  const { data, isLoading } = usePolicySuggestions(
    buildingData?.area ?? 0,
    selectedSorting,
    selectedFilters,
    buildingData?.itemsType,
  );

  const onReadMoreCalculationClick = () => {
    window.open(`${baseWordpressUrl}kako-sporeduva-kompa/`, '_blank');
  };

  const pageSubtitle = useMemo(() => {
    if (!buildingData) {
      return '';
    }
    const buildingType = BuildingTypeLabelMap[buildingData.buildingType];

    return `Ова се понудите за ${buildingType} од ${buildingData.area} метри квадратни во ${
      CITY_LABELS_MAP[buildingData.city]
    } кои Компа ги обезбеди и спореди за тебе.`;
  }, [buildingData]);

  if (!buildingData) {
    return <Navigate to="../" />;
  }

  if (isLoading) {
    return (
      <Container>
        <LogoColored />
        <CircularProgress />
      </Container>
    );
  }

  const onFilterClick = (key: string) => (active: boolean) => {
    if (active) {
      setSelectedFilters((prevFilters) => [...prevFilters, key]);
      return;
    }

    setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter !== key));
  };

  return (
    <Container>
      <LogoColored />
      <TitleText variant="h4" color="primary" fontWeight="bold" align="center">
        Да го избереме најдоброто домаќинско за тебе, заедно!
      </TitleText>
      <Typography variant="body1" color="primary.dark" marginTop={3} textAlign="center">
        {pageSubtitle}
      </Typography>
      <Box marginTop={4} display="flex" alignItems="center" justifyContent="center" width="100%">
        <Tabs
          value={selectedSorting}
          onChange={(e, v) => handleSortingOrderSelect(v)}
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
            '& .MuiTabs-flexContainer': {
              gap: (theme) => theme.spacing(2),
            },
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab value="recommended" label="Препорачано од Компа" />
          <Tab value="price" label="Најниска цена" />
          <Tab value="risks" label="Најшироко покритие" />
        </Tabs>
        <Box marginLeft={4}>
          <IconButton onClick={() => updateSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}>
            <ArrowUpward
              sx={{
                color: sortOrder === 'asc' ? (theme) => theme.palette.accent.main : 'grey.200',
                marginRight: (theme) => `-${theme.spacing(0.25)}`,
              }}
            />
            <ArrowDownward
              sx={{
                color: sortOrder === 'desc' ? (theme) => theme.palette.accent.main : 'grey.200',
                marginLeft: (theme) => `-${theme.spacing(0.25)}`,
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        marginTop={4}
        marginBottom={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
      >
        {selectedFilters.length > 0 && (
          <IconButton
            onClick={() => {
              setSelectedFilters([]);
            }}
            sx={{
              color: (theme) => theme.palette.accent.main,
              marginRight: (theme) => theme.spacing(1),
            }}
          >
            <Clear />
          </IconButton>
        )}
        {AVAILABLE_COMPANIES.map(({ key, name }) => (
          <FilterPill
            onClick={onFilterClick(key)}
            label={name}
            active={selectedFilters.includes(key)}
            sx={{
              marginRight: (theme) => theme.spacing(1),
            }}
          />
        ))}
      </Box>
      {isMobileOrTablet ? (
        <Box width="100%">
          <Carousel
            swipeable
            showDots
            arrows={false}
            partialVisible
            responsive={{
              desktop: {
                breakpoint: { max: 4000, min: 1280 },
                items: 1,
              },
              tablet: {
                breakpoint: { max: 1279, min: 640 },
                items: 1,
              },
              mobile: {
                breakpoint: { max: 660, min: 0 },
                items: 1,
                partialVisibilityGutter: getGutter(currentWidth),
              },
            }}
            // @ts-ignore
            customDot={<CustomDot />}
          >
            {data?.map((policy) => (
              <Box marginBottom={4}>
                <Policy
                  key={policy.id}
                  policy={policy}
                  recommended={policy.recommended}
                  currentTab={selectedSorting}
                />
              </Box>
            ))}
          </Carousel>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: gridTemplateColumns[selectedSorting],
            maxWidth: '1440px',
            gridGap: (theme) => theme.spacing(2),
            width: '100%',
          }}
        >
          {data?.map((policy) => (
            <Policy
              key={policy.id}
              policy={policy}
              recommended={policy.recommended}
              currentTab={selectedSorting}
            />
          ))}
        </Box>
      )}
      <Box width="100%" maxWidth="1440px">
        <Link to={`${process.env.REACT_APP_WORDPRESS_BASE_URL}domakinsko`}>
          <Button variant="text" color="info">
            Почни од почеток
          </Button>
        </Link>
      </Box>
      <Stack
        width="100%"
        maxWidth="1440px"
        direction={['column', 'column', 'row']}
        marginY={4}
        padding={4}
        boxSizing="border-box"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          borderRadius: 2,
          backgroundColor: 'primary.light',
        }}
      >
        <Stack alignItems="flex-start" paddingRight={4} gap={1}>
          <Typography
            variant="h1"
            fontSize={['24px', '24px', '36px']}
            color="primary.dark"
            align="center"
          >
            Како споредуваме?
          </Typography>
          <Typography variant="body1" color="info.dark" letterSpacing="0.04em">
            Компа користи напредна методологија креирана од нашите осигурителни експерти која ги
            споредува и рангира понудите по соодветност водејќи се по избраните критериуми на
            корисникот.
          </Typography>
          <Button
            variant="contained"
            color="accent"
            size="large"
            onClick={onReadMoreCalculationClick}
            sx={{
              marginTop: 4,
            }}
            endIcon={
              <ArrowForward
                sx={{
                  color: 'primary.dark',
                }}
              />
            }
          >
            Прочитај повеќе
          </Button>
        </Stack>
        <Box
          component="img"
          src={PhotoRight}
          alt="photo right"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            width: '30%',
            height: 'min-content',
            borderRadius: 2,
          }}
        />
      </Stack>
    </Container>
  );
};
