import { ApiPolicy } from './types';
import { BuildingType, ConstructionType } from '../../types/home-insurance';
import { api } from '../instance';

export const fetchPolicySuggestions = (
  size: number,
  constructionType: ConstructionType,
  itemsType: 'lux' | 'standard',
  objectType: BuildingType,
) =>
  api
    .get<Array<ApiPolicy>>('/insurance-packages/suggest', {
      params: {
        size,
        constructionType,
        itemsType,
        objectType,
      },
    })
    .then(({ data }) => data);

export const fetchPolicyPdf = async (url: string): Promise<File> => {
  const proxyUrl = 'https://corsproxy.io/?';
  const response = await api.get(proxyUrl + url, {
    responseType: 'blob',
  });
  const file = new File([response.data], 'policy.pdf', {
    type: 'application/pdf',
  });
  return file;
};
