import { useSearchParams } from 'react-router-dom';

import { HomeInsuranceForm } from '../../../../types/home-insurance';
import { fromBinary } from '../../../../util/string';

export const useBuildingData = () => {
  const [queryParams] = useSearchParams();

  const requestDetails = queryParams.get('rid');

  if (!requestDetails) {
    return null;
  }

  const parsedRequestDetails = JSON.parse(fromBinary(requestDetails)) as HomeInsuranceForm & {
    city: string;
    area: number;
  };

  return parsedRequestDetails;
};
