import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Chair: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 22 24">
    <path d="M5.2811 6V12H3.0311V6C3.0311 2.71875 5.70297 0 9.0311 0H12.0311C15.3123 0 18.0311 2.71875 18.0311 6V12H15.7811V6C15.7811 4.64062 14.9842 3.42188 13.9061 2.8125V12H11.6561V2.25H9.4061V12H7.1561V2.8125C6.0311 3.42188 5.2811 4.64062 5.2811 6ZM20.9373 16.0312C21.2655 17.0156 20.5155 18 19.5311 18V23.25C19.5311 23.6719 19.1561 24 18.7811 24H17.2811C16.8592 24 16.5311 23.6719 16.5311 23.25V18H4.5311V23.25C4.5311 23.6719 4.1561 24 3.7811 24H2.2811C1.85922 24 1.5311 23.6719 1.5311 23.25V18C0.499849 18 -0.250151 17.0156 0.0779738 16.0312L0.593599 14.5312C0.781099 13.9219 1.3436 13.5 1.99985 13.5H19.0155C19.6717 13.5 20.2342 13.9219 20.4217 14.5312L20.9373 16.0312Z" />
  </SvgIcon>
);

Chair.muiName = 'Chair';
