import React, { useState } from 'react';

import { Check, Clear } from '@mui/icons-material';
import { Box, IconButton, Tab, Tabs, Typography } from '@mui/material';

import { Container } from './styled';
import { IconFilled } from '../../../../../components/icon-filled';
import { ArrowReturn } from '../../../../../components/icons/arrow-return';
import {
  riskIcons,
  riskShortLabels,
  risksToIgnore,
} from '../../../../../components/icons/riskIcons';
import { PolicySuggestion, Review as ReviewType } from '../../types';
import { Reviews } from '../reviews';

interface ProsAndConsProps {
  pros: Array<string>;
  cons: Array<string>;
}

const ProsAndCons = ({ pros, cons }: ProsAndConsProps) => (
  <Box display="flex" flexDirection="column" overflow="visible" marginTop={1} padding={1}>
    {pros.map((pro, index) => (
      <Box key={pro} display="flex" alignItems="flex-start" marginTop={index !== 0 ? 1 : 0}>
        <IconFilled icon={Check} color="primary" active width="inherit" />
        <Typography variant="body1" color="primary.dark" marginLeft={1}>
          {pro}
        </Typography>
      </Box>
    ))}
    {cons.map((con) => (
      <Box key={con} display="flex" alignItems="flex-start" marginTop={1}>
        <IconFilled icon={Clear} color="accent" active width="inherit" />
        <Typography variant="body1" color="primary.dark" marginLeft={1}>
          {con}
        </Typography>
      </Box>
    ))}
  </Box>
);

interface PolicyScopeProps {
  risks: PolicySuggestion['risks'];
  subjects: Record<string, PolicySuggestion['subjects'][number]>;
}

export const SubjectTypeToLabelMapping: Record<string, string> = {
  CONSTRUCTION_PART: 'Градежен дел',
  ITEMS: 'Предмети во домот',
  THIRD_PARTY_DAMAGE: 'Штети кон соседи',
  ACCOMMODATION: 'Трошоци за сместување',
};

const SUBJECTS_ORDER = ['CONSTRUCTION_PART', 'ITEMS', 'THIRD_PARTY_DAMAGE', 'ACCOMMODATION'];

const PolicyScope = ({ risks, subjects }: PolicyScopeProps) => (
  <Box
    display="flex"
    width="100%"
    flexDirection="column"
    alignItems="center"
    marginTop={1}
    padding={1}
    boxSizing="border-box"
  >
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="subtitle1" textAlign="center">
        Максимална сума на исплата
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        {SUBJECTS_ORDER.map((subject) => (
          <Typography variant="subtitle2" color="primary.dark" align="center">
            {SubjectTypeToLabelMapping[subject]}{' '}
            <b>{subjects[subject] ? `до ${subjects[subject].totalSum}€` : 'нема'}</b>
          </Typography>
        ))}
      </Box>
    </Box>
    <Box
      display="grid"
      gridTemplateColumns="repeat(4, 1fr)"
      marginTop={2}
      sx={{
        gridGap: (theme) => theme.spacing(1),
        alignItems: 'start',
        minWidth: '100%',
      }}
    >
      {risks
        .filter((risk) => !risksToIgnore.includes(risk.key))
        .map((risk) => (
          <IconFilled
            key={risk.key}
            icon={riskIcons[risk.key]}
            width="40px"
            active
            label={riskShortLabels[risk.key]}
            tooltip={risk.note}
            description={risk.descriptionTitle}
          />
        ))}
    </Box>
  </Box>
);

interface ReviewProps {
  reviews: Array<ReviewType>;
}

const ReviewsTab = ({ reviews }: ReviewProps) => {
  const easeOfIssuingReviews = reviews.filter((review) => review.type === 'EASE_OF_ISSUING');

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Reviews reviews={easeOfIssuingReviews} />
    </Box>
  );
};

interface Props {
  visible: boolean;
  hideDetails: () => void;
  policy: PolicySuggestion;
}

export const PolicyDetails = ({ visible, hideDetails, policy }: Props) => {
  const [selectedTab, setSelectedTab] = useState(1);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const subjectMap = policy.subjects.reduce(
    (acc, subject) => {
      // eslint-disable-next-line no-param-reassign
      acc[subject.type] = subject;
      return acc;
    },
    {} as Record<string, PolicySuggestion['subjects'][number]>,
  );

  return (
    <Container visible={visible}>
      <Box display="flex" flexDirection="column" alignItems="center" overflow="hidden">
        <Tabs value={selectedTab} onChange={handleTabChange} centered>
          <Tab value={0} label="Pro/con" />
          <Tab value={1} label="Опфат" />
          <Tab value={2} label="Коментари" />
        </Tabs>
        {selectedTab === 0 ? <ProsAndCons pros={policy.pros} cons={policy.cons} /> : null}
        {selectedTab === 1 ? <PolicyScope risks={policy.risks} subjects={subjectMap} /> : null}
        {selectedTab === 2 ? <ReviewsTab reviews={policy.reviews} /> : null}
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="flex-end" marginBottom={2}>
        <Box width="100%" display="flex" justifyContent="center">
          <IconButton
            onClick={hideDetails}
            sx={{
              border: (theme) => `1px solid ${theme.palette.grey[100]}`,
            }}
          >
            <ArrowReturn />
          </IconButton>
        </Box>
        <Box
          display="grid"
          sx={{
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: 'auto auto',
          }}
          width="100%"
        >
          <Typography
            variant="subtitle1"
            color="primary.dark"
            fontWeight="bold"
            sx={{
              flexGrow: 1,
              flexBasis: 0,
            }}
          >
            Цена
          </Typography>
          <Typography
            variant="subtitle1"
            color="accent.main"
            align="right"
            sx={{
              flexGrow: 1,
              flexBasis: 0,
            }}
          >
            {policy.totalPremium} ден
          </Typography>
          <Typography
            variant="body1"
            color="grey.600"
            fontWeight={300}
            sx={{
              gridColumn: 2,
              gridRow: 2,
              justifySelf: 'end',
            }}
          >
            годишно
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
