import styled from 'styled-components';

export const Container = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: ${({ theme }) => theme.spacing(1)};
  box-sizing: border-box;
  justify-content: space-between;
`;
