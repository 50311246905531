export const LogoWhite = () => (
  <svg
    width="201"
    height="45"
    viewBox="16 12 169 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_77_41249)" filter="url(#filter0_d_77_41249)">
      <path
        d="M73.3639 33.6639C72.1321 35.8025 70.4435 37.4601 68.3069 38.6367C66.1674 39.8161 63.8128 40.4044 61.246 40.4044C58.6792 40.4044 56.2803 39.8161 54.1585 38.6367C52.0367 37.4572 50.3569 35.7996 49.1251 33.6639C47.8933 31.5253 47.2773 29.1113 47.2773 26.4163C47.2773 23.7212 47.8933 21.3073 49.1251 19.1716C50.3569 17.0329 52.0338 15.3666 54.1585 14.1727C56.2803 12.9788 58.6438 12.3789 61.246 12.3789C63.8482 12.3789 66.1674 12.9759 68.3069 14.1727C70.4464 15.3666 72.1321 17.0358 73.3639 19.1716C74.5958 21.3102 75.2117 23.7241 75.2117 26.4163C75.2117 29.1084 74.5958 31.5253 73.3639 33.6639ZM67.9975 21.9999C67.2961 20.7045 66.3531 19.7019 65.1743 18.9948C63.9955 18.2877 62.6841 17.9342 61.246 17.9342C59.8079 17.9342 58.4994 18.2877 57.3177 18.9948C56.1359 19.7019 55.1959 20.7045 54.4945 21.9999C53.7931 23.2952 53.4424 24.7703 53.4424 26.4192C53.4424 28.0681 53.7931 29.5344 54.4945 30.8124C55.1959 32.0932 56.1389 33.0843 57.3177 33.7914C58.4994 34.4985 59.8079 34.852 61.246 34.852C62.6841 34.852 63.9926 34.4985 65.1743 33.7914C66.356 33.0843 67.2961 32.0932 67.9975 30.8124C68.6989 29.5344 69.0496 28.0681 69.0496 26.4192C69.0496 24.7703 68.6989 23.2981 67.9975 21.9999Z"
        fill="white"
      />
      <path
        d="M148.338 14.1727C150.253 15.3666 151.771 17.0271 152.882 19.1455C153.993 21.2667 154.55 23.6749 154.55 26.367C154.55 29.0591 154.002 31.476 152.906 33.6146C151.809 35.7533 150.312 37.4196 148.412 38.6135C146.511 39.8074 144.415 40.4073 142.12 40.4073C140.475 40.4073 138.928 40.0798 137.472 39.422C136.017 38.7642 134.811 37.9673 133.851 37.0225V46.9188C133.851 47.794 133.559 48.5272 132.978 49.1154C132.395 49.7037 131.661 49.9993 130.771 49.9993C129.881 49.9993 129.144 49.7124 128.564 49.1415C127.98 48.5677 127.691 47.8288 127.691 46.9188V15.9636C127.691 15.0884 127.983 14.3553 128.564 13.767C129.144 13.1787 129.881 12.8831 130.771 12.8831C131.661 12.8831 132.398 13.1787 132.978 13.767C133.559 14.3553 133.851 15.0884 133.851 15.9636V16.1664C134.673 15.1551 135.819 14.2712 137.29 13.5149C138.76 12.7585 140.301 12.3789 141.911 12.3789C144.274 12.3789 146.411 12.9759 148.329 14.1727H148.338ZM146.54 32.4265C147.943 30.8095 148.644 28.7896 148.644 26.367C148.644 23.9444 147.952 21.9332 146.564 20.3336C145.176 18.734 143.372 17.9342 141.147 17.9342C138.922 17.9342 137.107 18.734 135.704 20.3336C134.301 21.9332 133.6 23.9444 133.6 26.367C133.6 28.7896 134.301 30.8095 135.704 32.4265C137.107 34.0435 138.922 34.8491 141.147 34.8491C143.372 34.8491 145.135 34.0406 146.54 32.4265Z"
        fill="white"
      />
      <path
        d="M184.128 13.2367C184.709 13.8105 185 14.5494 185 15.4594V36.8197C185 37.6949 184.709 38.428 184.128 39.0163C183.544 39.6046 182.811 39.9001 181.921 39.9001C181.031 39.9001 180.303 39.6133 179.737 39.0424C179.171 38.4715 178.874 37.747 178.838 36.8719C177.981 37.8484 176.825 38.683 175.373 39.3698C173.917 40.0595 172.384 40.4044 170.775 40.4044C168.412 40.4044 166.272 39.8074 164.357 38.6106C162.438 37.4167 160.924 35.7562 159.813 33.6378C158.699 31.5166 158.145 29.1113 158.145 26.4163C158.145 23.7212 158.693 21.3073 159.789 19.1716C160.885 17.0329 162.382 15.3666 164.283 14.1727C166.184 12.9788 168.279 12.3789 170.575 12.3789C172.219 12.3789 173.758 12.6745 175.196 13.2628C176.634 13.851 177.848 14.6016 178.841 15.5086V15.4594C178.841 14.5842 179.133 13.851 179.713 13.2628C180.294 12.6745 181.031 12.3789 181.921 12.3789C182.811 12.3789 183.547 12.6658 184.128 13.2367ZM176.99 32.4497C178.393 30.85 179.095 28.8389 179.095 26.4163C179.095 23.9936 178.393 21.9738 176.99 20.3568C175.588 18.7398 173.772 17.9342 171.547 17.9342C169.322 17.9342 167.557 18.7427 166.154 20.3568C164.752 21.9738 164.05 23.9936 164.05 26.4163C164.05 28.8389 164.743 30.8529 166.131 32.4497C167.516 34.0493 169.322 34.8491 171.547 34.8491C173.772 34.8491 175.588 34.0493 176.99 32.4497Z"
        fill="white"
      />
      <path
        d="M98.4973 38.6374C97.9167 38.0491 97.625 37.3159 97.625 36.4408V23.6147C97.625 19.9779 98.6447 17.1351 100.681 15.0805C102.717 13.0259 105.62 12 109.386 12C113.153 12 116.047 13.0259 118.065 15.0805C120.087 17.1322 121.095 19.9779 121.095 23.6147V36.4408C121.095 37.3159 120.803 38.0491 120.222 38.6374C119.642 39.2257 118.905 39.5212 118.015 39.5212C117.125 39.5212 116.388 39.2257 115.808 38.6374C115.227 38.0491 114.936 37.3159 114.936 36.4408V23.6147C114.936 21.5601 114.473 20.0387 113.55 19.0448C112.625 18.0508 111.24 17.5552 109.392 17.5552C107.545 17.5552 106.106 18.0508 105.181 19.0448C104.256 20.0358 103.796 21.5601 103.796 23.6147V36.4408C103.796 37.3159 103.504 38.0491 102.924 38.6374C102.343 39.2257 101.606 39.5212 100.716 39.5212C99.8264 39.5212 99.0896 39.2257 98.5091 38.6374H98.4973Z"
        fill="white"
      />
      <path
        d="M81.1809 38.6374C80.6003 38.0491 80.3086 37.3159 80.3086 36.4408V23.6147C80.3086 19.9779 81.3282 17.1351 83.3646 15.0805C85.401 13.0259 88.3037 12 92.07 12C95.8362 12 98.7301 13.0259 100.749 15.0805C102.77 17.1322 103.778 19.9779 103.778 23.6147V36.4408C103.778 37.3159 103.487 38.0491 102.906 38.6374C102.325 39.2257 101.589 39.5212 100.699 39.5212C99.8087 39.5212 99.072 39.2257 98.4914 38.6374C97.9109 38.0491 97.6191 37.3159 97.6191 36.4408V23.6147C97.6191 21.5601 97.1564 20.0387 96.234 19.0448C95.3087 18.0508 93.9236 17.5552 92.0759 17.5552C90.2281 17.5552 88.79 18.0508 87.8646 19.0448C86.9393 20.0358 86.4796 21.5601 86.4796 23.6147V36.4408C86.4796 37.3159 86.1878 38.0491 85.6072 38.6374C85.0267 39.2257 84.29 39.5212 83.4 39.5212C82.51 39.5212 81.7732 39.2257 81.1927 38.6374H81.1809Z"
        fill="white"
      />
      <path
        d="M39.5399 38.6225C38.6911 38.6225 37.8925 38.2979 37.2913 37.7067L32.0781 32.5804L36.5752 28.1582L41.7884 33.2846C42.3896 33.8728 42.7196 34.6582 42.7196 35.4957C42.7196 36.3331 42.3896 37.1185 41.7884 37.7067C41.1872 38.295 40.3886 38.6225 39.5399 38.6225Z"
        fill="white"
      />
      <path
        d="M17.1226 17.8765C16.5215 17.2853 16.1914 16.5 16.1914 15.6654C16.1914 14.8308 16.5215 14.0455 17.1226 13.4543C17.5205 13.0631 18.0126 12.7849 18.546 12.6429C18.7523 12.6023 18.9645 12.582 19.1796 12.582H19.2533C20.936 12.6226 22.3565 14.0802 22.3565 15.7668V23.026L17.1226 17.8765Z"
        fill="white"
      />
      <path
        d="M19.3743 39.3554C18.9794 39.3554 18.5963 39.2858 18.2309 39.1496C18.2191 39.1438 18.2043 39.1409 18.1925 39.1351C16.8811 38.712 16 37.5181 16 36.1648V21.1943L22.4509 27.5378C22.6218 27.703 22.8458 27.7957 23.0845 27.7957C23.3232 27.7957 23.5472 27.703 23.7181 27.5378L37.2919 14.1872C37.8931 13.596 38.6917 13.2715 39.5404 13.2715C40.3891 13.2715 41.1878 13.596 41.789 14.1872C42.3901 14.7784 42.7202 15.5637 42.7202 16.3983C42.7202 17.2329 42.3901 18.0211 41.789 18.6094L21.6228 38.4396C21.0216 39.0308 20.226 39.3554 19.3743 39.3554Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_77_41249"
        x="0"
        y="0"
        width="201"
        height="70"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0627451 0 0 0 0 0.0627451 0 0 0 0.08 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_77_41249" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_77_41249"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_77_41249">
        <rect width="169" height="38" fill="white" transform="translate(16 12)" />
      </clipPath>
    </defs>
  </svg>
);
