import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Building: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 26 22">
    <path d="M24.8318 13.4427L24.8081 13.4545C25.0805 13.7864 25.3173 14.1539 25.4949 14.5451L25.6015 14.7703C26.4422 16.7737 25.8501 19.0853 24.299 20.5315C22.8899 21.8236 20.9243 22.1674 19.1009 21.9303C17.3721 21.7169 15.7854 20.6263 14.8737 19.1208C14.6013 18.6585 14.3645 18.1369 14.2461 17.6035C14.0922 17.1886 14.0448 16.7381 14.0093 16.2995C13.9027 14.4029 14.6605 12.3876 16.0933 11.2022C15.442 12.6366 15.596 14.4266 16.5551 15.6713L16.6853 15.8254C16.8511 15.9676 17.0524 16.0032 17.2418 15.9321C17.4195 15.8609 17.5615 15.6831 17.5615 15.4934L17.4787 15.2089C16.4367 12.4469 17.3129 9.24628 19.5271 7.43259C20.131 6.93471 20.877 6.48425 21.6585 6.28273C20.8533 7.8949 21.1138 10.0049 22.4044 11.2615C22.9491 11.8542 23.6122 12.198 24.1687 12.7196L24.8318 13.4427ZM22.1321 18.9667L22.1203 18.9312C22.6531 18.4688 22.9491 17.6746 22.9254 16.9634L22.8899 16.5959C22.6531 15.4105 21.623 15.0074 20.9599 14.1421L20.4507 13.2174C20.1902 13.8101 20.1665 14.3673 20.2731 15.0074C20.3915 15.6831 20.652 16.264 20.5218 16.9634C20.3323 17.7339 19.7284 18.5044 18.6746 18.7534C19.2666 19.3342 20.2257 19.7965 21.1848 19.4646C21.4927 19.3816 21.8834 19.1564 22.1321 18.9667ZM2.16846 14.225H11.7122L11.6411 15.4105C11.6411 17.0819 12.0674 18.6467 12.8252 20.0099V20.1521H2.16846V14.225ZM0.984375 7.11252H8.08888V13.0396H0.984375V7.11252ZM9.27296 7.11252H16.3775V7.1955C14.2106 8.45205 12.5766 10.5502 11.9371 13.0396H9.27296V7.11252ZM2.16846 0H11.6411V5.9271H2.16846V0ZM12.8252 0H23.482V5.9271H12.8252V0Z" />
  </SvgIcon>
);

Building.muiName = 'Building';
