import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { fetchPolicyPdf } from '../../../../api/policies';

export const usePdfFromUrl = (url: string, options: UseQueryOptions<File> = {}) =>
  useQuery({
    queryKey: ['policyPdf', url],
    queryFn: () => fetchPolicyPdf(url),
    ...options,
  });
