import { useEffect, useState } from 'react';

import { EmojiEmotions, QuestionMark, Search } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Tooltip, Typography } from '@mui/material';

import { CompanyLogoMap } from './constants';
import Ananas from './logos/ananas.png';
import {
  CheapestLabel,
  CompanyLogo,
  HighlightLabel,
  HighlightLabelSmall,
  PolicyContainer,
} from './styled';
import { IconFilled } from '../../../../../components/icon-filled';
import { Burglar } from '../../../../../components/icons/burglar';
import { Explosion } from '../../../../../components/icons/explosion';
import { Fire } from '../../../../../components/icons/fire';
import { Flood } from '../../../../../components/icons/flood';
import { Hazard } from '../../../../../components/icons/hazard';
import { Spillage } from '../../../../../components/icons/spilllage';
import { useNavigatePreserveQuery } from '../../../../../util/useNavigatePreserveQuery';
import { PolicySuggestion } from '../../types';
import { PolicyDetails } from '../policy-details';

export const THIRD_PARTY_DAMAGE_DESCRIPTION =
  'Со осигурувањето се покриени штети на трети лица како последица на штета што ќе настане кај осигуреникот од осигурените ризици.';
interface Props {
  recommended?: boolean;
  policy: PolicySuggestion;
  cheapest?: boolean;
  currentTab: string;
}

const kompaRatingTooltip = (
  <Stack alignItems="flex-start">
    <Typography variant="body1" fontWeight="bold" color="primary.dark">
      Како оценува Компа:
    </Typography>
    <Typography variant="body1" color="primary.dark" marginTop={1}>
      Оценката што ја доделуваме на секоја полиса зависи од 4 критериуми кои ги земаме предвид
    </Typography>
    <Typography variant="body1" color="primary.dark" marginTop={1}>
      1. осигурителното покритие
    </Typography>
    <Typography variant="body1" color="primary.dark">
      2. цената на полисата
    </Typography>
    <Typography variant="body1" color="primary.dark">
      3. рејтинзите на корисниците за администрација и решавање штети
    </Typography>
    <Typography variant="body1" color="primary.dark">
      4. едноставноста на вадење и условите на плаќање
    </Typography>
  </Stack>
);

export const Policy = ({ recommended, policy, cheapest, currentTab }: Props) => {
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    setShowDetails(false);
  }, [currentTab]);

  const highlightText = policy.frameText;

  const logoSource = CompanyLogoMap[policy.company];

  const navigate = useNavigatePreserveQuery();

  const fireRisk = policy.risks.find((risk) => risk.key === 'fire');
  const spillageRisk = policy.risks.find((risk) => risk.key === 'spillage');
  const theftRisk = policy.risks.find((risk) => risk.key === 'theft');
  const explosionRisk = policy.risks.find((risk) => risk.key === 'explosion');
  const floodRisk = policy.risks.find((risk) => risk.key === 'flood');
  const thirdPartyDamage = policy.subjects.find((subject) => subject.type === 'THIRD_PARTY_DAMAGE');

  return (
    <PolicyContainer recommended={recommended}>
      {recommended ? (
        <HighlightLabel recommended>
          <Typography variant="body1" fontSize="0.7rem" color="accent.contrastText">
            Препорачано од Компа
          </Typography>
        </HighlightLabel>
      ) : null}
      {!recommended && highlightText && highlightText !== '' ? (
        <HighlightLabelSmall>
          <Typography variant="body1" color="white" fontSize="0.7rem">
            {highlightText}
          </Typography>
        </HighlightLabelSmall>
      ) : null}
      {cheapest ? (
        <CheapestLabel>
          <Typography variant="body1" color="primary.dark" fontSize="0.7rem">
            Најевтино
          </Typography>
        </CheapestLabel>
      ) : null}
      <Box marginTop={4} display="flex" justifyContent="center">
        <CompanyLogo src={logoSource} />
      </Box>
      <Box marginTop={1}>
        <Typography color="grey.600">{policy.name}</Typography>
      </Box>
      <Box marginTop={1} width="75%">
        <Divider />
      </Box>
      {!showDetails ? (
        <>
          <Box marginTop={1}>
            <Typography color="primary.dark" fontWeight="bold">
              Клучни ризици
            </Typography>
          </Box>
          <Box
            marginTop={1}
            display="grid"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridColumnGap: (theme) => theme.spacing(2),
              gridRowGap: (theme) => theme.spacing(1),
              alignItems: 'start',
            }}
          >
            <IconFilled
              icon={Fire}
              active={!!fireRisk}
              label="пожар"
              tooltip={fireRisk?.note}
              description={fireRisk?.descriptionTitle}
            />
            <IconFilled
              icon={Spillage}
              active={!!spillageRisk}
              label="излевање"
              tooltip={spillageRisk?.note}
              description={spillageRisk?.descriptionTitle}
            />
            <IconFilled
              icon={Hazard}
              active={!!thirdPartyDamage}
              label="трети лица"
              description={THIRD_PARTY_DAMAGE_DESCRIPTION}
            />
            <IconFilled
              icon={Burglar}
              active={!!theftRisk}
              label="кражба"
              tooltip={theftRisk?.note}
              description={theftRisk?.descriptionTitle}
            />
            <IconFilled
              icon={Explosion}
              active={!!explosionRisk}
              label="експлозија"
              tooltip={explosionRisk?.note}
              description={explosionRisk?.descriptionTitle}
            />
            <IconFilled
              icon={Flood}
              active={!!floodRisk}
              label="поплава"
              tooltip={floodRisk?.note}
              description={floodRisk?.descriptionTitle}
            />
          </Box>
          <Box width="75%" marginTop={2}>
            <Divider />
          </Box>
          <Box marginTop={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              marginBottom={1}
            >
              <Search
                color="accent"
                sx={{
                  marginRight: 0.5,
                }}
              />
              <Typography color="primary.dark" fontWeight="bold">
                Компа review
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="grey.600"
              sx={{
                wordWrap: 'break-word',
              }}
              textAlign="center"
            >
              {policy.kompaReview}
            </Typography>
          </Box>
          <Box
            marginTop={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            paddingX={2}
            boxSizing="border-box"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              marginBottom={1.5}
            >
              <Tooltip title={kompaRatingTooltip} placement="top" enterTouchDelay={0}>
                <Box display="flex" alignItems="center">
                  <Typography color="grey.600" marginRight={0.5}>
                    Компа рејтинг
                  </Typography>
                  <IconFilled icon={QuestionMark} iconSize="12px" width="16px" />
                </Box>
              </Tooltip>

              <Typography color="primary.dark" fontWeight="bold" display="flex" alignItems="center">
                <EmojiEmotions
                  fontSize="small"
                  color="accent"
                  sx={{
                    marginRight: 0.5,
                  }}
                />{' '}
                {policy.kompaRating.toFixed(1)} / 10
              </Typography>
            </Box>
          </Box>
          <Box width="75%" marginTop={1}>
            <Divider />
          </Box>
          <Box
            marginTop="auto"
            height="100%"
            paddingX={2}
            boxSizing="border-box"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Box
              display="grid"
              gridTemplateColumns="4fr 2fr"
              alignItems="flex-start"
              width="100%"
              marginBottom={1.5}
            >
              <Box display="flex" flexDirection="column">
                <Typography color="grey.600">Цена (годишно)</Typography>
                <Typography color="grey.600" fontSize="0.85rem">
                  + подарок{' '}
                  <Box
                    component="img"
                    src={Ananas}
                    sx={{
                      width: 14,
                      height: 14,
                    }}
                  />{' '}
                  ваучер
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                justifyContent="flex-start"
                height="100%"
              >
                <Typography
                  sx={{
                    color: 'accent.main',
                    fontWeight: 'bold',
                    fontSize: 20,
                    whiteSpace: 'nowrap',
                    position: 'relative',
                  }}
                  justifySelf="flex-end"
                >
                  {policy.oldPrice && (
                    <Box
                      sx={{
                        position: 'absolute',
                        color: 'grey.600',
                        top: (theme) => `-${theme.spacing(2)}`,
                        right: (theme) => theme.spacing(0.5),
                        // strikethrough
                        '::after': {
                          content: '""',
                          position: 'absolute',
                          top: '50%',
                          left: 0,
                          width: '100%',
                          height: '1px',
                          backgroundColor: 'primary.main',
                        },
                      }}
                    >
                      <Typography>{policy.oldPrice.toFixed(0)} ден</Typography>
                    </Box>
                  )}
                  {policy.totalPremium.toFixed(0)} ден
                  {policy.couponValue && (
                    <Box
                      sx={{
                        position: 'absolute',
                        color: 'grey.600',
                        bottom: (theme) => `-${theme.spacing(2)}`,
                        right: (theme) => theme.spacing(0.5),
                      }}
                    >
                      <Typography fontSize="0.85rem">
                        {policy.couponValue.toFixed(0)} ден
                      </Typography>
                    </Box>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            marginTop={1}
            alignItems="center"
            justifyContent="flex-end"
            height="100%"
          >
            <Box>
              <Button
                variant="outlined"
                color="info"
                size="medium"
                fullWidth
                onClick={() => setShowDetails(true)}
              >
                повеќе информации
              </Button>
            </Box>
            <Box marginTop={1} marginBottom={2}>
              <Button
                variant="contained"
                color="accent"
                size="large"
                fullWidth
                onClick={() => {
                  navigate(`../policy/${policy.id}`);
                  window.scrollTo(0, 0);
                }}
              >
                ОДБЕРИ ПОНУДА
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <PolicyDetails
          policy={policy}
          visible={showDetails}
          hideDetails={() => setShowDetails(false)}
        />
      )}
    </PolicyContainer>
  );
};
