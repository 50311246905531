import styled from 'styled-components';

import { Box } from '@mui/material';

export const PaymentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  gap: ${({ theme }) => theme.spacing(3)};
  text-align: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;

export const PaymentMethodsContainer = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const PaymentMethod = styled.img`
  width: auto;
  height: 40px;
`;
