import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Housing: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 26 18">
    <path d="M21.2727 2.4H11.8182V10.8H2.36364V0H0V18H2.36364V14.4H23.6364V18H26V7.2C26 5.92696 25.5019 4.70606 24.6154 3.80589C23.7289 2.90571 22.5265 2.4 21.2727 2.4ZM7.09091 9.6C8.03122 9.6 8.93302 9.22072 9.59792 8.54559C10.2628 7.87045 10.6364 6.95478 10.6364 6C10.6364 5.04522 10.2628 4.12955 9.59792 3.45442C8.93302 2.77928 8.03122 2.4 7.09091 2.4C6.1506 2.4 5.2488 2.77928 4.58389 3.45442C3.91899 4.12955 3.54545 5.04522 3.54545 6C3.54545 6.95478 3.91899 7.87045 4.58389 8.54559C5.2488 9.22072 6.1506 9.6 7.09091 9.6Z" />
  </SvgIcon>
);

Housing.muiName = 'Housing';
