import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Snow: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M18.59,11.95l-2.33,0.62l-2-1.13V8.56l2-1.13l2.33,0.62l0.52-1.93l-1.77-0.47l0.46-1.77l-1.93-0.52l-0.62,2.33
	l-2,1.13L10.8,5.38V3.12l1.71-1.71L11.09,0L9.8,1.29L8.51,0L7.09,1.41L8.8,3.12v2.26L6.3,6.82l-2-1.13L3.72,3.36L1.8,3.88l0.47,1.77
	L0.5,6.12l0.52,1.93l2.33-0.62l2,1.13v2.89l-2,1.13l-2.33-0.62L0.5,13.89l1.77,0.47L1.8,16.12l1.93,0.52l0.62-2.33l2-1.13l2.45,1.44
	v2.26l-1.71,1.71L8.51,20l1.29-1.29L11.09,20l1.41-1.41l-1.7-1.71v-2.26l2.5-1.45l2,1.13l0.62,2.33l1.88-0.51l-0.47-1.77l1.77-0.47
	L18.59,11.95z M7.3,8.56l2.5-1.45l2.5,1.45v2.88l-2.5,1.45l-2.5-1.45V8.56z"
    />{' '}
  </SvgIcon>
);

Snow.muiName = 'Snow';
