import { SvgIconComponent } from '@mui/icons-material';
import { SvgIconProps, SvgIcon } from '@mui/material';

export const Accident: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 21 18">
    <path d="M19.46 17.5C18.4 17.5 17.4 17.24 16.46 16.74C14.62 17.74 12.31 17.74 10.47 16.74C8.63 17.74 6.32 17.74 4.5 16.74C3.27 17.43 1.86 17.54 0.5 17.5V15.5C1.91 15.54 3.27 15.39 4.5 14.5C6.24 15.74 8.71 15.74 10.47 14.5C12.24 15.74 14.7 15.74 16.46 14.5C17.67 15.39 19.04 15.54 20.44 15.5V17.5H19.46ZM20.5 0L5.61 2.46L11.61 8.67L20.5 0ZM9.31 12.86L10.47 12.04L11.62 12.86C12.15 13.22 12.8 13.43 13.47 13.43C13.62 13.43 13.78 13.41 13.93 13.39L3.7 2.81C2.79 4.15 2.4 5.82 2.5 7.42L8.24 13.33C8.63 13.24 9 13.08 9.31 12.86Z" />
  </SvgIcon>
);

Accident.muiName = 'Accident';
