import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Box, Typography } from '@mui/material';

import IconRadio from '../../../components/form/icon-radio';
import { FormNavigation } from '../../../components/form-navigation';
import { Apartment } from '../../../components/icons/apartment';
import { House } from '../../../components/icons/house';
import { LogoColored } from '../../../components/icons/logo-colored';
import { buildingTypeState, homeInsuranceState } from '../../../stores/home-insurance';
import { useSetRecoilKeyValue } from '../../../stores/use-set-recoil-key-value';
import { useIsTabletOrLarger } from '../../../util/useIsTabletOrLarger';
import { useNavigatePreserveQuery } from '../../../util/useNavigatePreserveQuery';
import { BaseContainer, ChoicesContainer, TitleText } from '../styled';

export const BuildingType = () => {
  const buildingType = useRecoilValue(buildingTypeState);

  const [selectedBuildingType, setSelectedBuildingType] = useState<'apartment' | 'house'>(
    buildingType,
  );

  const remainingQuestionCount = useMemo(
    () => (selectedBuildingType === 'apartment' ? 1 : 2),
    [selectedBuildingType],
  );

  const isTabletOrLarger = useIsTabletOrLarger();

  const setKeyValue = useSetRecoilKeyValue(homeInsuranceState);

  const navigate = useNavigatePreserveQuery();

  const onSubmit = useCallback(() => {
    setKeyValue('buildingType', selectedBuildingType);

    switch (selectedBuildingType) {
      case 'apartment':
        navigate('../extras');
        return;
      case 'house':
        navigate('../construction');
        return;
      default:
        throw new Error('Unsupported building type');
    }
  }, [navigate, selectedBuildingType, setKeyValue]);

  const onPrev = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_WORDPRESS_BASE_URL}domakinsko`;
  }, []);

  return (
    <>
      <BaseContainer>
        <LogoColored />
        <TitleText variant="h4" color="primary" fontWeight="bold" align="center">
          Да го избереме најдоброто домаќинско за тебе, заедно!
        </TitleText>
        <Typography variant="subtitle1" color="primary.dark" marginTop={3}>
          Објектот е:
        </Typography>
        <ChoicesContainer gridTemplateColumns="1fr 1fr" marginTop={2}>
          <IconRadio
            value="apartment"
            label="Стан"
            icon={Apartment}
            active={selectedBuildingType === 'apartment'}
            onClick={() => setSelectedBuildingType('apartment')}
          />
          <IconRadio
            value="house"
            label="Куќа"
            icon={House}
            active={selectedBuildingType === 'house'}
            onClick={() => setSelectedBuildingType('house')}
          />
        </ChoicesContainer>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
          width={{
            xs: '75%',
            md: '50%',
          }}
        >
          <Typography variant="subtitle2" color="grey.400" textAlign="center">
            Ова и следните неколку прашања ќе ни помогнат да ти ги прикажеме споредените понуди
            подредени по релевантност за тебе.
          </Typography>
        </Box>
      </BaseContainer>
      <FormNavigation
        onPrev={onPrev}
        prevLabel="Претходно прашање"
        onNext={onSubmit}
        nextLabel={isTabletOrLarger ? 'Следно прашање' : 'Следно'}
        nextSubtitle={`уште ${
          remainingQuestionCount === 1 ? '1 прашање' : `${remainingQuestionCount} прашања`
        }`}
      />
    </>
  );
};
