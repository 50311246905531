import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Typography } from '@mui/material';

import IconRadio from '../../../components/form/icon-radio';
import { FormNavigation } from '../../../components/form-navigation';
import { Chair } from '../../../components/icons/chair';
import { Couch } from '../../../components/icons/couch';
import { LogoColored } from '../../../components/icons/logo-colored';
import {
  buildingTypeState,
  homeInsuranceState,
  itemsTypeState,
} from '../../../stores/home-insurance';
import { useSetRecoilKeyValue } from '../../../stores/use-set-recoil-key-value';
import { useIsTabletOrLarger } from '../../../util/useIsTabletOrLarger';
import { useNavigatePreserveQuery } from '../../../util/useNavigatePreserveQuery';
import { BaseContainer, ChoicesContainer, TitleText } from '../styled';

export const ItemsType = () => {
  const itemsType = useRecoilValue(itemsTypeState);
  const buildingType = useRecoilValue(buildingTypeState);

  const [selectedItemsType, setSelectedItemsType] = useState<'lux' | 'standard'>(itemsType);

  const isTabletOrLarger = useIsTabletOrLarger();

  const setKeyValue = useSetRecoilKeyValue(homeInsuranceState);

  const navigate = useNavigatePreserveQuery();

  const onSubmit = useCallback(() => {
    setKeyValue('itemsType', selectedItemsType);

    navigate('../extras');
  }, [navigate, selectedItemsType, setKeyValue]);

  const onBack = () => {
    switch (buildingType) {
      case 'apartment':
        navigate('../building');
        return;
      case 'house':
        navigate('../construction');
        return;
      default:
        throw new Error('Unsupported building type');
    }
  };

  return (
    <>
      <BaseContainer>
        <LogoColored />
        <TitleText variant="h4" color="primary" fontWeight="bold" align="center">
          Да го избереме најдоброто домаќинско за тебе, заедно!
        </TitleText>
        <Typography variant="subtitle1" color="primary.dark" marginTop={3}>
          Опременост на објектот:
        </Typography>
        <ChoicesContainer gridTemplateColumns="1fr 1fr" marginTop={2}>
          <IconRadio
            value="standard"
            label="Стандардно"
            icon={Chair}
            active={selectedItemsType === 'standard'}
            onClick={() => setSelectedItemsType('standard')}
          />
          <IconRadio
            value="lux"
            label="Луксузно"
            icon={Couch}
            active={selectedItemsType === 'lux'}
            onClick={() => setSelectedItemsType('lux')}
          />
        </ChoicesContainer>
      </BaseContainer>
      <FormNavigation
        onNext={onSubmit}
        onPrev={onBack}
        prevLabel={isTabletOrLarger ? 'Претходно прашање' : 'Претходно'}
        nextLabel={isTabletOrLarger ? 'Следно прашање' : 'Следно'}
        nextSubtitle="уште 1 прашање"
      />
    </>
  );
};
