import { Check } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';

import { AdvantagesContainer } from './styled';
import { IconFilled } from '../../../../../components/icon-filled';

const KompaItem = ({ text }: { text: string }) => (
  <Box display="flex" alignItems="center" marginTop={3}>
    <IconFilled icon={Check} color="primary" active width="inherit" />
    <Typography variant="body1" color="primary.dark" fontWeight={300} marginLeft={2}>
      {text}
    </Typography>
  </Box>
);

export const KompaAdvantages = () => (
  <AdvantagesContainer>
    <Typography variant="h6" color="primary.dark">
      Предности на Компа
    </Typography>
    <Box marginTop={3} width="100%">
      <Divider
        sx={{
          borderColor: 'secondary.main',
        }}
      />
    </Box>
    <KompaItem text="Целосно онлајн вадење на полисата" />
    <KompaItem text="Загарантирана цена, никогаш поскапа од компанијата" />
    <KompaItem text="Осигурителни експерти на располагање за сите дополнителни прашања" />
    <KompaItem text="Подарок со секоја извадена полиса" />
  </AdvantagesContainer>
);
