import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Explosion: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 256 256">
    <path
      d="M99.97,196.39c-3.06,1.47-5.81,2.71-8.48,4.1c-13.61,7.08-27.21,14.21-40.81,21.31c-5.84,3.05-11.27,2.46-15.4-1.67
			c-3.57-3.57-4.15-8.81-1.2-14.5c7.82-15.03,15.78-29.99,23.65-45c0.66-1.26,1.12-2.64,1.9-4.51c-7.64-2.51-14.95-4.92-22.26-7.31
			c-9-2.95-18.03-5.8-26.99-8.86c-6.96-2.37-10.34-6.51-10.38-12.2c-0.04-5.17,3.88-9.45,10.92-11.79
			c14.31-4.75,28.64-9.47,42.96-14.21c1.11-0.37,2.19-0.81,3.86-1.44c-1.31-2.1-2.35-3.91-3.53-5.62
			C37.42,70.28,20.63,45.86,3.78,21.48c-2.6-3.77-4.35-7.69-3.37-12.35c1.55-7.4,10.3-11.36,17.56-7.46
			c5.38,2.89,10.32,6.62,15.37,10.1c21,14.46,41.97,28.97,62.96,43.45c1.18,0.81,2.42,1.55,4.21,2.68c1.7-5.05,3.31-9.67,4.82-14.32
			c3.8-11.66,7.51-23.35,11.35-35c1.79-5.43,5.94-8.48,11.27-8.59c5.23-0.1,9.34,2.55,11.15,8c5.4,16.19,10.56,32.45,15.82,48.68
			c0.48,1.49,1.04,2.97,1.73,4.94c7.06-2.58,13.78-5.01,20.47-7.48c4.51-1.66,8.93-3.57,13.5-5.02c5.3-1.67,10.41-1.2,14.39,3.15
			c3.64,3.98,3.68,8.69,1.93,13.51c-3.64,10.03-7.22,20.09-11.1,30.03c-1.23,3.14-0.32,4.22,2.57,5.13
			c15.27,4.82,30.5,9.76,45.73,14.69c1.52,0.49,3.06,1.01,4.48,1.73c4.31,2.18,7.36,5.32,7.36,10.53c0,5.48-2.93,9.15-7.7,11.14
			c-4.67,1.95-9.58,3.33-14.39,4.9c-11.28,3.7-22.53,7.5-33.88,10.96c-3.35,1.02-3.6,2.19-2.08,5.03
			c7.88,14.7,15.69,29.44,23.34,44.27c1.31,2.53,2.27,5.5,2.43,8.32c0.44,7.78-8.21,13.92-15.24,10.54
			c-12.26-5.89-24.24-12.37-36.33-18.61c-4.15-2.14-8.32-4.25-12.41-6.5c-2.55-1.4-3.94-1.27-4.93,1.93
			c-4.69,15.15-9.64,30.23-14.44,45.35c-2.95,9.28-10.12,13.34-17.57,9.13c-2.71-1.53-5.2-4.75-6.24-7.74
			c-5.08-14.6-9.62-29.39-14.37-44.1C101.57,200.62,100.84,198.78,99.97,196.39z"
    />{' '}
  </SvgIcon>
);

Explosion.muiName = 'Explosion';
