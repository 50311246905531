export const CITIES = [
  'Skopje',
  'Tetovo',
  'Bitola',
  'Kumanovo',
  'Prilep',
  'Ohrid',
  'Veles',
  'Štip',
  'Gostivar',
  'Kočani',
  'Dračevo',
  'Struga',
  'Debar',
  'Strumica',
  'Vinica',
  'Probištip',
  'Aračinovo',
  'Kičevo',
  'Kavadarci',
  'Gevgelija',
  'Vrapčište',
  'Radoviš',
  'Berovo',
  'Kruševo',
  'Sveti Nikole',
  'Demir Kapija',
  'Kučevište',
  'Delčevo',
  'Bogdanci',
  'Rašče',
  'Negotino',
  'Vevčani',
  'Debrešte',
  'Labuništa',
  'Gradsko',
  'Valandovo',
  'Kriva Palanka',
  'Kratovo',
  'Krivogaštani',
  'Zrnovci',
  'Pehčevo',
  'Plasnica',
  'Mogila',
  'Tearce',
  'Novaci',
  'Rosoman',
  'Novo Selo',
  'Bosilovo',
  'Konče',
  'Rostuša',
  'Jegunovce',
  'Rankovce',
  'Sopište',
  'Obleševo',
  'Karbinci',
  'Vasilevo',
  'Petrovec',
  'Makedonski Brod',
  'Staro Nagoričane',
  'Demir Hisar',
  'Lozovo',
  'Centar Župa',
  'Brvenica',
  'Belčišta',
  'Dolneni',
  'Čučer-Sandevo',
  'Zelenikovo',
  'Želino',
  'Star Dojran',
  'Studeničani',
  'Bogovinje',
  'Ilinden',
  'Lipkovo',
  'Makedonska Kamenica',
  'Resen',
];

export const CITY_LABELS_MAP: Record<string, string> = {
  Skopje: 'Скопје',
  Tetovo: 'Тетово',
  Bitola: 'Битола',
  Kumanovo: 'Куманово',
  Prilep: 'Прилеп',
  Ohrid: 'Охрид',
  Veles: 'Велес',
  Štip: 'Штип',
  Gostivar: 'Гостивар',
  Kočani: 'Кочани',
  Dračevo: 'Драчево',
  Struga: 'Струга',
  Debar: 'Дебар',
  Strumica: 'Струмица',
  Vinica: 'Виница',
  Probištip: 'Пробиштип',
  Aračinovo: 'Арачиново',
  Kičevo: 'Кичево',
  Kavadarci: 'Кавадарци',
  Gevgelija: 'Гевгелија',
  Vrapčište: 'Врапчиште',
  Radoviš: 'Радовиш',
  Berovo: 'Берово',
  Kruševo: 'Крушево',
  'Sveti Nikole': 'Свети Николе',
  'Demir Kapija': 'Демир Капија',
  Kučevište: 'Кучевиште',
  Delčevo: 'Делчево',
  Bogdanci: 'Богданци',
  Rašče: 'Рашче',
  Negotino: 'Неготино',
  Vevčani: 'Вевчани',
  Debrešte: 'Дебреште',
  Labuništa: 'Лабуништа',
  Gradsko: 'Градско',
  Valandovo: 'Валандово',
  'Kriva Palanka': 'Крива Паланка',
  Kratovo: 'Кратово',
  Krivogaštani: 'Кривогаштани',
  Zrnovci: 'Зрновци',
  Pehčevo: 'Пехчево',
  Plasnica: 'Пласница',
  Mogila: 'Могила',
  Tearce: 'Теарце',
  Novaci: 'Новаци',
  Rosoman: 'Росоман',
  'Novo Selo': 'Ново Село',
  Bosilovo: 'Босилово',
  Konče: 'Конче',
  Rostuša: 'Ростуша',
  Jegunovce: 'Јегуновце',
  Rankovce: 'Ранковце',
  Sopište: 'Сопиште',
  Obleševo: 'Облешево',
  Karbinci: 'Карбинци',
  Vasilevo: 'Василево',
  Petrovec: 'Петровец',
  'Makedonski Brod': 'Македонски Брод',
  'Staro Nagoričane': 'Старо Нагоричане',
  'Demir Hisar': 'Демир Хисар',
  Lozovo: 'Лозово',
  'Centar Župa': 'Центар Жупа',
  Brvenica: 'Брвеница',
  Belčišta: 'Белчишта',
  Dolneni: 'Долнени',
  'Čučer-Sandevo': 'Чучер-Сандево',
  Zelenikovo: 'Зелениково',
  Želino: 'Желино',
  'Star Dojran': 'Стар Дојран',
  Studeničani: 'Студеничани',
  Bogovinje: 'Боговиње',
  Ilinden: 'Илинден',
  Lipkovo: 'Липково',
  'Makedonska Kamenica': 'Македонска Каменица',
  Resen: 'Ресен',
};
