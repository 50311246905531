import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Spillage: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 13.5"
    sx={{
      fill: '#FFF',
    }}
  >
    <path
      d="M0,0v8h2V6h6l2-2L8,2H2V0H0z M18,0v2h-8l2,2l-2,2h8v2h2V0H18z M10,8c0,0-2,2.2-2,3.5c0,0.5,0.2,1,0.6,1.4
	c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6S12,12,12,11.5C12,10.2,10,8,10,8z"
    />
  </SvgIcon>
);

Spillage.muiName = 'Spillage';
