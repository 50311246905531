import { useCallback, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { Info } from '@mui/icons-material';
import {
  Box,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { FormNavigation } from '../../../components/form-navigation';
import { LogoColored } from '../../../components/icons/logo-colored';
import { CITIES } from '../../../data/cities';
import { BaseContainer, TitleText } from '../styled';

const TODO_REPLACE_WITH_ACTUAL_LOGIC_VALUE_MULTIPLIER = 40.625;

export const BasicInfo = () => {
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [squareMeters, setSquareMeters] = useState<string | null>(null);

  const navigate = useNavigate();
  const theme = useTheme();

  const onSubmit = useCallback(() => {
    if (!selectedCity || !squareMeters) {
      return;
    }

    navigate({
      pathname: './building',
      search: createSearchParams({ city: selectedCity, area: squareMeters }).toString(),
    });
  }, [navigate, selectedCity, squareMeters]);

  const squareMetersNumber = useMemo(() => {
    if (!squareMeters) {
      return null;
    }

    const parsed = parseInt(squareMeters, 10);

    if (Number.isNaN(parsed)) {
      return null;
    }

    return parsed;
  }, [squareMeters]);

  const isTabletOrLarger = useMediaQuery(theme.breakpoints.up('md'));

  let formContent = (
    <>
      <Typography
        variant="subtitle1"
        color="primary.dark"
        textAlign={{
          xs: 'center',
          sm: 'left',
        }}
        marginTop={{
          xs: 2,
          md: 0,
        }}
      >
        Објектот за кој барате понуди се наоѓа во
      </Typography>
      <Select
        value={selectedCity}
        onChange={(e) => setSelectedCity(e.target.value)}
        sx={{
          minWidth: {
            xs: '100%',
            md: theme.spacing(25),
          },
          margin: `0 ${theme.spacing(2)}`,
        }}
      >
        {CITIES.map((city) => (
          <MenuItem key={city} value={city}>
            {city}
          </MenuItem>
        ))}
      </Select>
      <Typography
        variant="subtitle1"
        color="primary.dark"
        marginTop={{
          xs: 2,
          md: 0,
        }}
      >
        и има {!isTabletOrLarger ? 'квадратура од' : null}
      </Typography>
      <TextField
        value={squareMeters}
        onChange={(e) => setSquareMeters(e.target.value)}
        sx={{
          maxWidth: {
            xs: theme.spacing(16),
            md: theme.spacing(10),
          },
          margin: `0 ${theme.spacing(2)}`,
        }}
      />
      <Typography variant="subtitle1" color="primary.dark">
        м<sup>2</sup>
      </Typography>
    </>
  );

  if (isTabletOrLarger) {
    formContent = (
      <Paper
        elevation={5}
        sx={{
          marginTop: 2,
          marginBottom: 4,
          padding: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        {formContent}
      </Paper>
    );
  }

  return (
    <>
      <BaseContainer>
        <LogoColored />
        <TitleText variant="h4" color="primary" fontWeight="bold" textAlign="center">
          Да го избереме најдоброто домаќинско за тебе, заедно!
        </TitleText>
        {formContent}
        {selectedCity && squareMetersNumber ? (
          <Box display="flex" justifyContent="center" alignItems="center" marginTop={2}>
            <Info sx={{ color: 'grey.400', marginRight: 1 }} fontSize="small" />
            <Typography variant="subtitle2" color="grey.400" align="center">
              Просечната цена за {squareMetersNumber} м<sup>2</sup> во {selectedCity} е{' '}
              {Math.round(squareMetersNumber * TODO_REPLACE_WITH_ACTUAL_LOGIC_VALUE_MULTIPLIER)}{' '}
              денари.
            </Typography>
          </Box>
        ) : null}
      </BaseContainer>
      <FormNavigation
        onNext={onSubmit}
        nextLabel={isTabletOrLarger ? 'Следно прашање' : 'Следно'}
        prevLabel={isTabletOrLarger ? 'Претходно прашање' : 'Претходно'}
        nextSubtitle="уште 3 прашања"
      />
    </>
  );
};
