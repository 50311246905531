import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

interface NavigateConfig {
  exclude?: Array<string>;
  extraParams?: Record<string, string>;
}

export const useNavigatePreserveQuery = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const navigateWithPreserveQuery = (
    path: string,
    { exclude, extraParams }: NavigateConfig = {},
  ) => {
    exclude?.forEach((param) => {
      searchParams.delete(param);
    });

    Object.entries(extraParams ?? {}).forEach(([key, value]) => {
      searchParams.set(key, value);
    });

    navigate({
      pathname: path,
      search: searchParams.toString(),
    });
  };

  return navigateWithPreserveQuery;
};
