import styled from 'styled-components';

import { Paper, Typography } from '@mui/material';

export const Container = styled.div`
  flex-direction: column;
  align-items: center;
  width: ${({ theme }) => theme.spacing(15)};
  display: flex;
  min-width: 180px;
`;

export const RadioContainer = styled(Paper)<{ active?: boolean }>`
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  min-height: 180px;
  background: ${({ theme }) => theme.palette.common.white};
  border: ${({ active }) => (active ? '3px' : '1px')} solid
    ${({ theme, active }) => (active ? theme.palette.accent.main : theme.palette.grey[100])};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing(2.5)};
  transition: border 0.1s ease;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.accent.main};
`;

export const Description = styled(Typography).attrs({
  variant: 'body1',
})`
  margin-top: ${({ theme }) => theme.spacing(3)};
  text-align: center;
  text-wrap: wrap;
`;

export const ActionPoint = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.palette.accent.main};
  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(1)}`};
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: ${({ theme }) => theme.spacing(2)};
  width: 50%;
`;
