import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Burglar: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 23">
    <path d="M13.9648 13.2109C16.9297 13.4688 19.25 15.918 19.25 18.9258V20.6875C19.25 21.8477 18.3047 22.75 17.1875 22.75H2.0625C0.902344 22.75 0 21.8477 0 20.6875V18.9258C0 15.918 2.32031 13.4688 5.24219 13.2109L9.625 17.5508L13.9648 13.2109ZM1.375 9C1.375 7.58203 2.10547 6.33594 3.22266 5.5625C2.10547 4.83203 1.375 3.58594 1.375 2.125C2.75 2.125 3.95312 2.8125 4.68359 3.84375C5.58594 2.03906 7.43359 0.75 9.625 0.75C12.6328 0.75 15.125 3.24219 15.125 6.25C15.125 9.30078 12.6328 11.75 9.625 11.75C7.08984 11.75 4.98438 10.0742 4.33984 7.75391C3.56641 8.52734 2.53516 9 1.375 9ZM7.5625 4.875C6.78906 4.875 6.1875 5.51953 6.1875 6.25H13.0625C13.0625 5.51953 12.418 4.875 11.6875 4.875H7.5625Z" />
  </SvgIcon>
);

Burglar.muiName = 'Burglar';
