import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Typography,
} from '@mui/material';

import AmexIcon from './assets/amex.png';
import DinersIcon from './assets/diners.png';
import MaestroIcon from './assets/maestro.png';
import MasterIcon from './assets/master.png';
import VisaIcon from './assets/visa.png';
import { useOrderData } from './hooks/use-order-data';
import { PaymentContainer, PaymentMethod, PaymentMethodsContainer } from './styled';
import { ApiCompanyKey } from '../../../api/policies/types';
import { LogoColored } from '../../../components/icons/logo-colored';
import { BasicInfoContainer, PriceContainer } from '../preview-policy/components/basic-info/styled';
import { CompanyLogoMap } from '../results/components/policy/constants';
import { CompanyLogo } from '../results/components/policy/styled';
import { BaseContainer } from '../styled';

type PolicyPaymentProps = {
  variant?: 'payment' | 'thank-you' | 'payment-success' | 'payment-error';
};

export const PolicyPayment = ({ variant = 'payment' }: PolicyPaymentProps) => {
  const navigate = useNavigate();

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [params] = useSearchParams();
  const email = params.get('email');
  const orderNumber = params.get('orderNumber');

  const { data, isLoading, isError, error } = useOrderData(
    { email, orderNumber },
    variant === 'payment',
  );

  const propsFromUrl = {
    insurancePackageName: params.get('insurancePackageName'),
    insuranceCompanyKey: params.get('insuranceCompanyKey') as ApiCompanyKey,
    objectSize: params.get('objectSize'),
    address: params.get('address'),
    ownerAddress: params.get('ownerAddress'),
    ownerFirstName: params.get('ownerFirstName'),
    ownerLastName: params.get('ownerLastName'),
    totalPrice: params.get('totalPrice'),
  };

  const { order, paymentLink } = data ?? {
    order: {
      company: {
        key: propsFromUrl.insuranceCompanyKey ?? '',
      },
      insurancePackage: {
        name: propsFromUrl.insurancePackageName ?? '',
      },
      objectSize: propsFromUrl.objectSize ?? '',
      address: propsFromUrl.address ?? '',
      ownerAddress: propsFromUrl.ownerAddress ?? '',
      ownerFirstName: propsFromUrl.ownerFirstName ?? '',
      ownerLastName: propsFromUrl.ownerLastName ?? '',
      totalPrice: propsFromUrl.totalPrice ?? '',
    },
    paymentLink: '',
  };

  useEffect(() => {
    if (variant === 'payment' && (!email || !orderNumber)) {
      window.location.href = process.env.REACT_APP_WORDPRESS_BASE_URL ?? '';
    }
  }, [variant, email, orderNumber, navigate]);

  if (variant === 'payment' && (!email || !orderNumber)) {
    return null;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  if (variant === 'payment-success' || variant === 'payment-error') {
    return (
      <BaseContainer>
        <PaymentContainer>
          <LogoColored />
          <Typography variant="h5" fontSize="36px" color="accent.main">
            {variant === 'payment-success'
              ? 'Плаќањето е успешно!'
              : 'Се случи грешка. Плаќањето е неуспешно!'}
          </Typography>
          <Typography variant="body1" color="primary.dark">
            {variant === 'payment-success'
              ? 'Ти благодариме за успешното плаќање. Твојата полиса се изработува и ќе ти биде доставена на мејл.'
              : 'Обиди се повторно да платиш преку линкот кој го доби на мејл или контактирај ја твојата банка за потенцијални проблеми со твојата картичка.'}
          </Typography>
          <Button
            variant="contained"
            color="accent"
            onClick={() => navigate('/insurance/home/')}
            sx={{
              width: '180px',
              padding: (theme) => `${theme.spacing(2)} ${theme.spacing(1)}`,
              borderRadius: '32px',
            }}
          >
            <Typography component="span" fontWeight="bold">
              Назад
            </Typography>
          </Button>
        </PaymentContainer>
      </BaseContainer>
    );
  }

  if (variant === 'payment' && isError) {
    if (error?.response?.data?.errorKey === 'orderPaidAlready') {
      return (
        <BaseContainer>
          <PaymentContainer>
            <LogoColored />
            <Typography variant="h5" fontSize="36px" color="accent.main">
              Оваа полиса со нарачка бр. {orderNumber} е веќе платена.
            </Typography>
            <Typography variant="body1" color="primary.dark">
              Доколку се потребни информации поврзани со оваа нарачка и полисата пишете ни на{' '}
              <a href="mailto:alo@kompa.mk">alo@kompa.mk</a>.
            </Typography>
            <Button
              variant="contained"
              color="accent"
              onClick={() => navigate('/insurance/home/')}
              sx={{
                width: '180px',
                padding: (theme) => `${theme.spacing(2)} ${theme.spacing(1)}`,
                borderRadius: '32px',
              }}
            >
              <Typography component="span" fontWeight="bold">
                Назад
              </Typography>
            </Button>
          </PaymentContainer>
        </BaseContainer>
      );
    }
    return (
      <BaseContainer>
        <PaymentContainer>
          <LogoColored />
          <Typography variant="h5" fontSize="36px" color="accent.main">
            Се случи грешка при вчитување на податоците.
          </Typography>
          <Typography variant="body1" color="primary.dark">
            Те молиме обиди се повторно.
          </Typography>
          <Button
            variant="contained"
            color="accent"
            onClick={() => window.location.reload()}
            sx={{
              width: '180px',
              padding: (theme) => `${theme.spacing(2)} ${theme.spacing(1)}`,
              borderRadius: '32px',
            }}
          >
            <Typography component="span" fontWeight="bold">
              Пробај повторно
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="accent"
            onClick={() => navigate('/insurance/home/')}
            sx={{
              width: '180px',
              padding: (theme) => `${theme.spacing(2)} ${theme.spacing(1)}`,
              borderRadius: '32px',
              marginTop: 2,
            }}
          >
            <Typography component="span" fontWeight="bold">
              Назад
            </Typography>
          </Button>
        </PaymentContainer>
      </BaseContainer>
    );
  }

  if (!order) {
    return (
      <BaseContainer>
        <PaymentContainer>
          <LogoColored />
          <Typography variant="h5" fontSize="36px" color="accent.main">
            Нема податоци за нарачката
          </Typography>
        </PaymentContainer>
      </BaseContainer>
    );
  }

  const logoSource = CompanyLogoMap[order.company.key];

  return (
    <BaseContainer>
      <PaymentContainer>
        <LogoColored />
        <Typography variant="h5" fontSize="36px" color="accent.main">
          {variant === 'payment' ? 'Полисата е одобрена за издавање!' : 'Ти благодариме!'}
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Typography
            fontWeight="bold"
            fontSize={variant === 'payment' ? '24px' : '20px'}
            color="primary.dark"
          >
            {variant === 'payment' ? (
              <span>
                Истата ќе биде издадена откако ја регистрираме уплатата и ќе ти ја доставиме на мејл
                истиот или следниот работен ден.
              </span>
            ) : (
              <span>
                Твојата нарачка е успешно направена. Тимот на Компа веднаш ќе направи проверка на
                доставените информации, и доколку имаме се’ што ни е потребно, ќе ти испратиме
                потврда дека твојата полиса е одобрена за изработка заедно со линк за плаќање на
                истата.
              </span>
            )}
          </Typography>
          <Typography fontSize={variant === 'payment' ? '18px' : '14px'} color="primary.dark">
            {variant === 'payment' ? (
              <span>Важноста на полисата е по истекот на 24-от час од денот на издавање.</span>
            ) : (
              <span>
                За сите дополнителни информации во меѓувреме, достапни сме на{' '}
                <a href="tel:070268000">070 268 000</a> и{' '}
                <a href="mailto:alo@kompa.mk">alo@kompa.mk</a>. Доколку пак нам ни требаат
                дополнителни информации за вадење на осигурувањето, ќе те побараме во најкус можен
                рок.
              </span>
            )}
          </Typography>
        </Box>
        <BasicInfoContainer>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            paddingX={{
              xs: 2,
              sm: 6,
            }}
            paddingY={2}
            boxSizing="border-box"
            textAlign="left"
          >
            <CompanyLogo src={logoSource} />
            <Typography variant="caption" color="primary.dark" marginTop={0.5}>
              {order.insurancePackage.name}
            </Typography>
            <Divider
              sx={{
                width: '100%',
                margin: '20px 0',
              }}
            />
            <Box display="flex" flexDirection="column" width="100%" gap={1}>
              <Box display="flex" alignItems="center" gap={2} alignSelf="flex-start">
                <Typography variant="body1" color="primary.dark" fontWeight={300}>
                  Полиса за
                </Typography>
                <Typography variant="body1" color="primary.dark" fontWeight="bold" marginLeft={2}>
                  Домаќинско Осигурување
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={2} alignSelf="flex-start">
                <Typography variant="body1" color="primary.dark" fontWeight={300}>
                  Адреса на објект
                </Typography>
                <Typography variant="body1" color="primary.dark" fontWeight="bold" marginLeft={2}>
                  {order.address}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={2} alignSelf="flex-start">
                <Typography variant="body1" color="primary.dark" fontWeight={300}>
                  Квадратура
                </Typography>
                <Typography variant="body1" color="primary.dark" fontWeight="bold" marginLeft={2}>
                  {order.objectSize} м2
                </Typography>
              </Box>
            </Box>
            <Divider
              sx={{
                width: '100%',
                margin: '20px 0',
              }}
            />
            <Box display="flex" flexDirection="column" width="100%" gap={1}>
              <Box display="flex" alignItems="center" gap={2} alignSelf="flex-start">
                <Typography variant="body1" color="primary.dark" fontWeight={300}>
                  Договорувач
                </Typography>
                <Typography variant="body1" color="primary.dark" fontWeight="bold" marginLeft={2}>
                  {order.ownerFirstName} {order.ownerLastName}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={2} alignSelf="flex-start">
                <Typography variant="body1" color="primary.dark" fontWeight={300}>
                  Адреса на договорувач
                </Typography>
                <Typography variant="body1" color="primary.dark" fontWeight="bold" marginLeft={2}>
                  {order.ownerAddress || order.address}
                </Typography>
              </Box>
            </Box>
            <Divider
              sx={{
                width: '100%',
                margin: '20px 0',
              }}
            />
            <Box display="flex" flexDirection="column" width="100%" gap={1}>
              <Box display="flex" alignItems="center" gap={2} alignSelf="flex-start">
                <Typography variant="body1" color="primary.dark" fontWeight={300}>
                  ИД број на нарачка
                </Typography>
                <Typography variant="body1" color="primary.dark" fontWeight="bold" marginLeft={2}>
                  {orderNumber}
                </Typography>
              </Box>
            </Box>
          </Box>
          <PriceContainer display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography
                variant="h6"
                fontWeight={400}
                color="primary.dark"
                textTransform="uppercase"
              >
                Цена
              </Typography>
              <Typography
                variant="body1"
                color="primary.dark"
                fontWeight="bold"
                marginLeft={2}
                fontSize={26}
              >
                {order.totalPrice} ден
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" color="grey.600" fontWeight={300} marginLeft="auto">
                годишно
              </Typography>
            </Box>
          </PriceContainer>
        </BasicInfoContainer>
        {variant === 'payment' && (
          <>
            <FormControlLabel
              control={<Checkbox />}
              onChange={(e) => setAcceptedTerms((e.target as HTMLInputElement)?.checked ?? false)}
              label={
                <Typography variant="body1" fontSize={14} color="grey.400">
                  Се согласувам со{' '}
                  <Link
                    to={`${process.env.REACT_APP_WORDPRESS_BASE_URL}uslovi-za-koristenje/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Условите за користење
                  </Link>{' '}
                  и{' '}
                  <Link
                    to={`${process.env.REACT_APP_WORDPRESS_BASE_URL}politika-na-privatnost/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Политиката за приватност
                  </Link>{' '}
                  на продажно место.
                </Typography>
              }
            />
            <Button
              variant="contained"
              color="accent"
              href={paymentLink}
              disabled={!acceptedTerms}
              sx={{
                width: '180px',
                padding: (theme) => `${theme.spacing(2)} ${theme.spacing(1)}`,
                borderRadius: '32px',
              }}
            >
              <Typography component="span" fontWeight="bold">
                ПЛАТИ
              </Typography>{' '}
              <ArrowForward color="primary" />
            </Button>

            <Typography variant="caption" color="info.main" textAlign="center" width="60%">
              Ќе бидете пренасочени кон платформата за плаќање. Компа не прибира и зачувува ваши
              информации за плаќање.
            </Typography>
            <PaymentMethodsContainer>
              <PaymentMethod src={MasterIcon} alt="MasterCard" />
              <PaymentMethod src={DinersIcon} alt="Diners Club" />
              <PaymentMethod src={VisaIcon} alt="Visa" />
              <PaymentMethod src={AmexIcon} alt="American Express" />
              <PaymentMethod src={MaestroIcon} alt="Maestro" />
            </PaymentMethodsContainer>
          </>
        )}
      </PaymentContainer>
    </BaseContainer>
  );
};
