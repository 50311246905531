import { FlashOn, Thunderstorm, Flight, CarCrash, Pets, Landslide } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';

import { Accident } from './accident';
import { Aquarium } from './aquarium';
import { AtmosphereWaters } from './atmosphere-waters';
import { Avalanche } from './avalanche';
import { Burglar } from './burglar';
import { Defect } from './defect';
import { Earthquake } from './earthquake';
import { Explosion } from './explosion';
import { Fire } from './fire';
import { Flood } from './flood';
import { Glass } from './glass';
import { HomeRepairs } from './home-repairs';
import { Ice } from './ice';
import { Installation } from './installation';
import { LandslideCro } from './landslide_cro';
import { Protest } from './protest';
import { Snow } from './snow';
import { Spillage } from './spilllage';
import { TravelInsurance } from './travel-insurance';
import { TreeFall } from './tree-fall';
import { WeatherHail } from './weather-hail';

export const riskIcons: Record<string, typeof SvgIcon> = {
  fire: Fire,
  spillage: Spillage,
  theft: Burglar,
  explosion: Explosion,
  thunder: FlashOn,
  hail: WeatherHail,
  storm: Thunderstorm,
  flying_objects: Flight,
  protest: Protest,
  vehicle_crash: CarCrash,
  earthquake: Earthquake,
  snow_weight: Snow,
  landslide: Landslide,
  home_repairs: HomeRepairs,
  avalanche: Avalanche,
  tree_fall: TreeFall,
  vandalism: Burglar,
  atmosphere_wather: AtmosphereWaters,
  glass_break: Glass,
  aquarium: Aquarium,
  accident: Accident,
  defect: Defect,
  travel_insurance: TravelInsurance,
  pets: Pets,
  ice: Ice,
  installation: Installation,
  flood: Flood,
  landslide_cro: LandslideCro,
};

export const riskShortLabels: Record<string, string> = {
  thunder: 'гром',
  storm: 'љуња',
  hail: 'град',
  protest: 'собири',
  flying_objects: 'летало',
  avalanche: 'лавина',
  landslide: 'одрон',
  vehicle_crash: 'судар',
  vandalism: 'вандализам',
  glass_break: 'стакло',
  aquarium: 'аквариум',
  snow_weight: 'снег',
  atmosphere_wather: 'а.води',
  tree_fall: 'пад дрво',
  travel_insurance: 'патничко',
  accident: 'незгода',
  home_repairs: 'поправки',
  pets: 'миленици',
  defect: 'квар',
  earthquake: 'земјотрес',
  ice: 'мраз',
  installation: 'инсталации',
  fire: 'пожар',
  explosion: 'експлозија',
  flood: 'поплава',
  spillage: 'излевање',
  theft: 'кражба',
  landslide_cro: 'лизгање',
};

export const risksToIgnore = ['theft', 'fire', 'spillage', 'explosion', 'flood'];

/*
Гром
Луња
Град
Собири
Летало
Судар
Одрон
Лавина
Стакло
Аквариум
Снег
Вандализам
А. води
Пад дрво
Мраз
Незгода
Патничко
Поправки
Дефект
Инсталации
Земјотрес
*/
const riskPriorityArray = [
  'thunder',
  'storm',
  'hail',
  'protest',
  'flying_objects',
  'vehicle_crash',
  'landslide',
  'avalanche',
  'glass_break',
  'aquarium',
  'snow_weight',
  'vandalism',
  'atmosphere_wather',
  'tree_fall',
  'ice',
  'accident',
  'travel_insurance',
  'home_repairs',
  'defect',
  'installation',
  'earthquake',
  'landslide_cro',
];

export const riskPriority = riskPriorityArray.reduce(
  (acc, risk, index) => ({
    ...acc,
    [risk]: index,
  }),
  {} as Record<string, number>,
);
