import { useSearchParams } from 'react-router-dom';

export const useSortOrder = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sortOrder = searchParams.get('sortOrder') || 'desc';

  const updateSortOrder = (newSortOrder: 'asc' | 'desc') => {
    setSearchParams((params) => {
      params.set('sortOrder', newSortOrder);
      return params;
    });
  };

  return { sortOrder, updateSortOrder };
};
