import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const HomeRepairs: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 21 17">
    <path d="M3.5 17V9H0.5L10.5 0L20.5 9H17.5V17H3.5ZM10.5 2.69L5.5 7.19V15H15.5V7.19L10.5 2.69ZM9.5 14V13H11.5V14H9.5ZM9.5 12C9.22 12 9 11.78 9 11.5V10.6C8.1 10.08 7.5 9.11 7.5 8C7.5 6.34 8.84 5 10.5 5C12.16 5 13.5 6.34 13.5 8C13.5 9.11 12.9 10.08 12 10.6V11.5C12 11.78 11.78 12 11.5 12H9.5Z" />
  </SvgIcon>
);

HomeRepairs.muiName = 'HomeRepairs';
