import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Ice: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 22 23">
    <path d="M11.81 0L8.5 3.31H3.81V8L0.5 11.31L3.81 14.62V19.31H8.5L11.81 22.62L12.81 21.62V17.14L16.05 20.38L17.47 18.97L12.81 14.31V12.31H14.81L19.47 16.97L20.88 15.55L17.64 12.31H21.81V10.31H17.64L20.88 7.07L19.47 5.65L14.81 10.31H12.81V8.31L17.47 3.65L16.05 2.24L12.81 5.48V1M10.81 5.4V7.44C9.05 7.9 7.81 9.49 7.81 11.31C7.81 13.13 9.05 14.72 10.81 15.18V17.22C7.93 16.73 5.81 14.24 5.81 11.31C5.81 8.38 7.92 5.88 10.81 5.4Z" />
  </SvgIcon>
);

Ice.muiName = 'Ice';
