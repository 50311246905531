import { Star, StarBorder } from '@mui/icons-material';
import { Box, Rating, Typography } from '@mui/material';

import { Review as ReviewType } from '../../types';

interface Props {
  review: ReviewType;
}

export const Review = ({ review }: Props) => (
  <Box display="flex" flexDirection="column" alignItems="center" marginBottom={4}>
    <Typography variant="body1" fontWeight={600} color="primary.dark" align="center">
      „{review.comment}“
    </Typography>
    <Typography
      marginBottom={3}
      variant="body1"
      sx={{
        fontStyle: 'italic',
        marginTop: (theme) => theme.spacing(2),
      }}
      color="primary.dark"
      fontWeight={300}
      align="center"
    >
      {review.reviewer}
    </Typography>
    <Rating
      name="customized-color"
      value={review.grade}
      precision={0.5}
      readOnly
      icon={
        <Star
          fontSize="inherit"
          sx={{ color: 'accent.main', transform: 'scale(1.1, 1)', width: '0.9em' }}
        />
      }
      emptyIcon={
        <StarBorder
          fontSize="inherit"
          sx={{ color: 'accent.main', transform: 'scale(1.1, 1)', width: '0.9em' }}
        />
      }
    />
  </Box>
);
