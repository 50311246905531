import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AtmosphereWaters: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 23 19">
    <path
      d="M18.69,10.07C19.19,9.54,19.5,8.82,19.5,8c0-1.7-1.3-3.16-3-3.16h-2.8C13.7,2.17,11.53,0,8.86,0
	c-2.05,0-3.8,1.28-4.51,3.08C2.22,3.14,0.5,4.89,0.5,7.04C0.5,9.22,2.28,11,4.46,11H7.6c-0.06,0.33-0.1,0.66-0.1,1H7
	c-1.93,0-3.5,1.57-3.5,3.5S5.07,19,7,19h11c2.5,0,4.5-2,4.5-4.5C22.5,12.26,20.84,10.41,18.69,10.07z M18,17H7
	c-0.83,0-1.5-0.67-1.5-1.5S6.17,14,7,14h2.5v-2c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5H18c1.38,0,2.5,1.12,2.5,2.5
	S19.38,17,18,17z"
    />
  </SvgIcon>
);

AtmosphereWaters.muiName = 'AtmosphereWaters';
